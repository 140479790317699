$primary-color-dark: #512da8;
$primary-color: #673ab7;
$primary-color-light: #d1c4e9;
$primary-color-text: #ffffff;
$accent-color: #ff5252;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #bdbdbd;
$accent-color-border: #d14343;
$modules-bg-color: #31313f;
