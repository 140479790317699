@media all and (max-width: 1020px) {
  .left-side,
  .left-relative,
  .left-side-small {
    position: relative;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 1 !important;
  }
  .right-side,
  .right-side-large,
  .right-side-small {
    position: relative;
    right: 0;
    top: 0;
    height: 100% !important;
    z-index: 1 !important;
  }
  .right-side,
  .right-side-large,
  .right-side-small,
  .left-side,
  .left-side-small {
    width: 100% !important;
  }

  .loader-img img {
    left: 40%;
    bottom: 45%;
    width: 200px;
  }
}

@media all and (max-width: 992px) {
  .md-bg-transparent {
    background-color: transparent !important;
  }
  .md-bg-white {
    background-color: #fff !important;
  }
  .md-bg-black {
    background-color: #000 !important;
  }
  .md-bg-gray-extra-light {
    background-color: #f9f9f9 !important;
  }
  .md-bg-gray-light {
    background-color: #c0bfbf !important;
  }
  .md-bg-gray-regular {
    background-color: #9d9d9d !important;
  }
  .md-bg-gray-dark {
    background-color: #444 !important;
  }
  .md-bg-gray-extra-dark {
    background-color: #232323 !important;
  }
  .md-bg-blue {
    background-color: #1769ff !important;
  }
  .md-bg-red {
    background-color: #e31919 !important;
  }
  .md-line-height-normal {
    line-height: normal !important;
  }
  .md-no-line-height {
    line-height: 0 !important;
  }
  .md-line-height-spaced {
    line-height: 1md-5em !important;
  }
  .md-line-height-regular {
    line-height: 1md-1em !important;
  }
  .md-line-height-medium {
    line-height: 1md-3em !important;
  }
  .md-line-height-10 {
    line-height: 10px !important;
  }
  .md-line-height-15 {
    line-height: 15px !important;
  }
  .md-line-height-16 {
    line-height: 16px !important;
  }
  .md-line-height-17 {
    line-height: 17px !important;
  }
  .md-line-height-18 {
    line-height: 18px !important;
  }
  .md-line-height-19 {
    line-height: 19px !important;
  }
  .md-line-height-20 {
    line-height: 20px !important;
  }
  .md-line-height-25 {
    line-height: 25px !important;
  }
  .md-line-height-26 {
    line-height: 26px !important;
  }
  .md-line-height-27 {
    line-height: 27px !important;
  }
  .md-line-height-28 {
    line-height: 28px !important;
  }
  .md-line-height-29 {
    line-height: 29px !important;
  }
  .md-line-height-30 {
    line-height: 30px !important;
  }
  .md-line-height-35 {
    line-height: 35px !important;
  }
  .md-line-height-40 {
    line-height: 40px !important;
  }
  .md-line-height-45 {
    line-height: 45px !important;
  }
  .md-line-height-48 {
    line-height: 48px !important;
  }
  .md-line-height-50 {
    line-height: 50px !important;
  }
  .md-line-height-55 {
    line-height: 55px !important;
  }
  .md-line-height-60 {
    line-height: 60px !important;
  }
  .md-line-height-65 {
    line-height: 65px !important;
  }
  .md-line-height-70 {
    line-height: 70px !important;
  }
  .md-line-height-75 {
    line-height: 75px !important;
  }
  .md-line-height-80 {
    line-height: 80px !important;
  }
  .md-line-height-85 {
    line-height: 85px !important;
  }
  .md-line-height-90 {
    line-height: 90px !important;
  }
  .md-line-height-95 {
    line-height: 95px !important;
  }
  .md-line-height-100 {
    line-height: 100px !important;
  }
  .md-line-height-110 {
    line-height: 110px !important;
  }
  .md-line-height-120 {
    line-height: 120px !important;
  }
  .md-line-height-175 {
    line-height: 175px !important;
  }
  .md-line-height-180 {
    line-height: 180px !important;
  }
  .md-line-height-170 {
    line-height: 170px !important;
  }
  .md-line-height-160 {
    line-height: 160px !important;
  }
  .md-line-height-150 {
    line-height: 150px !important;
  }
  .md-line-height-200 {
    line-height: 200px !important;
  }
  .md-text-underline {
    text-decoration: underline !important;
  }
  .md-text-line-through {
    text-decoration: line-through !important;
  }
  .md-text-transform-none {
    text-transform: none !important;
  }
  .md-text-transform-unset {
    text-transform: unset !important;
  }
  .md-text-regular {
    font-style: normal !important;
  }
  .md-text-italic {
    font-style: italic !important;
  }

  .md-no-letter-spacing {
    letter-spacing: 0 !important;
  }
  .md-letter-spacing-1 {
    letter-spacing: 1px !important;
  }
  .md-letter-spacing-2 {
    letter-spacing: 2px !important;
  }
  .md-letter-spacing-3 {
    letter-spacing: 3px !important;
  }
  .md-letter-spacing-4 {
    letter-spacing: 4px !important;
  }
  .md-letter-spacing-5 {
    letter-spacing: 5px !important;
  }
  .md-letter-spacing-6 {
    letter-spacing: 6px !important;
  }
  .md-letter-spacing-7 {
    letter-spacing: 7px !important;
  }
  .md-letter-spacing-8 {
    letter-spacing: 8px !important;
  }
  .md-letter-spacing-9 {
    letter-spacing: 9px !important;
  }
  .md-letter-spacing-10 {
    letter-spacing: 10px !important;
  }
  .md-letter-spacing-11 {
    letter-spacing: 11px !important;
  }
  .md-letter-spacing-12 {
    letter-spacing: 12px !important;
  }
  .md-letter-spacing-13 {
    letter-spacing: 13px !important;
  }
  .md-letter-spacing-14 {
    letter-spacing: 14px !important;
  }
  .md-letter-spacing-15 {
    letter-spacing: 15px !important;
  }
  .md-letter-spacing-16 {
    letter-spacing: 16px !important;
  }
  .md-letter-spacing-17 {
    letter-spacing: 17px !important;
  }
  .md-letter-spacing-18 {
    letter-spacing: 18px !important;
  }
  .md-letter-spacing-19 {
    letter-spacing: 19px !important;
  }
  .md-letter-spacing-20 {
    letter-spacing: 20px !important;
  }
  .md-letter-spacing-50 {
    letter-spacing: 50px !important;
  }
  .md-letter-spacing-minus-1 {
    letter-spacing: -1px !important;
  }
  .md-letter-spacing-minus-2 {
    letter-spacing: -2px !important;
  }
  .md-letter-spacing-minus-3 {
    letter-spacing: -3px !important;
  }
  .md-letter-spacing-minus-4 {
    letter-spacing: -4px !important;
  }
  .md-letter-spacing-minus-5 {
    letter-spacing: -5px !important;
  }
  .md-letter-spacing-minus-6 {
    letter-spacing: -6px !important;
  }
  .md-letter-spacing-minus-7 {
    letter-spacing: -7px !important;
  }
  .md-letter-spacing-minus-8 {
    letter-spacing: -8px !important;
  }
  .md-letter-spacing-minus-9 {
    letter-spacing: -9px !important;
  }
  .md-letter-spacing-minus-10 {
    letter-spacing: -10px !important;
  }
  .md-no-word-spacing {
    word-spacing: 0 !important;
  }
  .md-word-spacing-1 {
    word-spacing: 1px !important;
  }
  .md-word-spacing-2 {
    word-spacing: 2px !important;
  }
  .md-word-spacing-3 {
    word-spacing: 3px !important;
  }
  .md-word-spacing-4 {
    word-spacing: 4px !important;
  }
  .md-word-spacing-5 {
    word-spacing: 5px !important;
  }
  .md-word-spacing-6 {
    word-spacing: 6px !important;
  }
  .md-word-spacing-7 {
    word-spacing: 7px !important;
  }
  .md-word-spacing-8 {
    word-spacing: 8px !important;
  }
  .md-word-spacing-9 {
    word-spacing: 9px !important;
  }
  .md-word-spacing-10 {
    word-spacing: 10px !important;
  }
  .md-word-spacing-minus-1 {
    word-spacing: -1px !important;
  }
  .md-word-spacing-minus-2 {
    word-spacing: -2px !important;
  }
  .md-word-spacing-minus-3 {
    word-spacing: -3px !important;
  }
  .md-word-spacing-minus-4 {
    word-spacing: -4px !important;
  }
  .md-word-spacing-minus-5 {
    word-spacing: -5px !important;
  }
  .md-word-spacing-minus-6 {
    word-spacing: -6px !important;
  }
  .md-word-spacing-minus-7 {
    word-spacing: -7px !important;
  }
  .md-word-spacing-minus-8 {
    word-spacing: -8px !important;
  }
  .md-word-spacing-minus-9 {
    word-spacing: -9px !important;
  }
  .md-word-spacing-minus-10 {
    word-spacing: -10px !important;
  }
  .md-text-extra-small {
    font-size: 11px !important;
    line-height: 14px !important;
  }
  .md-text-small {
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .md-text-medium {
    font-size: 16px !important;
    line-height: 23px !important;
  }
  .md-text-large {
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .md-text-extra-large {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .md-title-extra-small {
    font-size: 35px !important;
    line-height: 38px !important;
  }
  .md-title-small {
    font-size: 55px !important;
    line-height: 48px !important;
  }
  .md-title-medium {
    font-size: 75px !important;
    line-height: 60px !important;
  }
  .md-title-large {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .md-title-extra-large {
    font-size: 130px !important;
    line-height: 138px !important;
  }
  .md-text-center {
    text-align: center !important;
  }
  .md-text-left {
    text-align: left !important;
  }
  .md-text-right {
    text-align: right !important;
  }
  .md-text-white {
    color: #fff !important;
  }
  .md-text-black {
    color: #000 !important;
  }
  .md-text-blue {
    color: #1769ff !important;
  }
  .md-text-red {
    color: #e31919 !important;
  }
  .md-text-gray-light {
    color: #c0bfbf !important;
  }
  .md-text-gray-regular {
    color: #9d9d9d !important;
  }
  .md-text-gray-dark {
    color: #444 !important;
  }
  .md-text-gray-extra-dark {
    color: #232323 !important;
  }
  .md-text-weight-100 {
    font-weight: 100 !important;
  }
  .md-text-weight-200 {
    font-weight: 200 !important;
  }
  .md-text-weight-300 {
    font-weight: 300 !important;
  }
  .md-text-weight-400 {
    font-weight: 400 !important;
  }
  .md-text-weight-500 {
    font-weight: 500 !important;
  }
  .md-text-weight-600 {
    font-weight: 600 !important;
  }
  .md-text-weight-700 {
    font-weight: 700 !important;
  }
  .md-text-weight-800 {
    font-weight: 800 !important;
  }
  .md-text-weight-900 {
    font-weight: 900 !important;
  }
  .md-display-block {
    display: block !important;
  }
  .md-display-inline-block {
    display: inline-block !important;
  }
  .md-display-inline {
    display: inline !important;
  }
  .md-display-none {
    display: none !important;
  }
  .md-display-inherit {
    display: inherit !important;
  }
  .md-display-table {
    display: table !important;
  }
  .md-display-table-cell {
    display: table-cell !important;
  }
  .md-overflow-hidden {
    overflow: hidden !important;
  }
  .md-overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .md-overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .md-overflow-visible {
    overflow: visible !important;
  }
  .md-overflow-auto {
    overflow: auto !important;
  }
  .md-overflow-x-auto {
    overflow-x: auto !important;
  }
  .md-overflow-y-auto {
    overflow-y: auto !important;
  }
  .md-overflow-scroll {
    overflow: scroll !important;
  }
  .md-vertical-align-middle {
    vertical-align: middle !important;
  }
  .md-vertical-align-top {
    vertical-align: top !important;
  }
  .md-vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .md-vertical-middle {
    -webkit-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
    position: absolute !important;
    top: 50% !important;
    left: 0 !important;
    right: 0 !important;
  }
  .md-middle-center {
    left: 50% !important;
    top: 50% !important;
    position: absolute !important;
    -ms-transform: translateX(-50%) translateY(-50%) !important;
    -moz-transform: translateX(-50%) translateY(-50%) !important;
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
    transform: translateX(-50%) translateY(-50%) !important;
    -o-transform: translateX(-50%) translateY(-50%) !important;
  }
  .md-center-col {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md-right-col {
    float: right !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md-left-col {
    float: left !important;
    margin-right: auto !important;
    margin-right: auto !important;
  }
  .md-position-inherit {
    position: inherit !important;
  }
  .md-position-relative {
    position: relative !important;
  }
  .md-position-absolute {
    position: absolute !important;
  }
  .md-position-fixed {
    position: fixed !important;
  }
  .md-position-right {
    right: 0 !important;
  }
  .md-position-left {
    left: 0 !important;
  }
  .md-position-top {
    top: 0 !important;
  }
  .md-top-1 {
    top: 1px !important;
  }
  .md-top-2 {
    top: 2px !important;
  }
  .md-top-3 {
    top: 2px !important;
  }
  .md-top-4 {
    top: 2px !important;
  }
  .md-top-5 {
    top: 2px !important;
  }
  .md-top-6 {
    top: 2px !important;
  }
  .md-top-7 {
    top: 2px !important;
  }
  .md-top-8 {
    top: 2px !important;
  }
  .md-top-9 {
    top: 2px !important;
  }
  .md-top-10 {
    top: 2px !important;
  }
  .md-bottom-1 {
    bottom: 1px !important;
  }
  .md-bottom-2 {
    bottom: 2px !important;
  }
  .md-bottom-3 {
    top: 3px !important;
  }
  .md-bottom-4 {
    top: 4px !important;
  }
  .md-bottom-5 {
    top: 5px !important;
  }
  .md-bottom-6 {
    top: 6px !important;
  }
  .md-bottom-7 {
    top: 7px !important;
  }
  .md-bottom-8 {
    top: 8px !important;
  }
  .md-bottom-9 {
    top: 9px !important;
  }
  .md-bottom-10 {
    top: 10px !important;
  }
  .md-float-left {
    float: left !important;
  }
  .md-float-right {
    float: right !important;
  }
  .md-float-none {
    float: none !important;
  }
  .md-clear-both {
    clear: both !important;
  }
  .md-z-index-9999 {
    z-index: 9999 !important;
  }
  .md-z-index-999 {
    z-index: 999 !important;
  }
  .md-z-index-99 {
    z-index: 99 !important;
  }
  .md-z-index-6 {
    z-index: 6 !important;
  }
  .md-z-index-5 {
    z-index: 5 !important;
  }
  .md-z-index-4 {
    z-index: 4 !important;
  }
  .md-z-index-3 {
    z-index: 3 !important;
  }
  .md-z-index-2 {
    z-index: 2 !important;
  }
  .md-z-index-1 {
    z-index: 1 !important;
  }
  .md-z-index-0 {
    z-index: 0 !important;
  }
  .md-z-index-minus1 {
    z-index: -1 !important;
  }
  .md-z-index-minus2 {
    z-index: -2 !important;
  }
  .md-opacity-1 {
    opacity: 0md-1 !important;
  }
  .md-opacity-2 {
    opacity: 0md-2 !important;
  }
  .md-opacity-3 {
    opacity: 0md-3 !important;
  }
  .md-opacity-4 {
    opacity: 0md-4 !important;
  }
  .md-opacity-5 {
    opacity: 0md-5 !important;
  }
  .md-opacity-6 {
    opacity: 0md-6 !important;
  }
  .md-opacity-7 {
    opacity: 0md-7 !important;
  }
  .md-opacity-8 {
    opacity: 0md-8 !important;
  }
  .md-opacity-9 {
    opacity: 0md-9 !important;
  }
  .md-opacity-visible {
    opacity: 1 !important;
  }
  .md-hidden {
    display: none;
    opacity: 0 !important;
  }
  .md-all-border {
    border: 1px solid !important;
  }
  .md-top-border {
    border-top: 1px solid !important;
  }
  .md-bottom-border {
    border-bottom: 1px solid !important;
  }
  .md-left-border {
    border-left: 1px solid !important;
  }
  .md-right-border {
    border-right: 1px solid !important;
  }
  .md-border-left-right {
    border-left: 1px solid;
    border-right: 1px solid !important;
  }
  .md-border-right-left {
    border-top: 1px solid;
    border-bottom: 1px solid !important;
  }
  .md-no-border {
    border: 0 !important;
  }
  .md-no-border-top {
    border-top: 0 !important;
  }
  .md-no-border-bottom {
    border-bottom: 0 !important;
  }
  .md-no-border-left {
    border-left: 0 !important;
  }
  .md-no-border-right {
    border-right: 0 !important;
  }
  .md-border-1px {
    border-width: 1px !important;
  }
  .md-border-2px {
    border-width: 2px !important;
  }
  .md-border-3px {
    border-width: 3px !important;
  }
  .md-border-4px {
    border-width: 4px !important;
  }
  .md-border-5px {
    border-width: 5px !important;
  }
  .md-border-6px {
    border-width: 6px !important;
  }
  .md-border-7px {
    border-width: 7px !important;
  }
  .md-border-8px {
    border-width: 8px !important;
  }
  .md-border-9px {
    border-width: 9px !important;
  }
  .md-border-10px {
    border-width: 10px !important;
  }
  .md-border-11px {
    border-width: 11px !important;
  }
  .md-border-12px {
    border-width: 12px !important;
  }
  .md-border-13px {
    border-width: 13px !important;
  }
  .md-border-14px {
    border-width: 14px !important;
  }
  .md-border-15px {
    border-width: 15px !important;
  }
  .md-border-16px {
    border-width: 16px !important;
  }
  .md-border-17px {
    border-width: 17px !important;
  }
  .md-border-18px {
    border-width: 18px !important;
  }
  .md-border-19px {
    border-width: 19px !important;
  }
  .md-border-20px {
    border-width: 20px !important;
  }
  .md-border-color-white {
    border-color: #fff !important;
  }
  .md-border-color-black {
    border-color: #000 !important;
  }
  .md-border-color-blue {
    border-color: #1769ff !important;
  }
  .md-border-color-gray-extra-light {
    border-color: #e5e5e5 !important;
  }
  .md-border-color-gray-light {
    border-color: #c0bfbf !important;
  }
  .md-border-color-gray-regular {
    border-color: #9d9d9d !important;
  }
  .md-border-color-gray-dark {
    border-color: #444 !important;
  }
  .md-border-color-gray-extra-dark {
    border-color: #232323 !important;
  }
  .md-border-black-light {
    border-color: rgba(0, 0, 0, .md-1) !important;
  }
  .md-border-white-light {
    border-color: rgba(255, 255, 255, .md-1) !important;
  }
  .md-border-radius-1 {
    border-radius: 1px !important;
  }
  .md-border-radius-2 {
    border-radius: 2px !important;
  }
  .md-border-radius-3 {
    border-radius: 3px !important;
  }
  .md-border-radius-4 {
    border-radius: 4px !important;
  }
  .md-border-radius-5 {
    border-radius: 5px !important;
  }
  .md-border-radius-6 {
    border-radius: 6px !important;
  }
  .md-border-radius-7 {
    border-radius: 7px !important;
  }
  .md-border-radius-8 {
    border-radius: 8px !important;
  }
  .md-border-radius-9 {
    border-radius: 9px !important;
  }
  .md-border-radius-10 {
    border-radius: 11px !important;
  }
  .md-border-radius-11 {
    border-radius: 11px !important;
  }
  .md-border-radius-12 {
    border-radius: 12px !important;
  }
  .md-border-radius-13 {
    border-radius: 13px !important;
  }
  .md-border-radius-14 {
    border-radius: 14px !important;
  }
  .md-border-radius-15 {
    border-radius: 15px !important;
  }
  .md-border-radius-16 {
    border-radius: 16px !important;
  }
  .md-border-radius-17 {
    border-radius: 17px !important;
  }
  .md-border-radius-18 {
    border-radius: 18px !important;
  }
  .md-border-radius-19 {
    border-radius: 19px !important;
  }
  .md-border-radius-20 {
    border-radius: 20px !important;
  }
  .md-border-radius-50 {
    border-radius: 50px !important;
  }
  .md-border-radius-100 {
    border-radius: 100px !important;
  }
  .md-border-radius-50-percent {
    border-radius: 50% !important;
  }
  .md-border-radius-100-percent {
    border-radius: 100% !important;
  }
  .md-border-radius-none {
    border-radius: 0 !important;
  }
  .md-no-margin {
    margin: 0 !important;
  }
  .md-no-margin-top {
    margin-top: 0 !important;
  }
  .md-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .md-no-margin-left {
    margin-left: 0 !important;
  }
  .md-no-margin-right {
    margin-right: 0 !important;
  }
  .md-no-margin-left-right {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .md-no-margin-top-bottom {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .md-margin-auto {
    margin: 0 auto !important;
  }
  .md-margin-left-right-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md-margin-left-auto {
    margin-left: auto !important;
  }
  .md-margin-right-auto {
    margin-right: auto !important;
  }
  .md-margin-one-percent {
    margin: 1% !important;
  }
  .md-margin-two-percent {
    margin: 2% !important;
  }
  .md-margin-three-percent {
    margin: 3% !important;
  }
  .md-margin-four-percent {
    margin: 4% !important;
  }
  .md-margin-five-percent {
    margin: 5% !important;
  }
  .md-margin-six-percent {
    margin: 6% !important;
  }
  .md-margin-seven-percent {
    margin: 7% !important;
  }
  .md-margin-eight-percent {
    margin: 8% !important;
  }
  .md-margin-nine-percent {
    margin: 9% !important;
  }
  .md-margin-ten-percent {
    margin: 10% !important;
  }
  .md-margin-eleven-percent {
    margin: 11% !important;
  }
  .md-margin-twelve-percent {
    margin: 12% !important;
  }
  .md-margin-thirteen-percent {
    margin: 13% !important;
  }
  .md-margin-fourteen-percent {
    margin: 14% !important;
  }
  .md-margin-fifteen-percent {
    margin: 15% !important;
  }
  .md-margin-sixteen-percent {
    margin: 16% !important;
  }
  .md-margin-seventeen-percent {
    margin: 17% !important;
  }
  .md-margin-eighteen-percent {
    margin: 18% !important;
  }
  .md-margin-nineteen-percent {
    margin: 19% !important;
  }
  .md-margin-twenty-percent {
    margin: 20% !important;
  }
  .md-margin-twenty-one-percent {
    margin: 21% !important;
  }
  .md-margin-twenty-two-percent {
    margin: 22% !important;
  }
  .md-margin-twenty-three-percent {
    margin: 23% !important;
  }
  .md-margin-twenty-four-percent {
    margin: 24% !important;
  }
  .md-margin-twenty-five-percent {
    margin: 25% !important;
  }
  .md-margin-twenty-six-percent {
    margin: 26% !important;
  }
  .md-margin-twenty-seven-percent {
    margin: 27% !important;
  }
  .md-margin-twenty-eight-percent {
    margin: 28% !important;
  }
  .md-margin-twenty-nine-percent {
    margin: 29% !important;
  }
  .md-margin-thirty-percent {
    margin: 30% !important;
  }
  .md-margin-thirty-one-percent {
    margin: 31% !important;
  }
  .md-margin-thirty-two-percent {
    margin: 32% !important;
  }
  .md-margin-thirty-three-percent {
    margin: 33% !important;
  }
  .md-margin-thirty-four-percent {
    margin: 34% !important;
  }
  .md-margin-thirty-five-percent {
    margin: 35% !important;
  }
  .md-margin-thirty-six-percent {
    margin: 36% !important;
  }
  .md-margin-thirty-seven-percent {
    margin: 37% !important;
  }
  .md-margin-thirty-eight-percent {
    margin: 38% !important;
  }
  .md-margin-thirty-nine-percent {
    margin: 39% !important;
  }
  .md-margin-forty-percent {
    margin: 40% !important;
  }
  .md-margin-forty-one-percent {
    margin: 41% !important;
  }
  .md-margin-forty-two-percent {
    margin: 42% !important;
  }
  .md-margin-forty-three-percent {
    margin: 43% !important;
  }
  .md-margin-forty-four-percent {
    margin: 44% !important;
  }
  .md-margin-forty-five-percent {
    margin: 45% !important;
  }
  .md-margin-forty-six-percent {
    margin: 46% !important;
  }
  .md-margin-forty-seven-percent {
    margin: 47% !important;
  }
  .md-margin-forty-eight-percent {
    margin: 48% !important;
  }
  .md-margin-forty-nine-percent {
    margin: 49% !important;
  }
  .md-margin-fifty-percent {
    margin: 50% !important;
  }
  .md-margin-5px {
    margin: 5px !important;
  }
  .md-margin-10px {
    margin: 10px !important;
  }
  .md-margin-15px {
    margin: 15px !important;
  }
  .md-margin-20px {
    margin: 20px !important;
  }
  .md-margin-25px {
    margin: 25px !important;
  }
  .md-margin-30px {
    margin: 30px !important;
  }
  .md-margin-35px {
    margin: 35px !important;
  }
  .md-margin-40px {
    margin: 40px !important;
  }
  .md-margin-45px {
    margin: 45px !important;
  }
  .md-margin-50px {
    margin: 50px !important;
  }
  .md-margin-55px {
    margin: 55px !important;
  }
  .md-margin-60px {
    margin: 60px !important;
  }
  .md-margin-65px {
    margin: 65px !important;
  }
  .md-margin-70px {
    margin: 70px !important;
  }
  .md-margin-75px {
    margin: 75px !important;
  }
  .md-margin-80px {
    margin: 80px !important;
  }
  .md-margin-85px {
    margin: 85px !important;
  }
  .md-margin-90px {
    margin: 90px !important;
  }
  .md-margin-95px {
    margin: 95px !important;
  }
  .md-margin-100px {
    margin: 100px !important;
  }
  .md-margin-120px {
    margin: 120px !important;
  }
  .md-margin-150px {
    margin: 150px !important;
  }
  .md-margin-top-one-percent {
    margin-top: 1% !important;
  }
  .md-margin-top-two-percent {
    margin-top: 2% !important;
  }
  .md-margin-top-three-percent {
    margin-top: 3% !important;
  }
  .md-margin-top-four-percent {
    margin-top: 4% !important;
  }
  .md-margin-top-five-percent {
    margin-top: 5% !important;
  }
  .md-margin-top-six-percent {
    margin-top: 6% !important;
  }
  .md-margin-top-seven-percent {
    margin-top: 7% !important;
  }
  .md-margin-top-eight-percent {
    margin-top: 8% !important;
  }
  .md-margin-top-nine-percent {
    margin-top: 9% !important;
  }
  .md-margin-top-ten-percent {
    margin-top: 10% !important;
  }
  .md-margin-top-eleven-percent {
    margin-top: 11% !important;
  }
  .md-margin-top-twelve-percent {
    margin-top: 12% !important;
  }
  .md-margin-top-thirteen-percent {
    margin-top: 13% !important;
  }
  .md-margin-top-fourteen-percent {
    margin-top: 14% !important;
  }
  .md-margin-top-fifteen-percent {
    margin-top: 15% !important;
  }
  .md-margin-top-sixteen-percent {
    margin-top: 16% !important;
  }
  .md-margin-top-seventeen-percent {
    margin-top: 17% !important;
  }
  .md-margin-top-eighteen-percent {
    margin-top: 18% !important;
  }
  .md-margin-top-nineteen-percent {
    margin-top: 19% !important;
  }
  .md-margin-top-twenty-percent {
    margin-top: 20% !important;
  }
  .md-margin-top-twenty-one-percent {
    margin-top: 21% !important;
  }
  .md-margin-top-twenty-two-percent {
    margin-top: 22% !important;
  }
  .md-margin-top-twenty-three-percent {
    margin-top: 23% !important;
  }
  .md-margin-top-twenty-four-percent {
    margin-top: 24% !important;
  }
  .md-margin-top-twenty-five-percent {
    margin-top: 25% !important;
  }
  .md-margin-top-1px {
    margin-top: 1px !important;
  }
  .md-margin-top-5px {
    margin-top: 5px !important;
  }
  .md-margin-top-10px {
    margin-top: 10px !important;
  }
  .md-margin-top-15px {
    margin-top: 15px !important;
  }
  .md-margin-top-20px {
    margin-top: 20px !important;
  }
  .md-margin-top-25px {
    margin-top: 25px !important;
  }
  .md-margin-top-30px {
    margin-top: 30px !important;
  }
  .md-margin-top-35px {
    margin-top: 35px !important;
  }
  .md-margin-top-40px {
    margin-top: 40px !important;
  }
  .md-margin-top-45px {
    margin-top: 45px !important;
  }
  .md-margin-top-50px {
    margin-top: 50px !important;
  }
  .md-margin-top-55px {
    margin-top: 55px !important;
  }
  .md-margin-top-60px {
    margin-top: 60px !important;
  }
  .md-margin-top-65px {
    margin-top: 65px !important;
  }
  .md-margin-top-70px {
    margin-top: 70px !important;
  }
  .md-margin-top-75px {
    margin-top: 75px !important;
  }
  .md-margin-top-80px {
    margin-top: 80px !important;
  }
  .md-margin-top-85px {
    margin-top: 85px !important;
  }
  .md-margin-top-90px {
    margin-top: 90px !important;
  }
  .md-margin-top-95px {
    margin-top: 95px !important;
  }
  .md-margin-top-100px {
    margin-top: 100px !important;
  }
  .md-margin-top-110px {
    margin-top: 110px !important;
  }
  .md-margin-top-120px {
    margin-top: 120px !important;
  }
  .md-margin-top-130px {
    margin-top: 130px !important;
  }
  .md-margin-top-140px {
    margin-top: 140px !important;
  }
  .md-margin-top-150px {
    margin-top: 150px !important;
  }
  .md-margin-bottom-5px {
    margin-bottom: 5px !important;
  }
  .md-margin-bottom-10px {
    margin-bottom: 10px !important;
  }
  .md-margin-bottom-15px {
    margin-bottom: 15px !important;
  }
  .md-margin-bottom-20px {
    margin-bottom: 20px !important;
  }
  .md-margin-bottom-25px {
    margin-bottom: 25px !important;
  }
  .md-margin-bottom-30px {
    margin-bottom: 30px !important;
  }
  .md-margin-bottom-35px {
    margin-bottom: 35px !important;
  }
  .md-margin-bottom-40px {
    margin-bottom: 40px !important;
  }
  .md-margin-bottom-45px {
    margin-bottom: 45px !important;
  }
  .md-margin-bottom-50px {
    margin-bottom: 50px !important;
  }
  .md-margin-bottom-55px {
    margin-bottom: 55px !important;
  }
  .md-margin-bottom-60px {
    margin-bottom: 60px !important;
  }
  .md-margin-bottom-65px {
    margin-bottom: 65px !important;
  }
  .md-margin-bottom-70px {
    margin-bottom: 70px !important;
  }
  .md-margin-bottom-75px {
    margin-bottom: 75px !important;
  }
  .md-margin-bottom-80px {
    margin-bottom: 80px !important;
  }
  .md-margin-bottom-85px {
    margin-bottom: 85px !important;
  }
  .md-margin-bottom-90px {
    margin-bottom: 90px !important;
  }
  .md-margin-bottom-95px {
    margin-bottom: 95px !important;
  }
  .md-margin-bottom-100px {
    margin-bottom: 100px !important;
  }
  .md-margin-bottom-120px {
    margin-bottom: 120px !important;
  }
  .md-margin-bottom-150px {
    margin-bottom: 150px !important;
  }
  .md-margin-bottom-one-percent {
    margin-bottom: 1% !important;
  }
  .md-margin-bottom-two-percent {
    margin-bottom: 2% !important;
  }
  .md-margin-bottom-three-percent {
    margin-bottom: 3% !important;
  }
  .md-margin-bottom-four-percent {
    margin-bottom: 4% !important;
  }
  .md-margin-bottom-five-percent {
    margin-bottom: 5% !important;
  }
  .md-margin-bottom-six-percent {
    margin-bottom: 6% !important;
  }
  .md-margin-bottom-seven-percent {
    margin-bottom: 7% !important;
  }
  .md-margin-bottom-eight-percent {
    margin-bottom: 8% !important;
  }
  .md-margin-bottom-nine-percent {
    margin-bottom: 9% !important;
  }
  .md-margin-bottom-ten-percent {
    margin-bottom: 10% !important;
  }
  .md-margin-bottom-eleven-percent {
    margin-bottom: 11% !important;
  }
  .md-margin-bottom-twelve-percent {
    margin-bottom: 12% !important;
  }
  .md-margin-bottom-thirteen-percent {
    margin-bottom: 13% !important;
  }
  .md-margin-bottom-fourteen-percent {
    margin-bottom: 14% !important;
  }
  .md-margin-bottom-fifteen-percent {
    margin-bottom: 15% !important;
  }
  .md-margin-bottom-sixteen-percent {
    margin-bottom: 16% !important;
  }
  .md-margin-bottom-seventeen-percent {
    margin-bottom: 17% !important;
  }
  .md-margin-bottom-eighteen-percent {
    margin-bottom: 18% !important;
  }
  .md-margin-bottom-nineteen-percent {
    margin-bottom: 19% !important;
  }
  .md-margin-bottom-twenty-percent {
    margin-bottom: 20% !important;
  }
  .md-margin-bottom-twenty-one-percent {
    margin-bottom: 21% !important;
  }
  .md-margin-bottom-twenty-two-percent {
    margin-bottom: 22% !important;
  }
  .md-margin-bottom-twenty-three-percent {
    margin-bottom: 23% !important;
  }
  .md-margin-bottom-twenty-four-percent {
    margin-bottom: 24% !important;
  }
  .md-margin-bottom-twenty-five-percent {
    margin-bottom: 25% !important;
  }
  .md-margin-right-one-percent {
    margin-right: 1% !important;
  }
  .md-margin-right-two-percent {
    margin-right: 2% !important;
  }
  .md-margin-right-three-percent {
    margin-right: 3% !important;
  }
  .md-margin-right-four-percent {
    margin-right: 4% !important;
  }
  .md-margin-right-five-percent {
    margin-right: 5% !important;
  }
  .md-margin-right-six-percent {
    margin-right: 6% !important;
  }
  .md-margin-right-seven-percent {
    margin-right: 7% !important;
  }
  .md-margin-right-eight-percent {
    margin-right: 8% !important;
  }
  .md-margin-right-nine-percent {
    margin-right: 9% !important;
  }
  .md-margin-right-ten-percent {
    margin-right: 10% !important;
  }
  .md-margin-right-eleven-percent {
    margin-right: 11% !important;
  }
  .md-margin-right-twelve-percent {
    margin-right: 12% !important;
  }
  .md-margin-right-thirteen-percent {
    margin-right: 13% !important;
  }
  .md-margin-right-fourteen-percent {
    margin-right: 14% !important;
  }
  .md-margin-right-fifteen-percent {
    margin-right: 15% !important;
  }
  .md-margin-right-sixteen-percent {
    margin-right: 16% !important;
  }
  .md-margin-right-seventeen-percent {
    margin-right: 17% !important;
  }
  .md-margin-right-eighteen-percent {
    margin-right: 18% !important;
  }
  .md-margin-right-nineteen-percent {
    margin-right: 19% !important;
  }
  .md-margin-right-twenty-percent {
    margin-right: 20% !important;
  }
  .md-margin-right-twenty-one-percent {
    margin-right: 21% !important;
  }
  .md-margin-right-twenty-two-percent {
    margin-right: 22% !important;
  }
  .md-margin-right-twenty-three-percent {
    margin-right: 23% !important;
  }
  .md-margin-right-twenty-four-percent {
    margin-right: 24% !important;
  }
  .md-margin-right-twenty-five-percent {
    margin-right: 25% !important;
  }
  .md-margin-right-1px {
    margin-right: 2px !important;
  }
  .md-margin-right-2px {
    margin-right: 2px !important;
  }
  .md-margin-right-5px {
    margin-right: 5px !important;
  }
  .md-margin-right-10px {
    margin-right: 10px !important;
  }
  .md-margin-right-15px {
    margin-right: 15px !important;
  }
  .md-margin-right-20px {
    margin-right: 20px !important;
  }
  .md-margin-right-25px {
    margin-right: 25px !important;
  }
  .md-margin-right-30px {
    margin-right: 30px !important;
  }
  .md-margin-right-35px {
    margin-right: 35px !important;
  }
  .md-margin-right-40px {
    margin-right: 40px !important;
  }
  .md-margin-right-45px {
    margin-right: 45px !important;
  }
  .md-margin-right-50px {
    margin-right: 50px !important;
  }
  .md-margin-right-55px {
    margin-right: 55px !important;
  }
  .md-margin-right-60px {
    margin-right: 60px !important;
  }
  .md-margin-right-65px {
    margin-right: 65px !important;
  }
  .md-margin-right-70px {
    margin-right: 70px !important;
  }
  .md-margin-right-75px {
    margin-right: 75px !important;
  }
  .md-margin-right-80px {
    margin-right: 80px !important;
  }
  .md-margin-right-85px {
    margin-right: 85px !important;
  }
  .md-margin-right-90px {
    margin-right: 90px !important;
  }
  .md-margin-right-95px {
    margin-right: 95px !important;
  }
  .md-margin-right-100px {
    margin-right: 100px !important;
  }
  .md-margin-left-one-percent {
    margin-left: 1% !important;
  }
  .md-margin-left-two-percent {
    margin-left: 2% !important;
  }
  .md-margin-left-three-percent {
    margin-left: 3% !important;
  }
  .md-margin-left-four-percent {
    margin-left: 4% !important;
  }
  .md-margin-left-five-percent {
    margin-left: 5% !important;
  }
  .md-margin-left-six-percent {
    margin-left: 6% !important;
  }
  .md-margin-left-seven-percent {
    margin-left: 7% !important;
  }
  .md-margin-left-eight-percent {
    margin-left: 8% !important;
  }
  .md-margin-left-nine-percent {
    margin-left: 9% !important;
  }
  .md-margin-left-ten-percent {
    margin-left: 10% !important;
  }
  .md-margin-left-eleven-percent {
    margin-left: 11% !important;
  }
  .md-margin-left-twelve-percent {
    margin-left: 12% !important;
  }
  .md-margin-left-thirteen-percent {
    margin-left: 13% !important;
  }
  .md-margin-left-fourteen-percent {
    margin-left: 14% !important;
  }
  .md-margin-left-fifteen-percent {
    margin-left: 15% !important;
  }
  .md-margin-left-sixteen-percent {
    margin-left: 16% !important;
  }
  .md-margin-left-seventeen-percent {
    margin-left: 17% !important;
  }
  .md-margin-left-eighteen-percent {
    margin-left: 18% !important;
  }
  .md-margin-left-nineteen-percent {
    margin-left: 19% !important;
  }
  .md-margin-left-twenty-percent {
    margin-left: 20% !important;
  }
  .md-margin-left-twenty-one-percent {
    margin-left: 21% !important;
  }
  .md-margin-left-twenty-two-percent {
    margin-left: 22% !important;
  }
  .md-margin-left-twenty-three-percent {
    margin-left: 23% !important;
  }
  .md-margin-left-twenty-four-percent {
    margin-left: 24% !important;
  }
  .md-margin-left-twenty-five-percent {
    margin-left: 25% !important;
  }
  .md-margin-left-5px {
    margin-left: 5px !important;
  }
  .md-margin-left-10px {
    margin-left: 10px !important;
  }
  .md-margin-left-15px {
    margin-left: 15px !important;
  }
  .md-margin-left-20px {
    margin-left: 20px !important;
  }
  .md-margin-left-25px {
    margin-left: 25px !important;
  }
  .md-margin-left-30px {
    margin-left: 30px !important;
  }
  .md-margin-left-35px {
    margin-left: 35px !important;
  }
  .md-margin-left-40px {
    margin-left: 40px !important;
  }
  .md-margin-left-45px {
    margin-left: 45px !important;
  }
  .md-margin-left-50px {
    margin-left: 50px !important;
  }
  .md-margin-left-55px {
    margin-left: 55px !important;
  }
  .md-margin-left-60px {
    margin-left: 60px !important;
  }
  .md-margin-left-65px {
    margin-left: 65px !important;
  }
  .md-margin-left-70px {
    margin-left: 70px !important;
  }
  .md-margin-left-75px {
    margin-left: 75px !important;
  }
  .md-margin-left-80px {
    margin-left: 80px !important;
  }
  .md-margin-left-85px {
    margin-left: 85px !important;
  }
  .md-margin-left-90px {
    margin-left: 90px !important;
  }
  .md-margin-left-95px {
    margin-left: 95px !important;
  }
  .md-margin-left-100px {
    margin-left: 100px !important;
  }
  .md-margin-left-150px {
    margin-left: 150px !important;
  }
  .md-margin-top-bottom-one-percent {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .md-margin-top-bottom-two-percent {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
  .md-margin-top-bottom-three-percent {
    margin-top: 3% !important;
    margin-bottom: 3% !important;
  }
  .md-margin-top-bottom-four-percent {
    margin-top: 4% !important;
    margin-bottom: 4% !important;
  }
  .md-margin-top-bottom-five-percent {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
  }
  .md-margin-top-bottom-six-percent {
    margin-top: 6% !important;
    margin-bottom: 6% !important;
  }
  .md-margin-top-bottom-seven-percent {
    margin-top: 7% !important;
    margin-bottom: 7% !important;
  }
  .md-margin-top-bottom-eight-percent {
    margin-top: 8% !important;
    margin-bottom: 8% !important;
  }
  .md-margin-top-bottom-nine-percent {
    margin-top: 9% !important;
    margin-bottom: 9% !important;
  }
  .md-margin-top-bottom-ten-percent {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .md-margin-top-bottom-eleven-percent {
    margin-top: 11% !important;
    margin-bottom: 11% !important;
  }
  .md-margin-top-bottom-twelve-percent {
    margin-top: 12% !important;
    margin-bottom: 12% !important;
  }
  .md-margin-top-bottom-thirteen-percent {
    margin-top: 13% !important;
    margin-bottom: 13% !important;
  }
  .md-margin-top-bottom-fourteen-percent {
    margin-top: 14% !important;
    margin-bottom: 14% !important;
  }
  .md-margin-top-bottom-fifteen-percent {
    margin-top: 15% !important;
    margin-bottom: 15% !important;
  }
  .md-margin-top-bottom-sixteen-percent {
    margin-top: 16% !important;
    margin-bottom: 16% !important;
  }
  .md-margin-top-bottom-seventeen-percent {
    margin-top: 17% !important;
    margin-bottom: 17% !important;
  }
  .md-margin-top-bottom-eighteen-percent {
    margin-top: 18% !important;
    margin-bottom: 18% !important;
  }
  .md-margin-top-bottom-nineteen-percent {
    margin-top: 19% !important;
    margin-bottom: 19% !important;
  }
  .md-margin-top-bottom-twenty-percent {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .md-margin-top-bottom-twenty-one-percent {
    margin-top: 21% !important;
    margin-bottom: 21% !important;
  }
  .md-margin-top-bottom-twenty-two-percent {
    margin-top: 22% !important;
    margin-bottom: 22% !important;
  }
  .md-margin-top-bottom-twenty-three-percent {
    margin-top: 23% !important;
    margin-bottom: 23% !important;
  }
  .md-margin-top-bottom-twenty-four-percent {
    margin-top: 24% !important;
    margin-bottom: 24% !important;
  }
  .md-margin-top-bottom-twenty-five-percent {
    margin-top: 25% !important;
    margin-bottom: 25% !important;
  }
  .md-margin-top-bottom-5px {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .md-margin-top-bottom-10px {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .md-margin-top-bottom-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .md-margin-top-bottom-20px {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .md-margin-top-bottom-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .md-margin-top-bottom-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .md-margin-top-bottom-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .md-margin-top-bottom-40px {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .md-margin-top-bottom-45px {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .md-margin-top-bottom-50px {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .md-margin-top-bottom-55px {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .md-margin-top-bottom-60px {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .md-margin-top-bottom-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .md-margin-top-bottom-70px {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .md-margin-top-bottom-75px {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .md-margin-top-bottom-80px {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .md-margin-top-bottom-85px {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .md-margin-top-bottom-90px {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .md-margin-top-bottom-95px {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .md-margin-top-bottom-100px {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .md-margin-top-bottom-150px {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .md-no-padding {
    padding: 0 !important;
  }
  .md-no-padding-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .md-no-padding-top-bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .md-no-padding-top {
    padding-top: 0 !important;
  }
  .md-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .md-no-padding-left {
    padding-left: 0 !important;
  }
  .md-no-padding-right {
    padding-right: 0 !important;
  }
  .md-padding-one-percent {
    padding: 1% !important;
  }
  .md-padding-two-percent {
    padding: 2% !important;
  }
  .md-padding-three-percent {
    padding: 3% !important;
  }
  .md-padding-four-percent {
    padding: 4% !important;
  }
  .md-padding-five-percent {
    padding: 5% !important;
  }
  .md-padding-six-percent {
    padding: 6% !important;
  }
  .md-padding-seven-percent {
    padding: 7% !important;
  }
  .md-padding-eight-percent {
    padding: 8% !important;
  }
  .md-padding-nine-percent {
    padding: 9% !important;
  }
  .md-padding-ten-percent {
    padding: 10% !important;
  }
  .md-padding-eleven-percent {
    padding: 11% !important;
  }
  .md-padding-twelve-percent {
    padding: 12% !important;
  }
  .md-padding-thirteen-percent {
    padding: 13% !important;
  }
  .md-padding-fourteen-percent {
    padding: 14% !important;
  }
  .md-padding-fifteen-percent {
    padding: 15% !important;
  }
  .md-padding-sixteen-percent {
    padding: 16% !important;
  }
  .md-padding-seventeen-percent {
    padding: 17% !important;
  }
  .md-padding-eighteen-percent {
    padding: 18% !important;
  }
  .md-padding-nineteen-percent {
    padding: 19% !important;
  }
  .md-padding-twenty-percent {
    padding: 20% !important;
  }
  .md-padding-twenty-one-percent {
    padding: 21% !important;
  }
  .md-padding-twenty-two-percent {
    padding: 22% !important;
  }
  .md-padding-twenty-three-percent {
    padding: 23% !important;
  }
  .md-padding-twenty-four-percent {
    padding: 24% !important;
  }
  .md-padding-twenty-five-percent {
    padding: 25% !important;
  }
  .md-padding-5px {
    padding: 5px !important;
  }
  .md-padding-10px {
    padding: 10px !important;
  }
  .md-padding-15px {
    padding: 15px !important;
  }
  .md-padding-20px {
    padding: 20px !important;
  }
  .md-padding-25px {
    padding: 25px !important;
  }
  .md-padding-30px {
    padding: 30px !important;
  }
  .md-padding-35px {
    padding: 35px !important;
  }
  .md-padding-40px {
    padding: 40px !important;
  }
  .md-padding-45px {
    padding: 45px !important;
  }
  .md-padding-50px {
    padding: 50px !important;
  }
  .md-padding-55px {
    padding: 55px !important;
  }
  .md-padding-60px {
    padding: 60px !important;
  }
  .md-padding-65px {
    padding: 65px !important;
  }
  .md-padding-70px {
    padding: 70px !important;
  }
  .md-padding-75px {
    padding: 75px !important;
  }
  .md-padding-80px {
    padding: 80px !important;
  }
  .md-padding-85px {
    padding: 85px !important;
  }
  .md-padding-90px {
    padding: 90px !important;
  }
  .md-padding-95px {
    padding: 95px !important;
  }
  .md-padding-100px {
    padding: 100px !important;
  }
  .md-padding-150px {
    padding: 150px !important;
  }
  .md-padding-top-one-percent {
    padding-top: 1% !important;
  }
  .md-padding-top-two-percent {
    padding-top: 2% !important;
  }
  .md-padding-top-three-percent {
    padding-top: 3% !important;
  }
  .md-padding-top-four-percent {
    padding-top: 4% !important;
  }
  .md-padding-top-five-percent {
    padding-top: 5% !important;
  }
  .md-padding-top-six-percent {
    padding-top: 6% !important;
  }
  .md-padding-top-seven-percent {
    padding-top: 7% !important;
  }
  .md-padding-top-eight-percent {
    padding-top: 8% !important;
  }
  .md-padding-top-nine-percent {
    padding-top: 9% !important;
  }
  .md-padding-top-ten-percent {
    padding-top: 10% !important;
  }
  .md-padding-top-eleven-percent {
    padding-top: 11% !important;
  }
  .md-padding-top-twelve-percent {
    padding-top: 12% !important;
  }
  .md-padding-top-thirteen-percent {
    padding-top: 13% !important;
  }
  .md-padding-top-fourteen-percent {
    padding-top: 14% !important;
  }
  .md-padding-top-fifteen-percent {
    padding-top: 15% !important;
  }
  .md-padding-top-sixteen-percent {
    padding-top: 16% !important;
  }
  .md-padding-top-seventeen-percent {
    padding-top: 17% !important;
  }
  .md-padding-top-eighteen-percent {
    padding-top: 18% !important;
  }
  .md-padding-top-nineteen-percent {
    padding-top: 19% !important;
  }
  .md-padding-top-twenty-percent {
    padding-top: 20% !important;
  }
  .md-padding-top-twenty-one-percent {
    padding-top: 21% !important;
  }
  .md-padding-top-twenty-two-percent {
    padding-top: 22% !important;
  }
  .md-padding-top-twenty-three-percent {
    padding-top: 23% !important;
  }
  .md-padding-top-twenty-four-percent {
    padding-top: 24% !important;
  }
  .md-padding-top-twenty-five-percent {
    padding-top: 25% !important;
  }
  .md-padding-top-1px {
    padding-top: 1px !important;
  }
  .md-padding-top-2px {
    padding-top: 2px !important;
  }
  .md-padding-top-5px {
    padding-top: 5px !important;
  }
  .md-padding-top-10px {
    padding-top: 10px !important;
  }
  .md-padding-top-15px {
    padding-top: 15px !important;
  }
  .md-padding-top-20px {
    padding-top: 20px !important;
  }
  .md-padding-top-25px {
    padding-top: 25px !important;
  }
  .md-padding-top-30px {
    padding-top: 30px !important;
  }
  .md-padding-top-35px {
    padding-top: 35px !important;
  }
  .md-padding-top-40px {
    padding-top: 40px !important;
  }
  .md-padding-top-45px {
    padding-top: 45px !important;
  }
  .md-padding-top-50px {
    padding-top: 50px !important;
  }
  .md-padding-top-55px {
    padding-top: 55px !important;
  }
  .md-padding-top-60px {
    padding-top: 60px !important;
  }
  .md-padding-top-65px {
    padding-top: 65px !important;
  }
  .md-padding-top-70px {
    padding-top: 70px !important;
  }
  .md-padding-top-75px {
    padding-top: 75px !important;
  }
  .md-padding-top-80px {
    padding-top: 80px !important;
  }
  .md-padding-top-85px {
    padding-top: 85px !important;
  }
  .md-padding-top-90px {
    padding-top: 90px !important;
  }
  .md-padding-top-95px {
    padding-top: 95px !important;
  }
  .md-padding-top-100px {
    padding-top: 100px !important;
  }
  .md-padding-top-120px {
    padding-top: 120px !important;
  }
  .md-padding-top-150px {
    padding-top: 150px !important;
  }
  .md-padding-bottom-one-percent {
    padding-bottom: 1% !important;
  }
  .md-padding-bottom-two-percent {
    padding-bottom: 2% !important;
  }
  .md-padding-bottom-three-percent {
    padding-bottom: 3% !important;
  }
  .md-padding-bottom-four-percent {
    padding-bottom: 4% !important;
  }
  .md-padding-bottom-five-percent {
    padding-bottom: 5% !important;
  }
  .md-padding-bottom-six-percent {
    padding-bottom: 6% !important;
  }
  .md-padding-bottom-seven-percent {
    padding-bottom: 7% !important;
  }
  .md-padding-bottom-eight-percent {
    padding-bottom: 8% !important;
  }
  .md-padding-bottom-nine-percent {
    padding-bottom: 9% !important;
  }
  .md-padding-bottom-ten-percent {
    padding-bottom: 10% !important;
  }
  .md-padding-bottom-eleven-percent {
    padding-bottom: 11% !important;
  }
  .md-padding-bottom-twelve-percent {
    padding-bottom: 12% !important;
  }
  .md-padding-bottom-thirteen-percent {
    padding-bottom: 13% !important;
  }
  .md-padding-bottom-fourteen-percent {
    padding-bottom: 14% !important;
  }
  .md-padding-bottom-fifteen-percent {
    padding-bottom: 15% !important;
  }
  .md-padding-bottom-sixteen-percent {
    padding-bottom: 16% !important;
  }
  .md-padding-bottom-seventeen-percent {
    padding-bottom: 17% !important;
  }
  .md-padding-bottom-eighteen-percent {
    padding-bottom: 18% !important;
  }
  .md-padding-bottom-nineteen-percent {
    padding-bottom: 19% !important;
  }
  .md-padding-bottom-twenty-percent {
    padding-bottom: 20% !important;
  }
  .md-padding-bottom-twenty-one-percent {
    padding-bottom: 21% !important;
  }
  .md-padding-bottom-twenty-two-percent {
    padding-bottom: 22% !important;
  }
  .md-padding-bottom-twenty-three-percent {
    padding-bottom: 23% !important;
  }
  .md-padding-bottom-twenty-four-percent {
    padding-bottom: 24% !important;
  }
  .md-padding-bottom-twenty-five-percent {
    padding-bottom: 25% !important;
  }
  .md-padding-bottom-5px {
    padding-bottom: 5px !important;
  }
  .md-padding-bottom-10px {
    padding-bottom: 10px !important;
  }
  .md-padding-bottom-15px {
    padding-bottom: 15px !important;
  }
  .md-padding-bottom-20px {
    padding-bottom: 20px !important;
  }
  .md-padding-bottom-25px {
    padding-bottom: 25px !important;
  }
  .md-padding-bottom-30px {
    padding-bottom: 30px !important;
  }
  .md-padding-bottom-35px {
    padding-bottom: 35px !important;
  }
  .md-padding-bottom-40px {
    padding-bottom: 40px !important;
  }
  .md-padding-bottom-45px {
    padding-bottom: 45px !important;
  }
  .md-padding-bottom-50px {
    padding-bottom: 50px !important;
  }
  .md-padding-bottom-55px {
    padding-bottom: 55px !important;
  }
  .md-padding-bottom-60px {
    padding-bottom: 60px !important;
  }
  .md-padding-bottom-65px {
    padding-bottom: 65px !important;
  }
  .md-padding-bottom-70px {
    padding-bottom: 70px !important;
  }
  .md-padding-bottom-75px {
    padding-bottom: 75px !important;
  }
  .md-padding-bottom-80px {
    padding-bottom: 80px !important;
  }
  .md-padding-bottom-85px {
    padding-bottom: 85px !important;
  }
  .md-padding-bottom-90px {
    padding-bottom: 90px !important;
  }
  .md-padding-bottom-95px {
    padding-bottom: 95px !important;
  }
  .md-padding-bottom-100px {
    padding-bottom: 100px !important;
  }
  .md-padding-bottom-120px {
    padding-bottom: 120px !important;
  }
  .md-padding-bottom-150px {
    padding-bottom: 150px !important;
  }
  .md-padding-bottom-200px {
    padding-bottom: 200px !important;
  }
  .md-padding-bottom-250px {
    padding-bottom: 250px !important;
  }
  .md-padding-right-one-percent {
    padding-right: 1% !important;
  }
  .md-padding-right-two-percent {
    padding-right: 2% !important;
  }
  .md-padding-right-three-percent {
    padding-right: 3% !important;
  }
  .md-padding-right-four-percent {
    padding-right: 4% !important;
  }
  .md-padding-right-five-percent {
    padding-right: 5% !important;
  }
  .md-padding-right-six-percent {
    padding-right: 6% !important;
  }
  .md-padding-right-seven-percent {
    padding-right: 7% !important;
  }
  .md-padding-right-eight-percent {
    padding-right: 8% !important;
  }
  .md-padding-right-nine-percent {
    padding-right: 9% !important;
  }
  .md-padding-right-ten-percent {
    padding-right: 10% !important;
  }
  .md-padding-right-eleven-percent {
    padding-right: 11% !important;
  }
  .md-padding-right-twelve-percent {
    padding-right: 12% !important;
  }
  .md-padding-right-thirteen-percent {
    padding-right: 13% !important;
  }
  .md-padding-right-fourteen-percent {
    padding-right: 14% !important;
  }
  .md-padding-right-fifteen-percent {
    padding-right: 15% !important;
  }
  .md-padding-right-sixteen-percent {
    padding-right: 16% !important;
  }
  .md-padding-right-seventeen-percent {
    padding-right: 17% !important;
  }
  .md-padding-right-eighteen-percent {
    padding-right: 18% !important;
  }
  .md-padding-right-nineteen-percent {
    padding-right: 19% !important;
  }
  .md-padding-right-twenty-percent {
    padding-right: 20% !important;
  }
  .md-padding-right-twenty-one-percent {
    padding-right: 21% !important;
  }
  .md-padding-right-twenty-two-percent {
    padding-right: 22% !important;
  }
  .md-padding-right-twenty-three-percent {
    padding-right: 23% !important;
  }
  .md-padding-right-twenty-four-percent {
    padding-right: 24% !important;
  }
  .md-padding-right-twenty-five-percent {
    padding-right: 25% !important;
  }
  .md-padding-right-5px {
    padding-right: 5px !important;
  }
  .md-padding-right-10px {
    padding-right: 10px !important;
  }
  .md-padding-right-15px {
    padding-right: 15px !important;
  }
  .md-padding-right-20px {
    padding-right: 20px !important;
  }
  .md-padding-right-25px {
    padding-right: 25px !important;
  }
  .md-padding-right-30px {
    padding-right: 30px !important;
  }
  .md-padding-right-35px {
    padding-right: 35px !important;
  }
  .md-padding-right-40px {
    padding-right: 40px !important;
  }
  .md-padding-right-45px {
    padding-right: 45px !important;
  }
  .md-padding-right-50px {
    padding-right: 50px !important;
  }
  .md-padding-right-55px {
    padding-right: 55px !important;
  }
  .md-padding-right-60px {
    padding-right: 60px !important;
  }
  .md-padding-right-65px {
    padding-right: 65px !important;
  }
  .md-padding-right-70px {
    padding-right: 70px !important;
  }
  .md-padding-right-75px {
    padding-right: 75px !important;
  }
  .md-padding-right-80px {
    padding-right: 80px !important;
  }
  .md-padding-right-85px {
    padding-right: 85px !important;
  }
  .md-padding-right-90px {
    padding-right: 90px !important;
  }
  .md-padding-right-95px {
    padding-right: 95px !important;
  }
  .md-padding-right-100px {
    padding-right: 100px !important;
  }
  .md-padding-right-150px {
    padding-right: 150px !important;
  }
  .md-padding-left-one-percent {
    padding-left: 1% !important;
  }
  .md-padding-left-two-percent {
    padding-left: 2% !important;
  }
  .md-padding-left-three-percent {
    padding-left: 3% !important;
  }
  .md-padding-left-four-percent {
    padding-left: 4% !important;
  }
  .md-padding-left-five-percent {
    padding-left: 5% !important;
  }
  .md-padding-left-six-percent {
    padding-left: 6% !important;
  }
  .md-padding-left-seven-percent {
    padding-left: 7% !important;
  }
  .md-padding-left-eight-percent {
    padding-left: 8% !important;
  }
  .md-padding-left-nine-percent {
    padding-left: 9% !important;
  }
  .md-padding-left-ten-percent {
    padding-left: 10% !important;
  }
  .md-padding-left-eleven-percent {
    padding-left: 11% !important;
  }
  .md-padding-left-twelve-percent {
    padding-left: 12% !important;
  }
  .md-padding-left-thirteen-percent {
    padding-left: 13% !important;
  }
  .md-padding-left-fourteen-percent {
    padding-left: 14% !important;
  }
  .md-padding-left-fifteen-percent {
    padding-left: 15% !important;
  }
  .md-padding-left-sixteen-percent {
    padding-left: 16% !important;
  }
  .md-padding-left-seventeen-percent {
    padding-left: 17% !important;
  }
  .md-padding-left-eighteen-percent {
    padding-left: 18% !important;
  }
  .md-padding-left-nineteen-percent {
    padding-left: 19% !important;
  }
  .md-padding-left-twenty-percent {
    padding-left: 20% !important;
  }
  .md-padding-left-twenty-one-percent {
    padding-left: 21% !important;
  }
  .md-padding-left-twenty-two-percent {
    padding-left: 22% !important;
  }
  .md-padding-left-twenty-three-percent {
    padding-left: 23% !important;
  }
  .md-padding-left-twenty-four-percent {
    padding-left: 24% !important;
  }
  .md-padding-left-twenty-five-percent {
    padding-left: 25% !important;
  }
  .md-padding-left-5px {
    padding-left: 5px !important;
  }
  .md-padding-left-10px {
    padding-left: 10px !important;
  }
  .md-padding-left-15px {
    padding-left: 15px !important;
  }
  .md-padding-left-20px {
    padding-left: 20px !important;
  }
  .md-padding-left-25px {
    padding-left: 25px !important;
  }
  .md-padding-left-30px {
    padding-left: 30px !important;
  }
  .md-padding-left-35px {
    padding-left: 35px !important;
  }
  .md-padding-left-40px {
    padding-left: 40px !important;
  }
  .md-padding-left-45px {
    padding-left: 45px !important;
  }
  .md-padding-left-50px {
    padding-left: 50px !important;
  }
  .md-padding-left-55px {
    padding-left: 55px !important;
  }
  .md-padding-left-60px {
    padding-left: 60px !important;
  }
  .md-padding-left-65px {
    padding-left: 65px !important;
  }
  .md-padding-left-70px {
    padding-left: 70px !important;
  }
  .md-padding-left-75px {
    padding-left: 75px !important;
  }
  .md-padding-left-80px {
    padding-left: 80px !important;
  }
  .md-padding-left-85px {
    padding-left: 85px !important;
  }
  .md-padding-left-90px {
    padding-left: 90px !important;
  }
  .md-padding-left-95px {
    padding-left: 95px !important;
  }
  .md-padding-left-100px {
    padding-left: 100px !important;
  }
  .md-padding-left-150px {
    padding-left: 150px !important;
  }
  .md-padding-top-bottom-one-percent {
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
  .md-padding-top-bottom-two-percent {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }
  .md-padding-top-bottom-three-percent {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
  }
  .md-padding-top-bottom-four-percent {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
  }
  .md-padding-top-bottom-five-percent {
    padding-top: 5% !important;
    padding-bottom: 5% !important;
  }
  .md-padding-top-bottom-six-percent {
    padding-top: 6% !important;
    padding-bottom: 6% !important;
  }
  .md-padding-top-bottom-seven-percent {
    padding-top: 7% !important;
    padding-bottom: 7% !important;
  }
  .md-padding-top-bottom-eight-percent {
    padding-top: 8% !important;
    padding-bottom: 8% !important;
  }
  .md-padding-top-bottom-nine-percent {
    padding-top: 9% !important;
    padding-bottom: 9% !important;
  }
  .md-padding-top-bottom-ten-percent {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .md-padding-top-bottom-eleven-percent {
    padding-top: 11% !important;
    padding-bottom: 11% !important;
  }
  .md-padding-top-bottom-twelve-percent {
    padding-top: 12% !important;
    padding-bottom: 12% !important;
  }
  .md-padding-top-bottom-thirteen-percent {
    padding-top: 13% !important;
    padding-bottom: 13% !important;
  }
  .md-padding-top-bottom-fourteen-percent {
    padding-top: 14% !important;
    padding-bottom: 14% !important;
  }
  .md-padding-top-bottom-fifteen-percent {
    padding-top: 15% !important;
    padding-bottom: 15% !important;
  }
  .md-padding-top-bottom-sixteen-percent {
    padding-top: 16% !important;
    padding-bottom: 16% !important;
  }
  .md-padding-top-bottom-seventeen-percent {
    padding-top: 17% !important;
    padding-bottom: 17% !important;
  }
  .md-padding-top-bottom-eighteen-percent {
    padding-top: 18% !important;
    padding-bottom: 18% !important;
  }
  .md-padding-top-bottom-nineteen-percent {
    padding-top: 19% !important;
    padding-bottom: 19% !important;
  }
  .md-padding-top-bottom-twenty-percent {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .md-padding-top-bottom-twenty-one-percent {
    padding-top: 21% !important;
    padding-bottom: 21% !important;
  }
  .md-padding-top-bottom-twenty-two-percent {
    padding-top: 22% !important;
    padding-bottom: 22% !important;
  }
  .md-padding-top-bottom-twenty-three-percent {
    padding-top: 23% !important;
    padding-bottom: 23% !important;
  }
  .md-padding-top-bottom-twenty-four-percent {
    padding-top: 24% !important;
    padding-bottom: 24% !important;
  }
  .md-padding-top-bottom-twenty-five-percent {
    padding-top: 25% !important;
    padding-bottom: 25% !important;
  }
  .md-padding-top-bottom-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md-padding-top-bottom-10px {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md-padding-top-bottom-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md-padding-top-bottom-20px {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md-padding-top-bottom-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .md-padding-top-bottom-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md-padding-top-bottom-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md-padding-top-bottom-40px {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md-padding-top-bottom-45px {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .md-padding-top-bottom-50px {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md-padding-top-bottom-55px {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .md-padding-top-bottom-60px {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .md-padding-top-bottom-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .md-padding-top-bottom-70px {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .md-padding-top-bottom-75px {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .md-padding-top-bottom-80px {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md-padding-top-bottom-85px {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .md-padding-top-bottom-90px {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .md-padding-top-bottom-95px {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .md-padding-top-bottom-100px {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .md-padding-top-bottom-120px {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .md-padding-top-bottom-150px {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .md-padding-top-bottom-200px {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .md-padding-top-bottom-250px {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .md-padding-top-bottom-300px {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .md-padding-left-right-one-percent {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
  .md-padding-left-right-two-percent {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .md-padding-left-right-three-percent {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
  .md-padding-left-right-four-percent {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
  .md-padding-left-right-five-percent {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .md-padding-left-right-six-percent {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .md-padding-left-right-seven-percent {
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
  .md-padding-left-right-eight-percent {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  .md-padding-left-right-nine-percent {
    padding-left: 9% !important;
    padding-right: 9% !important;
  }
  .md-padding-left-right-ten-percent {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .md-padding-left-right-eleven-percent {
    padding-left: 11% !important;
    padding-right: 11% !important;
  }
  .md-padding-left-right-twelve-percent {
    padding-left: 12% !important;
    padding-right: 12% !important;
  }
  .md-padding-left-right-thirteen-percent {
    padding-left: 13% !important;
    padding-right: 13% !important;
  }
  .md-padding-left-right-fourteen-percent {
    padding-left: 14% !important;
    padding-right: 14% !important;
  }
  .md-padding-left-right-fifteen-percent {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .md-padding-left-right-sixteen-percent {
    padding-left: 16% !important;
    padding-right: 16% !important;
  }
  .md-padding-left-right-seventeen-percent {
    padding-left: 17% !important;
    padding-right: 17% !important;
  }
  .md-padding-left-right-eighteen-percent {
    padding-left: 18% !important;
    padding-right: 18% !important;
  }
  .md-padding-left-right-nineteen-percent {
    padding-left: 19% !important;
    padding-right: 19% !important;
  }
  .md-padding-left-right-twenty-percent {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .md-padding-left-right-twenty-one-percent {
    padding-left: 21% !important;
    padding-right: 21% !important;
  }
  .md-padding-left-right-twenty-two-percent {
    padding-left: 22% !important;
    padding-right: 22% !important;
  }
  .md-padding-left-right-twenty-three-percent {
    padding-left: 23% !important;
    padding-right: 23% !important;
  }
  .md-padding-left-right-twenty-four-percent {
    padding-left: 24% !important;
    padding-right: 24% !important;
  }
  .md-padding-left-right-twenty-five-percent {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }
  .md-padding-left-right-5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md-padding-left-right-10px {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md-padding-left-right-15px {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md-padding-left-right-20px {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md-padding-left-right-25px {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .md-padding-left-right-30px {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md-padding-left-right-35px {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md-padding-left-right-40px {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md-padding-left-right-45px {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md-padding-left-right-50px {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md-padding-left-right-55px {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .md-padding-left-right-60px {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .md-padding-left-right-65px {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .md-padding-left-right-70px {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .md-padding-left-right-75px {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .md-padding-left-right-80px {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .md-padding-left-right-85px {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .md-padding-left-right-90px {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .md-padding-left-right-95px {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .md-padding-left-right-100px {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .md-padding-left-right-120px {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .md-padding-left-right-150px {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .md-width-1px {
    width: 1px !important;
  }
  .md-width-2px {
    width: 2px !important;
  }
  .md-width-3px {
    width: 3px !important;
  }
  .md-width-4px {
    width: 4px !important;
  }
  .md-width-5px {
    width: 5px !important;
  }
  .md-width-6px {
    width: 6px !important;
  }
  .md-width-7px {
    width: 7px !important;
  }
  .md-width-8px {
    width: 8px !important;
  }
  .md-width-9px {
    width: 9px !important;
  }
  .md-width-10px {
    width: 10px !important;
  }
  .md-width-15px {
    width: 15px !important;
  }
  .md-width-20px {
    width: 20px !important;
  }
  .md-width-25px {
    width: 25px !important;
  }
  .md-width-30px {
    width: 30px !important;
  }
  .md-width-35px {
    width: 35px !important;
  }
  .md-width-40px {
    width: 40px !important;
  }
  .md-width-45px {
    width: 45px !important;
  }
  .md-width-50px {
    width: 50px !important;
  }
  .md-width-55px {
    width: 55px !important;
  }
  .md-width-60px {
    width: 60px !important;
  }
  .md-width-65px {
    width: 65px !important;
  }
  .md-width-70px {
    width: 70px !important;
  }
  .md-width-75px {
    width: 75px !important;
  }
  .md-width-80px {
    width: 80px !important;
  }
  .md-width-85px {
    width: 85px !important;
  }
  .md-width-90px {
    width: 90px !important;
  }
  .md-width-100px {
    width: 100px !important;
  }
  .md-width-110px {
    width: 110px !important;
  }
  .md-width-120px {
    width: 120px !important;
  }
  .md-width-130px {
    width: 130px !important;
  }
  .md-width-140px {
    width: 140px !important;
  }
  .md-width-150px {
    width: 150px !important;
  }
  .md-width-160px {
    width: 160px !important;
  }
  .md-width-170px {
    width: 170px !important;
  }
  .md-width-180px {
    width: 180px !important;
  }
  .md-width-190px {
    width: 190px !important;
  }
  .md-width-200px {
    width: 200px !important;
  }
  .md-width-250px {
    width: 250px !important;
  }
  .md-width-300px {
    width: 300px !important;
  }
  .md-width-350px {
    width: 350px !important;
  }
  .md-width-400px {
    width: 400px !important;
  }
  .md-width-450px {
    width: 450px !important;
  }
  .md-width-500px {
    width: 500px !important;
  }
  .md-width-550px {
    width: 550px !important;
  }
  .md-width-600px {
    width: 600px !important;
  }
  .md-width-650px {
    width: 650px !important;
  }
  .md-width-700px {
    width: 700px !important;
  }
  .md-width-750px {
    width: 750px !important;
  }
  .md-width-800px {
    width: 800px !important;
  }
  .md-width-850px {
    width: 850px !important;
  }
  .md-width-900px {
    width: 900px !important;
  }
  .md-width-950px {
    width: 950px !important;
  }
  .md-width-1000px {
    width: 1000px !important;
  }
  .md-width-5 {
    width: 5% !important;
  }
  .md-width-10 {
    width: 10% !important;
  }
  .md-width-12 {
    width: 12% !important;
  }
  .md-width-15 {
    width: 15% !important;
  }
  .md-width-20 {
    width: 20% !important;
  }
  .md-width-25 {
    width: 25% !important;
  }
  .md-width-30 {
    width: 30% !important;
  }
  .md-width-35 {
    width: 35% !important;
  }
  .md-width-40 {
    width: 40% !important;
  }
  .md-width-45 {
    width: 45% !important;
  }
  .md-width-50 {
    width: 50% !important;
  }
  .md-width-55 {
    width: 55% !important;
  }
  .md-width-60 {
    width: 60% !important;
  }
  .md-width-65 {
    width: 65% !important;
  }
  .md-width-70 {
    width: 70% !important;
  }
  .md-width-75 {
    width: 75% !important;
  }
  .md-width-80 {
    width: 80% !important;
  }
  .md-width-85 {
    width: 85% !important;
  }
  .md-width-90 {
    width: 90% !important;
  }
  .md-width-95 {
    width: 95% !important;
  }
  .md-width-100 {
    width: 100% !important;
  }
  .md-width-auto {
    width: auto !important;
  }
  .md-height-1px {
    height: 1px !important;
  }
  .md-height-2px {
    height: 2px !important;
  }
  .md-height-3px {
    height: 3px !important;
  }
  .md-height-4px {
    height: 4px !important;
  }
  .md-height-5px {
    height: 5px !important;
  }
  .md-height-6px {
    height: 6px !important;
  }
  .md-height-7px {
    height: 7px !important;
  }
  .md-height-8px {
    height: 8px !important;
  }
  .md-height-9px {
    height: 9px !important;
  }
  .md-height-5px {
    height: 5px !important;
  }
  .md-height-10px {
    height: 10px !important;
  }
  .md-height-15px {
    height: 15px !important;
  }
  .md-height-20px {
    height: 20px !important;
  }
  .md-height-25px {
    height: 25px !important;
  }
  .md-height-30px {
    height: 30px !important;
  }
  .md-height-35px {
    height: 30px !important;
  }
  .md-height-40px {
    height: 40px !important;
  }
  .md-height-45px {
    height: 45px !important;
  }
  .md-height-50px {
    height: 50px !important;
  }
  .md-height-55px {
    height: 55px !important;
  }
  .md-height-60px {
    height: 60px !important;
  }
  .md-height-65px {
    height: 60px !important;
  }
  .md-height-70px {
    height: 70px !important;
  }
  .md-height-75px {
    height: 70px !important;
  }
  .md-height-80px {
    height: 80px !important;
  }
  .md-height-85px {
    height: 80px !important;
  }
  .md-height-90px {
    height: 90px !important;
  }
  .md-height-95px {
    height: 90px !important;
  }
  .md-height-100px {
    height: 100px !important;
  }
  .md-height-110px {
    height: 110px !important;
  }
  .md-height-120px {
    height: 120px !important;
  }
  .md-height-130px {
    height: 130px !important;
  }
  .md-height-140px {
    height: 130px !important;
  }
  .md-height-150px {
    height: 150px !important;
  }
  .md-height-160px {
    height: 160px !important;
  }
  .md-height-170px {
    height: 170px !important;
  }
  .md-height-180px {
    height: 180px !important;
  }
  .md-height-190px {
    height: 190px !important;
  }
  .md-height-200px {
    height: 200px !important;
  }
  .md-height-250px {
    height: 250px !important;
  }
  .md-height-300px {
    height: 300px !important;
  }
  .md-height-350px {
    height: 350px !important;
  }
  .md-height-400px {
    height: 400px !important;
  }
  .md-height-450px {
    height: 450px !important;
  }
  .md-height-500px {
    height: 500px !important;
  }
  .md-height-550px {
    height: 550px !important;
  }
  .md-height-600px {
    height: 600px !important;
  }
  .md-height-650px {
    height: 650px !important;
  }
  .md-height-700px {
    height: 700px !important;
  }
  .md-height-10 {
    height: 10% !important;
  }
  .md-height-20 {
    height: 20% !important;
  }
  .md-height-30 {
    height: 30% !important;
  }
  .md-height-40 {
    height: 40% !important;
  }
  .md-height-50 {
    height: 50% !important;
  }
  .md-height-55 {
    height: 55% !important;
  }
  .md-height-60 {
    height: 60% !important;
  }
  .md-height-70 {
    height: 70% !important;
  }
  .md-height-80 {
    height: 80% !important;
  }
  .md-height-90 {
    height: 90% !important;
  }
  .md-height-100 {
    height: 100% !important;
  }
  .md-height-full {
    height: 100vh !important;
  }
  .md-height-auto {
    height: auto !important;
  }
  .md-max-height-100 {
    max-height: 100% !important;
  }
  .md-full-screen {
    min-height: 980px !important;
  }
}

@media all and (max-width: 767px) {
  .first-col {
    order: 13;
  }

  .uk-inline-clip-custom > img {
    max-width: 50%;
    margin-left: 25%;
    align-self: center !important;
  }

  #return-to-top {
    right: 45px !important;
  }
  .title-small {
    font-size: 18px !important;
    line-height: 16px;
  }

  .title-medium {
    font-size: 25px !important;
    line-height: 22px;
  }

  .title-large {
    font-size: 35px !important;
    line-height: 32px;
  }

  .title-extra-large {
    font-size: 55px !important;
    line-height: 55px;
  }

  h1 {
    /* font-size: 55px !important; */
    font-size: 55px;
    line-height: 58px;
  }

  h2 {
    /* font-size: 46px !important; */
    font-size: 46px;
    line-height: 52px;
  }

  h6 {
    /* font-size: 27px !important; */
    font-size: 27px;
    line-height: 33px;
  }

  h1:empty {
    min-height: 55px;
  }

  h2:empty {
    min-height: 46px;
  }

  h6:empty {
    min-height: 27px;
  }

  /* .sm-overlay-white-light:after {
    background: url(../images/overlay-2.png) repeat !important;
    opacity: 0.6 !important;
  } */

  .sm-bg-transparent {
    background-color: transparent !important;
  }
  .sm-bg-white {
    background-color: #fff !important;
  }
  .sm-bg-black {
    background-color: #000 !important;
  }
  .sm-bg-gray-extra-light {
    background-color: #f9f9f9 !important;
  }
  .sm-bg-gray-light {
    background-color: #c0bfbf !important;
  }
  .sm-bg-gray-regular {
    background-color: #9d9d9d !important;
  }
  .sm-bg-gray-dark {
    background-color: #444 !important;
  }
  .sm-bg-gray-extra-dark {
    background-color: #232323 !important;
  }
  .sm-bg-blue {
    background-color: #1769ff !important;
  }
  .sm-bg-red {
    background-color: #e31919 !important;
  }
  .sm-line-height-normal {
    line-height: normal !important;
  }
  .sm-no-line-height {
    line-height: 0 !important;
  }
  .sm-line-height-spaced {
    line-height: 1sm-5em !important;
  }
  .sm-line-height-regular {
    line-height: 1sm-1em !important;
  }
  .sm-line-height-medium {
    line-height: 1sm-3em !important;
  }
  .sm-line-height-10 {
    line-height: 10px !important;
  }
  .sm-line-height-15 {
    line-height: 15px !important;
  }
  .sm-line-height-16 {
    line-height: 16px !important;
  }
  .sm-line-height-17 {
    line-height: 17px !important;
  }
  .sm-line-height-18 {
    line-height: 18px !important;
  }
  .sm-line-height-19 {
    line-height: 19px !important;
  }
  .sm-line-height-20 {
    line-height: 20px !important;
  }
  .sm-line-height-25 {
    line-height: 25px !important;
  }
  .sm-line-height-26 {
    line-height: 26px !important;
  }
  .sm-line-height-27 {
    line-height: 27px !important;
  }
  .sm-line-height-28 {
    line-height: 28px !important;
  }
  .sm-line-height-29 {
    line-height: 29px !important;
  }
  .sm-line-height-30 {
    line-height: 30px !important;
  }
  .sm-line-height-35 {
    line-height: 35px !important;
  }
  .sm-line-height-40 {
    line-height: 40px !important;
  }
  .sm-line-height-45 {
    line-height: 45px !important;
  }
  .sm-line-height-48 {
    line-height: 48px !important;
  }
  .sm-line-height-50 {
    line-height: 50px !important;
  }
  .sm-line-height-55 {
    line-height: 55px !important;
  }
  .sm-line-height-60 {
    line-height: 60px !important;
  }
  .sm-line-height-65 {
    line-height: 65px !important;
  }
  .sm-line-height-70 {
    line-height: 70px !important;
  }
  .sm-line-height-75 {
    line-height: 75px !important;
  }
  .sm-line-height-80 {
    line-height: 80px !important;
  }
  .sm-line-height-85 {
    line-height: 85px !important;
  }
  .sm-line-height-90 {
    line-height: 90px !important;
  }
  .sm-line-height-95 {
    line-height: 95px !important;
  }
  .sm-line-height-100 {
    line-height: 100px !important;
  }
  .sm-line-height-110 {
    line-height: 110px !important;
  }
  .sm-line-height-120 {
    line-height: 120px !important;
  }
  .sm-line-height-175 {
    line-height: 175px !important;
  }
  .sm-line-height-180 {
    line-height: 180px !important;
  }
  .sm-line-height-170 {
    line-height: 170px !important;
  }
  .sm-line-height-160 {
    line-height: 160px !important;
  }
  .sm-line-height-150 {
    line-height: 150px !important;
  }
  .sm-line-height-200 {
    line-height: 200px !important;
  }
  .sm-text-underline {
    text-decoration: underline !important;
  }
  .sm-text-line-through {
    text-decoration: line-through !important;
  }
  .sm-text-transform-none {
    text-transform: none !important;
  }
  .sm-text-transform-unset {
    text-transform: unset !important;
  }
  .sm-text-regular {
    font-style: normal !important;
  }
  .sm-text-italic {
    font-style: italic !important;
  }
  .sm-no-letter-spacing {
    letter-spacing: 0 !important;
  }
  .sm-letter-spacing-1 {
    letter-spacing: 1px !important;
  }
  .sm-letter-spacing-2 {
    letter-spacing: 2px !important;
  }
  .sm-letter-spacing-3 {
    letter-spacing: 3px !important;
  }
  .sm-letter-spacing-4 {
    letter-spacing: 4px !important;
  }
  .sm-letter-spacing-5 {
    letter-spacing: 5px !important;
  }
  .sm-letter-spacing-6 {
    letter-spacing: 6px !important;
  }
  .sm-letter-spacing-7 {
    letter-spacing: 7px !important;
  }
  .sm-letter-spacing-8 {
    letter-spacing: 8px !important;
  }
  .sm-letter-spacing-9 {
    letter-spacing: 9px !important;
  }
  .sm-letter-spacing-10 {
    letter-spacing: 10px !important;
  }
  .sm-letter-spacing-11 {
    letter-spacing: 11px !important;
  }
  .sm-letter-spacing-12 {
    letter-spacing: 12px !important;
  }
  .sm-letter-spacing-13 {
    letter-spacing: 13px !important;
  }
  .sm-letter-spacing-14 {
    letter-spacing: 14px !important;
  }
  .sm-letter-spacing-15 {
    letter-spacing: 15px !important;
  }
  .sm-letter-spacing-16 {
    letter-spacing: 16px !important;
  }
  .sm-letter-spacing-17 {
    letter-spacing: 17px !important;
  }
  .sm-letter-spacing-18 {
    letter-spacing: 18px !important;
  }
  .sm-letter-spacing-19 {
    letter-spacing: 19px !important;
  }
  .sm-letter-spacing-20 {
    letter-spacing: 20px !important;
  }
  .sm-letter-spacing-50 {
    letter-spacing: 50px !important;
  }
  .sm-letter-spacing-minus-1 {
    letter-spacing: -1px !important;
  }
  .sm-letter-spacing-minus-2 {
    letter-spacing: -2px !important;
  }
  .sm-letter-spacing-minus-3 {
    letter-spacing: -3px !important;
  }
  .sm-letter-spacing-minus-4 {
    letter-spacing: -4px !important;
  }
  .sm-letter-spacing-minus-5 {
    letter-spacing: -5px !important;
  }
  .sm-letter-spacing-minus-6 {
    letter-spacing: -6px !important;
  }
  .sm-letter-spacing-minus-7 {
    letter-spacing: -7px !important;
  }
  .sm-letter-spacing-minus-8 {
    letter-spacing: -8px !important;
  }
  .sm-letter-spacing-minus-9 {
    letter-spacing: -9px !important;
  }
  .sm-letter-spacing-minus-10 {
    letter-spacing: -10px !important;
  }
  .sm-no-word-spacing {
    word-spacing: 0 !important;
  }
  .sm-word-spacing-1 {
    word-spacing: 1px !important;
  }
  .sm-word-spacing-2 {
    word-spacing: 2px !important;
  }
  .sm-word-spacing-3 {
    word-spacing: 3px !important;
  }
  .sm-word-spacing-4 {
    word-spacing: 4px !important;
  }
  .sm-word-spacing-5 {
    word-spacing: 5px !important;
  }
  .sm-word-spacing-6 {
    word-spacing: 6px !important;
  }
  .sm-word-spacing-7 {
    word-spacing: 7px !important;
  }
  .sm-word-spacing-8 {
    word-spacing: 8px !important;
  }
  .sm-word-spacing-9 {
    word-spacing: 9px !important;
  }
  .sm-word-spacing-10 {
    word-spacing: 10px !important;
  }
  .sm-word-spacing-minus-1 {
    word-spacing: -1px !important;
  }
  .sm-word-spacing-minus-2 {
    word-spacing: -2px !important;
  }
  .sm-word-spacing-minus-3 {
    word-spacing: -3px !important;
  }
  .sm-word-spacing-minus-4 {
    word-spacing: -4px !important;
  }
  .sm-word-spacing-minus-5 {
    word-spacing: -5px !important;
  }
  .sm-word-spacing-minus-6 {
    word-spacing: -6px !important;
  }
  .sm-word-spacing-minus-7 {
    word-spacing: -7px !important;
  }
  .sm-word-spacing-minus-8 {
    word-spacing: -8px !important;
  }
  .sm-word-spacing-minus-9 {
    word-spacing: -9px !important;
  }
  .sm-word-spacing-minus-10 {
    word-spacing: -10px !important;
  }
  .sm-text-extra-small {
    font-size: 11px !important;
    line-height: 14px !important;
  }
  .sm-text-small {
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .sm-text-medium {
    font-size: 16px !important;
    line-height: 23px !important;
  }
  .sm-text-large {
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .sm-text-extra-large {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .sm-title-extra-small {
    font-size: 35px !important;
    line-height: 38px !important;
  }
  .sm-title-small {
    font-size: 55px !important;
    line-height: 48px !important;
  }
  .sm-title-medium {
    font-size: 75px !important;
    line-height: 60px !important;
  }
  .sm-title-large {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .sm-title-extra-large {
    font-size: 130px !important;
    line-height: 138px !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-white {
    color: #fff !important;
  }
  .sm-text-black {
    color: #000 !important;
  }
  .sm-text-blue {
    color: #1769ff !important;
  }
  .sm-text-red {
    color: #e31919 !important;
  }
  .sm-text-gray-light {
    color: #c0bfbf !important;
  }
  .sm-text-gray-regular {
    color: #9d9d9d !important;
  }
  .sm-text-gray-dark {
    color: #444 !important;
  }
  .sm-text-gray-extra-dark {
    color: #232323 !important;
  }
  .sm-text-weight-100 {
    font-weight: 100 !important;
  }
  .sm-text-weight-200 {
    font-weight: 200 !important;
  }
  .sm-text-weight-300 {
    font-weight: 300 !important;
  }
  .sm-text-weight-400 {
    font-weight: 400 !important;
  }
  .sm-text-weight-500 {
    font-weight: 500 !important;
  }
  .sm-text-weight-600 {
    font-weight: 600 !important;
  }
  .sm-text-weight-700 {
    font-weight: 700 !important;
  }
  .sm-text-weight-800 {
    font-weight: 800 !important;
  }
  .sm-text-weight-900 {
    font-weight: 900 !important;
  }
  .sm-display-block {
    display: block !important;
  }
  .sm-display-inline-block {
    display: inline-block !important;
  }
  .sm-display-inline {
    display: inline !important;
  }
  .sm-display-none {
    display: none !important;
  }
  .sm-display-inherit {
    display: inherit !important;
  }
  .sm-display-table {
    display: table !important;
  }
  .sm-display-table-cell {
    display: table-cell !important;
  }
  .sm-overflow-hidden {
    overflow: hidden !important;
  }
  .sm-overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .sm-overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .sm-overflow-visible {
    overflow: visible !important;
  }
  .sm-overflow-auto {
    overflow: auto !important;
  }
  .sm-overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm-overflow-y-auto {
    overflow-y: auto !important;
  }
  .sm-overflow-scroll {
    overflow: scroll !important;
  }
  .sm-vertical-align-middle {
    vertical-align: middle !important;
  }
  .sm-vertical-align-top {
    vertical-align: top !important;
  }
  .sm-vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .sm-vertical-middle {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    position: absolute;
    top: 50% !important;
    left: 0;
    right: 0 !important;
  }
  .sm-middle-center {
    left: 50% !important;
    top: 50% !important;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%) !important;
  }
  .sm-center-col {
    float: none;
    margin-left: auto;
    margin-right: auto !important;
  }
  .sm-right-col {
    float: right;
    margin-left: auto;
    margin-right: auto !important;
  }
  .sm-left-col {
    float: left;
    margin-right: auto;
    margin-right: auto !important;
  }
  .sm-position-inherit {
    position: inherit !important;
  }
  .sm-position-relative {
    position: relative !important;
  }
  .sm-position-absolute {
    position: absolute !important;
  }
  .sm-position-fixed {
    position: fixed !important;
  }
  .sm-position-right {
    right: 0 !important;
  }
  .sm-position-left {
    left: 0 !important;
  }
  .sm-position-top {
    top: 0 !important;
  }
  .sm-top-1 {
    top: 1px !important;
  }
  .sm-top-2 {
    top: 2px !important;
  }
  .sm-top-3 {
    top: 2px !important;
  }
  .sm-top-4 {
    top: 2px !important;
  }
  .sm-top-5 {
    top: 2px !important;
  }
  .sm-top-6 {
    top: 2px !important;
  }
  .sm-top-7 {
    top: 2px !important;
  }
  .sm-top-8 {
    top: 2px !important;
  }
  .sm-top-9 {
    top: 2px !important;
  }
  .sm-top-10 {
    top: 2px !important;
  }
  .sm-bottom-1 {
    bottom: 1px !important;
  }
  .sm-bottom-2 {
    bottom: 2px !important;
  }
  .sm-bottom-3 {
    top: 3px !important;
  }
  .sm-bottom-4 {
    top: 4px !important;
  }
  .sm-bottom-5 {
    top: 5px !important;
  }
  .sm-bottom-6 {
    top: 6px !important;
  }
  .sm-bottom-7 {
    top: 7px !important;
  }
  .sm-bottom-8 {
    top: 8px !important;
  }
  .sm-bottom-9 {
    top: 9px !important;
  }
  .sm-bottom-10 {
    top: 10px !important;
  }
  .sm-float-left {
    float: left !important;
  }
  .sm-float-right {
    float: right !important;
  }
  .sm-float-none {
    float: none !important;
  }
  .sm-clear-both {
    clear: both !important;
  }
  .sm-z-index-9999 {
    z-index: 9999 !important;
  }
  .sm-z-index-999 {
    z-index: 999 !important;
  }
  .sm-z-index-99 {
    z-index: 99 !important;
  }
  .sm-z-index-6 {
    z-index: 6 !important;
  }
  .sm-z-index-5 {
    z-index: 5 !important;
  }
  .sm-z-index-4 {
    z-index: 4 !important;
  }
  .sm-z-index-3 {
    z-index: 3 !important;
  }
  .sm-z-index-2 {
    z-index: 2 !important;
  }
  .sm-z-index-1 {
    z-index: 1 !important;
  }
  .sm-z-index-0 {
    z-index: 0 !important;
  }
  .sm-z-index-minus1 {
    z-index: -1 !important;
  }
  .sm-z-index-minus2 {
    z-index: -2 !important;
  }
  .sm-opacity-1 {
    opacity: 0sm-1 !important;
  }
  .sm-opacity-2 {
    opacity: 0sm-2 !important;
  }
  .sm-opacity-3 {
    opacity: 0sm-3 !important;
  }
  .sm-opacity-4 {
    opacity: 0sm-4 !important;
  }
  .sm-opacity-5 {
    opacity: 0sm-5 !important;
  }
  .sm-opacity-6 {
    opacity: 0sm-6 !important;
  }
  .sm-opacity-7 {
    opacity: 0sm-7 !important;
  }
  .sm-opacity-8 {
    opacity: 0sm-8 !important;
  }
  .sm-opacity-9 {
    opacity: 0sm-9 !important;
  }
  .sm-opacity-visible {
    opacity: 1 !important;
  }
  .sm-hidden {
    display: none;
    opacity: 0 !important;
  }
  .sm-all-border {
    border: 1px solid !important;
  }
  .sm-top-border {
    border-top: 1px solid !important;
  }
  .sm-bottom-border {
    border-bottom: 1px solid !important;
  }
  .sm-left-border {
    border-left: 1px solid !important;
  }
  .sm-right-border {
    border-right: 1px solid !important;
  }
  .sm-border-left-right {
    border-left: 1px solid;
    border-right: 1px solid !important;
  }
  .sm-border-right-left {
    border-top: 1px solid;
    border-bottom: 1px solid !important;
  }
  .sm-no-border {
    border: 0 !important;
  }
  .sm-no-border-top {
    border-top: 0 !important;
  }
  .sm-no-border-bottom {
    border-bottom: 0 !important;
  }
  .sm-no-border-left {
    border-left: 0 !important;
  }
  .sm-no-border-right {
    border-right: 0 !important;
  }
  .sm-border-1px {
    border-width: 1px !important;
  }
  .sm-border-2px {
    border-width: 2px !important;
  }
  .sm-border-3px {
    border-width: 3px !important;
  }
  .sm-border-4px {
    border-width: 4px !important;
  }
  .sm-border-5px {
    border-width: 5px !important;
  }
  .sm-border-6px {
    border-width: 6px !important;
  }
  .sm-border-7px {
    border-width: 7px !important;
  }
  .sm-border-8px {
    border-width: 8px !important;
  }
  .sm-border-9px {
    border-width: 9px !important;
  }
  .sm-border-10px {
    border-width: 10px !important;
  }
  .sm-border-11px {
    border-width: 11px !important;
  }
  .sm-border-12px {
    border-width: 12px !important;
  }
  .sm-border-13px {
    border-width: 13px !important;
  }
  .sm-border-14px {
    border-width: 14px !important;
  }
  .sm-border-15px {
    border-width: 15px !important;
  }
  .sm-border-16px {
    border-width: 16px !important;
  }
  .sm-border-17px {
    border-width: 17px !important;
  }
  .sm-border-18px {
    border-width: 18px !important;
  }
  .sm-border-19px {
    border-width: 19px !important;
  }
  .sm-border-20px {
    border-width: 20px !important;
  }
  .sm-border-color-white {
    border-color: #fff !important;
  }
  .sm-border-color-black {
    border-color: #000 !important;
  }
  .sm-border-color-blue {
    border-color: #1769ff !important;
  }
  .sm-border-color-gray-extra-light {
    border-color: #e5e5e5 !important;
  }
  .sm-border-color-gray-light {
    border-color: #c0bfbf !important;
  }
  .sm-border-color-gray-regular {
    border-color: #9d9d9d !important;
  }
  .sm-border-color-gray-dark {
    border-color: #444 !important;
  }
  .sm-border-color-gray-extra-dark {
    border-color: #232323 !important;
  }
  .sm-border-black-light {
    border-color: rgba(0, 0, 0, .sm-1) !important;
  }
  .sm-border-white-light {
    border-color: rgba(255, 255, 255, .sm-1) !important;
  }
  .sm-border-radius-1 {
    border-radius: 1px !important;
  }
  .sm-border-radius-2 {
    border-radius: 2px !important;
  }
  .sm-border-radius-3 {
    border-radius: 3px !important;
  }
  .sm-border-radius-4 {
    border-radius: 4px !important;
  }
  .sm-border-radius-5 {
    border-radius: 5px !important;
  }
  .sm-border-radius-6 {
    border-radius: 6px !important;
  }
  .sm-border-radius-7 {
    border-radius: 7px !important;
  }
  .sm-border-radius-8 {
    border-radius: 8px !important;
  }
  .sm-border-radius-9 {
    border-radius: 9px !important;
  }
  .sm-border-radius-10 {
    border-radius: 11px !important;
  }
  .sm-border-radius-11 {
    border-radius: 11px !important;
  }
  .sm-border-radius-12 {
    border-radius: 12px !important;
  }
  .sm-border-radius-13 {
    border-radius: 13px !important;
  }
  .sm-border-radius-14 {
    border-radius: 14px !important;
  }
  .sm-border-radius-15 {
    border-radius: 15px !important;
  }
  .sm-border-radius-16 {
    border-radius: 16px !important;
  }
  .sm-border-radius-17 {
    border-radius: 17px !important;
  }
  .sm-border-radius-18 {
    border-radius: 18px !important;
  }
  .sm-border-radius-19 {
    border-radius: 19px !important;
  }
  .sm-border-radius-20 {
    border-radius: 20px !important;
  }
  .sm-border-radius-50 {
    border-radius: 50px !important;
  }
  .sm-border-radius-100 {
    border-radius: 100px !important;
  }
  .sm-border-radius-50-percent {
    border-radius: 50% !important;
  }
  .sm-border-radius-100-percent {
    border-radius: 100% !important;
  }
  .sm-border-radius-none {
    border-radius: 0 !important;
  }
  .sm-no-margin {
    margin: 0 !important;
  }
  .sm-no-margin-top {
    margin-top: 0 !important;
  }
  .sm-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .sm-no-margin-left {
    margin-left: 0 !important;
  }
  .sm-no-margin-right {
    margin-right: 0 !important;
  }
  .sm-no-margin-left-right {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sm-no-margin-top-bottom {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .sm-margin-auto {
    margin: 0 auto !important;
  }
  .sm-margin-left-right-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm-margin-left-auto {
    margin-left: auto !important;
  }
  .sm-margin-right-auto {
    margin-right: auto !important;
  }
  .sm-margin-one-percent {
    margin: 1% !important;
  }
  .sm-margin-two-percent {
    margin: 2% !important;
  }
  .sm-margin-three-percent {
    margin: 3% !important;
  }
  .sm-margin-four-percent {
    margin: 4% !important;
  }
  .sm-margin-five-percent {
    margin: 5% !important;
  }
  .sm-margin-six-percent {
    margin: 6% !important;
  }
  .sm-margin-seven-percent {
    margin: 7% !important;
  }
  .sm-margin-eight-percent {
    margin: 8% !important;
  }
  .sm-margin-nine-percent {
    margin: 9% !important;
  }
  .sm-margin-ten-percent {
    margin: 10% !important;
  }
  .sm-margin-eleven-percent {
    margin: 11% !important;
  }
  .sm-margin-twelve-percent {
    margin: 12% !important;
  }
  .sm-margin-thirteen-percent {
    margin: 13% !important;
  }
  .sm-margin-fourteen-percent {
    margin: 14% !important;
  }
  .sm-margin-fifteen-percent {
    margin: 15% !important;
  }
  .sm-margin-sixteen-percent {
    margin: 16% !important;
  }
  .sm-margin-seventeen-percent {
    margin: 17% !important;
  }
  .sm-margin-eighteen-percent {
    margin: 18% !important;
  }
  .sm-margin-nineteen-percent {
    margin: 19% !important;
  }
  .sm-margin-twenty-percent {
    margin: 20% !important;
  }
  .sm-margin-twenty-one-percent {
    margin: 21% !important;
  }
  .sm-margin-twenty-two-percent {
    margin: 22% !important;
  }
  .sm-margin-twenty-three-percent {
    margin: 23% !important;
  }
  .sm-margin-twenty-four-percent {
    margin: 24% !important;
  }
  .sm-margin-twenty-five-percent {
    margin: 25% !important;
  }
  .sm-margin-twenty-six-percent {
    margin: 26% !important;
  }
  .sm-margin-twenty-seven-percent {
    margin: 27% !important;
  }
  .sm-margin-twenty-eight-percent {
    margin: 28% !important;
  }
  .sm-margin-twenty-nine-percent {
    margin: 29% !important;
  }
  .sm-margin-thirty-percent {
    margin: 30% !important;
  }
  .sm-margin-thirty-one-percent {
    margin: 31% !important;
  }
  .sm-margin-thirty-two-percent {
    margin: 32% !important;
  }
  .sm-margin-thirty-three-percent {
    margin: 33% !important;
  }
  .sm-margin-thirty-four-percent {
    margin: 34% !important;
  }
  .sm-margin-thirty-five-percent {
    margin: 35% !important;
  }
  .sm-margin-thirty-six-percent {
    margin: 36% !important;
  }
  .sm-margin-thirty-seven-percent {
    margin: 37% !important;
  }
  .sm-margin-thirty-eight-percent {
    margin: 38% !important;
  }
  .sm-margin-thirty-nine-percent {
    margin: 39% !important;
  }
  .sm-margin-forty-percent {
    margin: 40% !important;
  }
  .sm-margin-forty-one-percent {
    margin: 41% !important;
  }
  .sm-margin-forty-two-percent {
    margin: 42% !important;
  }
  .sm-margin-forty-three-percent {
    margin: 43% !important;
  }
  .sm-margin-forty-four-percent {
    margin: 44% !important;
  }
  .sm-margin-forty-five-percent {
    margin: 45% !important;
  }
  .sm-margin-forty-six-percent {
    margin: 46% !important;
  }
  .sm-margin-forty-seven-percent {
    margin: 47% !important;
  }
  .sm-margin-forty-eight-percent {
    margin: 48% !important;
  }
  .sm-margin-forty-nine-percent {
    margin: 49% !important;
  }
  .sm-margin-fifty-percent {
    margin: 50% !important;
  }
  .sm-margin-5px {
    margin: 5px !important;
  }
  .sm-margin-10px {
    margin: 10px !important;
  }
  .sm-margin-15px {
    margin: 15px !important;
  }
  .sm-margin-20px {
    margin: 20px !important;
  }
  .sm-margin-25px {
    margin: 25px !important;
  }
  .sm-margin-30px {
    margin: 30px !important;
  }
  .sm-margin-35px {
    margin: 35px !important;
  }
  .sm-margin-40px {
    margin: 40px !important;
  }
  .sm-margin-45px {
    margin: 45px !important;
  }
  .sm-margin-50px {
    margin: 50px !important;
  }
  .sm-margin-55px {
    margin: 55px !important;
  }
  .sm-margin-60px {
    margin: 60px !important;
  }
  .sm-margin-65px {
    margin: 65px !important;
  }
  .sm-margin-70px {
    margin: 70px !important;
  }
  .sm-margin-75px {
    margin: 75px !important;
  }
  .sm-margin-80px {
    margin: 80px !important;
  }
  .sm-margin-85px {
    margin: 85px !important;
  }
  .sm-margin-90px {
    margin: 90px !important;
  }
  .sm-margin-95px {
    margin: 95px !important;
  }
  .sm-margin-100px {
    margin: 100px !important;
  }
  .sm-margin-120px {
    margin: 120px !important;
  }
  .sm-margin-150px {
    margin: 150px !important;
  }
  .sm-margin-top-one-percent {
    margin-top: 1% !important;
  }
  .sm-margin-top-two-percent {
    margin-top: 2% !important;
  }
  .sm-margin-top-three-percent {
    margin-top: 3% !important;
  }
  .sm-margin-top-four-percent {
    margin-top: 4% !important;
  }
  .sm-margin-top-five-percent {
    margin-top: 5% !important;
  }
  .sm-margin-top-six-percent {
    margin-top: 6% !important;
  }
  .sm-margin-top-seven-percent {
    margin-top: 7% !important;
  }
  .sm-margin-top-eight-percent {
    margin-top: 8% !important;
  }
  .sm-margin-top-nine-percent {
    margin-top: 9% !important;
  }
  .sm-margin-top-ten-percent {
    margin-top: 10% !important;
  }
  .sm-margin-top-eleven-percent {
    margin-top: 11% !important;
  }
  .sm-margin-top-twelve-percent {
    margin-top: 12% !important;
  }
  .sm-margin-top-thirteen-percent {
    margin-top: 13% !important;
  }
  .sm-margin-top-fourteen-percent {
    margin-top: 14% !important;
  }
  .sm-margin-top-fifteen-percent {
    margin-top: 15% !important;
  }
  .sm-margin-top-sixteen-percent {
    margin-top: 16% !important;
  }
  .sm-margin-top-seventeen-percent {
    margin-top: 17% !important;
  }
  .sm-margin-top-eighteen-percent {
    margin-top: 18% !important;
  }
  .sm-margin-top-nineteen-percent {
    margin-top: 19% !important;
  }
  .sm-margin-top-twenty-percent {
    margin-top: 20% !important;
  }
  .sm-margin-top-twenty-one-percent {
    margin-top: 21% !important;
  }
  .sm-margin-top-twenty-two-percent {
    margin-top: 22% !important;
  }
  .sm-margin-top-twenty-three-percent {
    margin-top: 23% !important;
  }
  .sm-margin-top-twenty-four-percent {
    margin-top: 24% !important;
  }
  .sm-margin-top-twenty-five-percent {
    margin-top: 25% !important;
  }
  .sm-margin-top-1px {
    margin-top: 1px !important;
  }
  .sm-margin-top-5px {
    margin-top: 5px !important;
  }
  .sm-margin-top-10px {
    margin-top: 10px !important;
  }
  .sm-margin-top-15px {
    margin-top: 15px !important;
  }
  .sm-margin-top-20px {
    margin-top: 20px !important;
  }
  .sm-margin-top-25px {
    margin-top: 25px !important;
  }
  .sm-margin-top-30px {
    margin-top: 30px !important;
  }
  .sm-margin-top-35px {
    margin-top: 35px !important;
  }
  .sm-margin-top-40px {
    margin-top: 40px !important;
  }
  .sm-margin-top-45px {
    margin-top: 45px !important;
  }
  .sm-margin-top-50px {
    margin-top: 50px !important;
  }
  .sm-margin-top-55px {
    margin-top: 55px !important;
  }
  .sm-margin-top-60px {
    margin-top: 60px !important;
  }
  .sm-margin-top-65px {
    margin-top: 65px !important;
  }
  .sm-margin-top-70px {
    margin-top: 70px !important;
  }
  .sm-margin-top-75px {
    margin-top: 75px !important;
  }
  .sm-margin-top-80px {
    margin-top: 80px !important;
  }
  .sm-margin-top-85px {
    margin-top: 85px !important;
  }
  .sm-margin-top-90px {
    margin-top: 90px !important;
  }
  .sm-margin-top-95px {
    margin-top: 95px !important;
  }
  .sm-margin-top-100px {
    margin-top: 100px !important;
  }
  .sm-margin-top-110px {
    margin-top: 110px !important;
  }
  .sm-margin-top-120px {
    margin-top: 120px !important;
  }
  .sm-margin-top-130px {
    margin-top: 130px !important;
  }
  .sm-margin-top-140px {
    margin-top: 140px !important;
  }
  .sm-margin-top-150px {
    margin-top: 150px !important;
  }
  .sm-margin-bottom-5px {
    margin-bottom: 5px !important;
  }
  .sm-margin-bottom-10px {
    margin-bottom: 10px !important;
  }
  .sm-margin-bottom-15px {
    margin-bottom: 15px !important;
  }
  .sm-margin-bottom-20px {
    margin-bottom: 20px !important;
  }
  .sm-margin-bottom-25px {
    margin-bottom: 25px !important;
  }
  .sm-margin-bottom-30px {
    margin-bottom: 30px !important;
  }
  .sm-margin-bottom-35px {
    margin-bottom: 35px !important;
  }
  .sm-margin-bottom-40px {
    margin-bottom: 40px !important;
  }
  .sm-margin-bottom-45px {
    margin-bottom: 45px !important;
  }
  .sm-margin-bottom-50px {
    margin-bottom: 50px !important;
  }
  .sm-margin-bottom-55px {
    margin-bottom: 55px !important;
  }
  .sm-margin-bottom-60px {
    margin-bottom: 60px !important;
  }
  .sm-margin-bottom-65px {
    margin-bottom: 65px !important;
  }
  .sm-margin-bottom-70px {
    margin-bottom: 70px !important;
  }
  .sm-margin-bottom-75px {
    margin-bottom: 75px !important;
  }
  .sm-margin-bottom-80px {
    margin-bottom: 80px !important;
  }
  .sm-margin-bottom-85px {
    margin-bottom: 85px !important;
  }
  .sm-margin-bottom-90px {
    margin-bottom: 90px !important;
  }
  .sm-margin-bottom-95px {
    margin-bottom: 95px !important;
  }
  .sm-margin-bottom-100px {
    margin-bottom: 100px !important;
  }
  .sm-margin-bottom-120px {
    margin-bottom: 120px !important;
  }
  .sm-margin-bottom-150px {
    margin-bottom: 150px !important;
  }
  .sm-margin-bottom-one-percent {
    margin-bottom: 1% !important;
  }
  .sm-margin-bottom-two-percent {
    margin-bottom: 2% !important;
  }
  .sm-margin-bottom-three-percent {
    margin-bottom: 3% !important;
  }
  .sm-margin-bottom-four-percent {
    margin-bottom: 4% !important;
  }
  .sm-margin-bottom-five-percent {
    margin-bottom: 5% !important;
  }
  .sm-margin-bottom-six-percent {
    margin-bottom: 6% !important;
  }
  .sm-margin-bottom-seven-percent {
    margin-bottom: 7% !important;
  }
  .sm-margin-bottom-eight-percent {
    margin-bottom: 8% !important;
  }
  .sm-margin-bottom-nine-percent {
    margin-bottom: 9% !important;
  }
  .sm-margin-bottom-ten-percent {
    margin-bottom: 10% !important;
  }
  .sm-margin-bottom-eleven-percent {
    margin-bottom: 11% !important;
  }
  .sm-margin-bottom-twelve-percent {
    margin-bottom: 12% !important;
  }
  .sm-margin-bottom-thirteen-percent {
    margin-bottom: 13% !important;
  }
  .sm-margin-bottom-fourteen-percent {
    margin-bottom: 14% !important;
  }
  .sm-margin-bottom-fifteen-percent {
    margin-bottom: 15% !important;
  }
  .sm-margin-bottom-sixteen-percent {
    margin-bottom: 16% !important;
  }
  .sm-margin-bottom-seventeen-percent {
    margin-bottom: 17% !important;
  }
  .sm-margin-bottom-eighteen-percent {
    margin-bottom: 18% !important;
  }
  .sm-margin-bottom-nineteen-percent {
    margin-bottom: 19% !important;
  }
  .sm-margin-bottom-twenty-percent {
    margin-bottom: 20% !important;
  }
  .sm-margin-bottom-twenty-one-percent {
    margin-bottom: 21% !important;
  }
  .sm-margin-bottom-twenty-two-percent {
    margin-bottom: 22% !important;
  }
  .sm-margin-bottom-twenty-three-percent {
    margin-bottom: 23% !important;
  }
  .sm-margin-bottom-twenty-four-percent {
    margin-bottom: 24% !important;
  }
  .sm-margin-bottom-twenty-five-percent {
    margin-bottom: 25% !important;
  }
  .sm-margin-right-one-percent {
    margin-right: 1% !important;
  }
  .sm-margin-right-two-percent {
    margin-right: 2% !important;
  }
  .sm-margin-right-three-percent {
    margin-right: 3% !important;
  }
  .sm-margin-right-four-percent {
    margin-right: 4% !important;
  }
  .sm-margin-right-five-percent {
    margin-right: 5% !important;
  }
  .sm-margin-right-six-percent {
    margin-right: 6% !important;
  }
  .sm-margin-right-seven-percent {
    margin-right: 7% !important;
  }
  .sm-margin-right-eight-percent {
    margin-right: 8% !important;
  }
  .sm-margin-right-nine-percent {
    margin-right: 9% !important;
  }
  .sm-margin-right-ten-percent {
    margin-right: 10% !important;
  }
  .sm-margin-right-eleven-percent {
    margin-right: 11% !important;
  }
  .sm-margin-right-twelve-percent {
    margin-right: 12% !important;
  }
  .sm-margin-right-thirteen-percent {
    margin-right: 13% !important;
  }
  .sm-margin-right-fourteen-percent {
    margin-right: 14% !important;
  }
  .sm-margin-right-fifteen-percent {
    margin-right: 15% !important;
  }
  .sm-margin-right-sixteen-percent {
    margin-right: 16% !important;
  }
  .sm-margin-right-seventeen-percent {
    margin-right: 17% !important;
  }
  .sm-margin-right-eighteen-percent {
    margin-right: 18% !important;
  }
  .sm-margin-right-nineteen-percent {
    margin-right: 19% !important;
  }
  .sm-margin-right-twenty-percent {
    margin-right: 20% !important;
  }
  .sm-margin-right-twenty-one-percent {
    margin-right: 21% !important;
  }
  .sm-margin-right-twenty-two-percent {
    margin-right: 22% !important;
  }
  .sm-margin-right-twenty-three-percent {
    margin-right: 23% !important;
  }
  .sm-margin-right-twenty-four-percent {
    margin-right: 24% !important;
  }
  .sm-margin-right-twenty-five-percent {
    margin-right: 25% !important;
  }
  .sm-margin-right-1px {
    margin-right: 2px !important;
  }
  .sm-margin-right-2px {
    margin-right: 2px !important;
  }
  .sm-margin-right-5px {
    margin-right: 5px !important;
  }
  .sm-margin-right-10px {
    margin-right: 10px !important;
  }
  .sm-margin-right-15px {
    margin-right: 15px !important;
  }
  .sm-margin-right-20px {
    margin-right: 20px !important;
  }
  .sm-margin-right-25px {
    margin-right: 25px !important;
  }
  .sm-margin-right-30px {
    margin-right: 30px !important;
  }
  .sm-margin-right-35px {
    margin-right: 35px !important;
  }
  .sm-margin-right-40px {
    margin-right: 40px !important;
  }
  .sm-margin-right-45px {
    margin-right: 45px !important;
  }
  .sm-margin-right-50px {
    margin-right: 50px !important;
  }
  .sm-margin-right-55px {
    margin-right: 55px !important;
  }
  .sm-margin-right-60px {
    margin-right: 60px !important;
  }
  .sm-margin-right-65px {
    margin-right: 65px !important;
  }
  .sm-margin-right-70px {
    margin-right: 70px !important;
  }
  .sm-margin-right-75px {
    margin-right: 75px !important;
  }
  .sm-margin-right-80px {
    margin-right: 80px !important;
  }
  .sm-margin-right-85px {
    margin-right: 85px !important;
  }
  .sm-margin-right-90px {
    margin-right: 90px !important;
  }
  .sm-margin-right-95px {
    margin-right: 95px !important;
  }
  .sm-margin-right-100px {
    margin-right: 100px !important;
  }
  .sm-margin-left-one-percent {
    margin-left: 1% !important;
  }
  .sm-margin-left-two-percent {
    margin-left: 2% !important;
  }
  .sm-margin-left-three-percent {
    margin-left: 3% !important;
  }
  .sm-margin-left-four-percent {
    margin-left: 4% !important;
  }
  .sm-margin-left-five-percent {
    margin-left: 5% !important;
  }
  .sm-margin-left-six-percent {
    margin-left: 6% !important;
  }
  .sm-margin-left-seven-percent {
    margin-left: 7% !important;
  }
  .sm-margin-left-eight-percent {
    margin-left: 8% !important;
  }
  .sm-margin-left-nine-percent {
    margin-left: 9% !important;
  }
  .sm-margin-left-ten-percent {
    margin-left: 10% !important;
  }
  .sm-margin-left-eleven-percent {
    margin-left: 11% !important;
  }
  .sm-margin-left-twelve-percent {
    margin-left: 12% !important;
  }
  .sm-margin-left-thirteen-percent {
    margin-left: 13% !important;
  }
  .sm-margin-left-fourteen-percent {
    margin-left: 14% !important;
  }
  .sm-margin-left-fifteen-percent {
    margin-left: 15% !important;
  }
  .sm-margin-left-sixteen-percent {
    margin-left: 16% !important;
  }
  .sm-margin-left-seventeen-percent {
    margin-left: 17% !important;
  }
  .sm-margin-left-eighteen-percent {
    margin-left: 18% !important;
  }
  .sm-margin-left-nineteen-percent {
    margin-left: 19% !important;
  }
  .sm-margin-left-twenty-percent {
    margin-left: 20% !important;
  }
  .sm-margin-left-twenty-one-percent {
    margin-left: 21% !important;
  }
  .sm-margin-left-twenty-two-percent {
    margin-left: 22% !important;
  }
  .sm-margin-left-twenty-three-percent {
    margin-left: 23% !important;
  }
  .sm-margin-left-twenty-four-percent {
    margin-left: 24% !important;
  }
  .sm-margin-left-twenty-five-percent {
    margin-left: 25% !important;
  }
  .sm-margin-left-5px {
    margin-left: 5px !important;
  }
  .sm-margin-left-10px {
    margin-left: 10px !important;
  }
  .sm-margin-left-15px {
    margin-left: 15px !important;
  }
  .sm-margin-left-20px {
    margin-left: 20px !important;
  }
  .sm-margin-left-25px {
    margin-left: 25px !important;
  }
  .sm-margin-left-30px {
    margin-left: 30px !important;
  }
  .sm-margin-left-35px {
    margin-left: 35px !important;
  }
  .sm-margin-left-40px {
    margin-left: 40px !important;
  }
  .sm-margin-left-45px {
    margin-left: 45px !important;
  }
  .sm-margin-left-50px {
    margin-left: 50px !important;
  }
  .sm-margin-left-55px {
    margin-left: 55px !important;
  }
  .sm-margin-left-60px {
    margin-left: 60px !important;
  }
  .sm-margin-left-65px {
    margin-left: 65px !important;
  }
  .sm-margin-left-70px {
    margin-left: 70px !important;
  }
  .sm-margin-left-75px {
    margin-left: 75px !important;
  }
  .sm-margin-left-80px {
    margin-left: 80px !important;
  }
  .sm-margin-left-85px {
    margin-left: 85px !important;
  }
  .sm-margin-left-90px {
    margin-left: 90px !important;
  }
  .sm-margin-left-95px {
    margin-left: 95px !important;
  }
  .sm-margin-left-100px {
    margin-left: 100px !important;
  }
  .sm-margin-left-150px {
    margin-left: 150px !important;
  }
  .sm-margin-top-bottom-one-percent {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .sm-margin-top-bottom-two-percent {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
  .sm-margin-top-bottom-three-percent {
    margin-top: 3% !important;
    margin-bottom: 3% !important;
  }
  .sm-margin-top-bottom-four-percent {
    margin-top: 4% !important;
    margin-bottom: 4% !important;
  }
  .sm-margin-top-bottom-five-percent {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
  }
  .sm-margin-top-bottom-six-percent {
    margin-top: 6% !important;
    margin-bottom: 6% !important;
  }
  .sm-margin-top-bottom-seven-percent {
    margin-top: 7% !important;
    margin-bottom: 7% !important;
  }
  .sm-margin-top-bottom-eight-percent {
    margin-top: 8% !important;
    margin-bottom: 8% !important;
  }
  .sm-margin-top-bottom-nine-percent {
    margin-top: 9% !important;
    margin-bottom: 9% !important;
  }
  .sm-margin-top-bottom-ten-percent {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .sm-margin-top-bottom-eleven-percent {
    margin-top: 11% !important;
    margin-bottom: 11% !important;
  }
  .sm-margin-top-bottom-twelve-percent {
    margin-top: 12% !important;
    margin-bottom: 12% !important;
  }
  .sm-margin-top-bottom-thirteen-percent {
    margin-top: 13% !important;
    margin-bottom: 13% !important;
  }
  .sm-margin-top-bottom-fourteen-percent {
    margin-top: 14% !important;
    margin-bottom: 14% !important;
  }
  .sm-margin-top-bottom-fifteen-percent {
    margin-top: 15% !important;
    margin-bottom: 15% !important;
  }
  .sm-margin-top-bottom-sixteen-percent {
    margin-top: 16% !important;
    margin-bottom: 16% !important;
  }
  .sm-margin-top-bottom-seventeen-percent {
    margin-top: 17% !important;
    margin-bottom: 17% !important;
  }
  .sm-margin-top-bottom-eighteen-percent {
    margin-top: 18% !important;
    margin-bottom: 18% !important;
  }
  .sm-margin-top-bottom-nineteen-percent {
    margin-top: 19% !important;
    margin-bottom: 19% !important;
  }
  .sm-margin-top-bottom-twenty-percent {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .sm-margin-top-bottom-twenty-one-percent {
    margin-top: 21% !important;
    margin-bottom: 21% !important;
  }
  .sm-margin-top-bottom-twenty-two-percent {
    margin-top: 22% !important;
    margin-bottom: 22% !important;
  }
  .sm-margin-top-bottom-twenty-three-percent {
    margin-top: 23% !important;
    margin-bottom: 23% !important;
  }
  .sm-margin-top-bottom-twenty-four-percent {
    margin-top: 24% !important;
    margin-bottom: 24% !important;
  }
  .sm-margin-top-bottom-twenty-five-percent {
    margin-top: 25% !important;
    margin-bottom: 25% !important;
  }
  .sm-margin-top-bottom-5px {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .sm-margin-top-bottom-10px {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sm-margin-top-bottom-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .sm-margin-top-bottom-20px {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sm-margin-top-bottom-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .sm-margin-top-bottom-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .sm-margin-top-bottom-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .sm-margin-top-bottom-40px {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sm-margin-top-bottom-45px {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .sm-margin-top-bottom-50px {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .sm-margin-top-bottom-55px {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .sm-margin-top-bottom-60px {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .sm-margin-top-bottom-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .sm-margin-top-bottom-70px {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .sm-margin-top-bottom-75px {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .sm-margin-top-bottom-80px {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .sm-margin-top-bottom-85px {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .sm-margin-top-bottom-90px {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .sm-margin-top-bottom-95px {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .sm-margin-top-bottom-100px {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .sm-margin-top-bottom-150px {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .sm-no-padding {
    padding: 0 !important;
  }
  .sm-no-padding-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .sm-no-padding-top-bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .sm-no-padding-top {
    padding-top: 0 !important;
  }
  .sm-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .sm-no-padding-left {
    padding-left: 0 !important;
  }
  .sm-no-padding-right {
    padding-right: 0 !important;
  }
  .sm-padding-one-percent {
    padding: 1% !important;
  }
  .sm-padding-two-percent {
    padding: 2% !important;
  }
  .sm-padding-three-percent {
    padding: 3% !important;
  }
  .sm-padding-four-percent {
    padding: 4% !important;
  }
  .sm-padding-five-percent {
    padding: 5% !important;
  }
  .sm-padding-six-percent {
    padding: 6% !important;
  }
  .sm-padding-seven-percent {
    padding: 7% !important;
  }
  .sm-padding-eight-percent {
    padding: 8% !important;
  }
  .sm-padding-nine-percent {
    padding: 9% !important;
  }
  .sm-padding-ten-percent {
    padding: 10% !important;
  }
  .sm-padding-eleven-percent {
    padding: 11% !important;
  }
  .sm-padding-twelve-percent {
    padding: 12% !important;
  }
  .sm-padding-thirteen-percent {
    padding: 13% !important;
  }
  .sm-padding-fourteen-percent {
    padding: 14% !important;
  }
  .sm-padding-fifteen-percent {
    padding: 15% !important;
  }
  .sm-padding-sixteen-percent {
    padding: 16% !important;
  }
  .sm-padding-seventeen-percent {
    padding: 17% !important;
  }
  .sm-padding-eighteen-percent {
    padding: 18% !important;
  }
  .sm-padding-nineteen-percent {
    padding: 19% !important;
  }
  .sm-padding-twenty-percent {
    padding: 20% !important;
  }
  .sm-padding-twenty-one-percent {
    padding: 21% !important;
  }
  .sm-padding-twenty-two-percent {
    padding: 22% !important;
  }
  .sm-padding-twenty-three-percent {
    padding: 23% !important;
  }
  .sm-padding-twenty-four-percent {
    padding: 24% !important;
  }
  .sm-padding-twenty-five-percent {
    padding: 25% !important;
  }
  .sm-padding-5px {
    padding: 5px !important;
  }
  .sm-padding-10px {
    padding: 10px !important;
  }
  .sm-padding-15px {
    padding: 15px !important;
  }
  .sm-padding-20px {
    padding: 20px !important;
  }
  .sm-padding-25px {
    padding: 25px !important;
  }
  .sm-padding-30px {
    padding: 30px !important;
  }
  .sm-padding-35px {
    padding: 35px !important;
  }
  .sm-padding-40px {
    padding: 40px !important;
  }
  .sm-padding-45px {
    padding: 45px !important;
  }
  .sm-padding-50px {
    padding: 50px !important;
  }
  .sm-padding-55px {
    padding: 55px !important;
  }
  .sm-padding-60px {
    padding: 60px !important;
  }
  .sm-padding-65px {
    padding: 65px !important;
  }
  .sm-padding-70px {
    padding: 70px !important;
  }
  .sm-padding-75px {
    padding: 75px !important;
  }
  .sm-padding-80px {
    padding: 80px !important;
  }
  .sm-padding-85px {
    padding: 85px !important;
  }
  .sm-padding-90px {
    padding: 90px !important;
  }
  .sm-padding-95px {
    padding: 95px !important;
  }
  .sm-padding-100px {
    padding: 100px !important;
  }
  .sm-padding-150px {
    padding: 150px !important;
  }
  .sm-padding-top-one-percent {
    padding-top: 1% !important;
  }
  .sm-padding-top-two-percent {
    padding-top: 2% !important;
  }
  .sm-padding-top-three-percent {
    padding-top: 3% !important;
  }
  .sm-padding-top-four-percent {
    padding-top: 4% !important;
  }
  .sm-padding-top-five-percent {
    padding-top: 5% !important;
  }
  .sm-padding-top-six-percent {
    padding-top: 6% !important;
  }
  .sm-padding-top-seven-percent {
    padding-top: 7% !important;
  }
  .sm-padding-top-eight-percent {
    padding-top: 8% !important;
  }
  .sm-padding-top-nine-percent {
    padding-top: 9% !important;
  }
  .sm-padding-top-ten-percent {
    padding-top: 10% !important;
  }
  .sm-padding-top-eleven-percent {
    padding-top: 11% !important;
  }
  .sm-padding-top-twelve-percent {
    padding-top: 12% !important;
  }
  .sm-padding-top-thirteen-percent {
    padding-top: 13% !important;
  }
  .sm-padding-top-fourteen-percent {
    padding-top: 14% !important;
  }
  .sm-padding-top-fifteen-percent {
    padding-top: 15% !important;
  }
  .sm-padding-top-sixteen-percent {
    padding-top: 16% !important;
  }
  .sm-padding-top-seventeen-percent {
    padding-top: 17% !important;
  }
  .sm-padding-top-eighteen-percent {
    padding-top: 18% !important;
  }
  .sm-padding-top-nineteen-percent {
    padding-top: 19% !important;
  }
  .sm-padding-top-twenty-percent {
    padding-top: 20% !important;
  }
  .sm-padding-top-twenty-one-percent {
    padding-top: 21% !important;
  }
  .sm-padding-top-twenty-two-percent {
    padding-top: 22% !important;
  }
  .sm-padding-top-twenty-three-percent {
    padding-top: 23% !important;
  }
  .sm-padding-top-twenty-four-percent {
    padding-top: 24% !important;
  }
  .sm-padding-top-twenty-five-percent {
    padding-top: 25% !important;
  }
  .sm-padding-top-1px {
    padding-top: 1px !important;
  }
  .sm-padding-top-2px {
    padding-top: 2px !important;
  }
  .sm-padding-top-5px {
    padding-top: 5px !important;
  }
  .sm-padding-top-10px {
    padding-top: 10px !important;
  }
  .sm-padding-top-15px {
    padding-top: 15px !important;
  }
  .sm-padding-top-20px {
    padding-top: 20px !important;
  }
  .sm-padding-top-25px {
    padding-top: 25px !important;
  }
  .sm-padding-top-30px {
    padding-top: 30px !important;
  }
  .sm-padding-top-35px {
    padding-top: 35px !important;
  }
  .sm-padding-top-40px {
    padding-top: 40px !important;
  }
  .sm-padding-top-45px {
    padding-top: 45px !important;
  }
  .sm-padding-top-50px {
    padding-top: 50px !important;
  }
  .sm-padding-top-55px {
    padding-top: 55px !important;
  }
  .sm-padding-top-60px {
    padding-top: 60px !important;
  }
  .sm-padding-top-65px {
    padding-top: 65px !important;
  }
  .sm-padding-top-70px {
    padding-top: 70px !important;
  }
  .sm-padding-top-75px {
    padding-top: 75px !important;
  }
  .sm-padding-top-80px {
    padding-top: 80px !important;
  }
  .sm-padding-top-85px {
    padding-top: 85px !important;
  }
  .sm-padding-top-90px {
    padding-top: 90px !important;
  }
  .sm-padding-top-95px {
    padding-top: 95px !important;
  }
  .sm-padding-top-100px {
    padding-top: 100px !important;
  }
  .sm-padding-top-120px {
    padding-top: 120px !important;
  }
  .sm-padding-top-150px {
    padding-top: 150px !important;
  }
  .sm-padding-bottom-one-percent {
    padding-bottom: 1% !important;
  }
  .sm-padding-bottom-two-percent {
    padding-bottom: 2% !important;
  }
  .sm-padding-bottom-three-percent {
    padding-bottom: 3% !important;
  }
  .sm-padding-bottom-four-percent {
    padding-bottom: 4% !important;
  }
  .sm-padding-bottom-five-percent {
    padding-bottom: 5% !important;
  }
  .sm-padding-bottom-six-percent {
    padding-bottom: 6% !important;
  }
  .sm-padding-bottom-seven-percent {
    padding-bottom: 7% !important;
  }
  .sm-padding-bottom-eight-percent {
    padding-bottom: 8% !important;
  }
  .sm-padding-bottom-nine-percent {
    padding-bottom: 9% !important;
  }
  .sm-padding-bottom-ten-percent {
    padding-bottom: 10% !important;
  }
  .sm-padding-bottom-eleven-percent {
    padding-bottom: 11% !important;
  }
  .sm-padding-bottom-twelve-percent {
    padding-bottom: 12% !important;
  }
  .sm-padding-bottom-thirteen-percent {
    padding-bottom: 13% !important;
  }
  .sm-padding-bottom-fourteen-percent {
    padding-bottom: 14% !important;
  }
  .sm-padding-bottom-fifteen-percent {
    padding-bottom: 15% !important;
  }
  .sm-padding-bottom-sixteen-percent {
    padding-bottom: 16% !important;
  }
  .sm-padding-bottom-seventeen-percent {
    padding-bottom: 17% !important;
  }
  .sm-padding-bottom-eighteen-percent {
    padding-bottom: 18% !important;
  }
  .sm-padding-bottom-nineteen-percent {
    padding-bottom: 19% !important;
  }
  .sm-padding-bottom-twenty-percent {
    padding-bottom: 20% !important;
  }
  .sm-padding-bottom-twenty-one-percent {
    padding-bottom: 21% !important;
  }
  .sm-padding-bottom-twenty-two-percent {
    padding-bottom: 22% !important;
  }
  .sm-padding-bottom-twenty-three-percent {
    padding-bottom: 23% !important;
  }
  .sm-padding-bottom-twenty-four-percent {
    padding-bottom: 24% !important;
  }
  .sm-padding-bottom-twenty-five-percent {
    padding-bottom: 25% !important;
  }
  .sm-padding-bottom-5px {
    padding-bottom: 5px !important;
  }
  .sm-padding-bottom-10px {
    padding-bottom: 10px !important;
  }
  .sm-padding-bottom-15px {
    padding-bottom: 15px !important;
  }
  .sm-padding-bottom-20px {
    padding-bottom: 20px !important;
  }
  .sm-padding-bottom-25px {
    padding-bottom: 25px !important;
  }
  .sm-padding-bottom-30px {
    padding-bottom: 30px !important;
  }
  .sm-padding-bottom-35px {
    padding-bottom: 35px !important;
  }
  .sm-padding-bottom-40px {
    padding-bottom: 40px !important;
  }
  .sm-padding-bottom-45px {
    padding-bottom: 45px !important;
  }
  .sm-padding-bottom-50px {
    padding-bottom: 50px !important;
  }
  .sm-padding-bottom-55px {
    padding-bottom: 55px !important;
  }
  .sm-padding-bottom-60px {
    padding-bottom: 60px !important;
  }
  .sm-padding-bottom-65px {
    padding-bottom: 65px !important;
  }
  .sm-padding-bottom-70px {
    padding-bottom: 70px !important;
  }
  .sm-padding-bottom-75px {
    padding-bottom: 75px !important;
  }
  .sm-padding-bottom-80px {
    padding-bottom: 80px !important;
  }
  .sm-padding-bottom-85px {
    padding-bottom: 85px !important;
  }
  .sm-padding-bottom-90px {
    padding-bottom: 90px !important;
  }
  .sm-padding-bottom-95px {
    padding-bottom: 95px !important;
  }
  .sm-padding-bottom-100px {
    padding-bottom: 100px !important;
  }
  .sm-padding-bottom-120px {
    padding-bottom: 120px !important;
  }
  .sm-padding-bottom-150px {
    padding-bottom: 150px !important;
  }
  .sm-padding-bottom-200px {
    padding-bottom: 200px !important;
  }
  .sm-padding-bottom-250px {
    padding-bottom: 250px !important;
  }
  .sm-padding-right-one-percent {
    padding-right: 1% !important;
  }
  .sm-padding-right-two-percent {
    padding-right: 2% !important;
  }
  .sm-padding-right-three-percent {
    padding-right: 3% !important;
  }
  .sm-padding-right-four-percent {
    padding-right: 4% !important;
  }
  .sm-padding-right-five-percent {
    padding-right: 5% !important;
  }
  .sm-padding-right-six-percent {
    padding-right: 6% !important;
  }
  .sm-padding-right-seven-percent {
    padding-right: 7% !important;
  }
  .sm-padding-right-eight-percent {
    padding-right: 8% !important;
  }
  .sm-padding-right-nine-percent {
    padding-right: 9% !important;
  }
  .sm-padding-right-ten-percent {
    padding-right: 10% !important;
  }
  .sm-padding-right-eleven-percent {
    padding-right: 11% !important;
  }
  .sm-padding-right-twelve-percent {
    padding-right: 12% !important;
  }
  .sm-padding-right-thirteen-percent {
    padding-right: 13% !important;
  }
  .sm-padding-right-fourteen-percent {
    padding-right: 14% !important;
  }
  .sm-padding-right-fifteen-percent {
    padding-right: 15% !important;
  }
  .sm-padding-right-sixteen-percent {
    padding-right: 16% !important;
  }
  .sm-padding-right-seventeen-percent {
    padding-right: 17% !important;
  }
  .sm-padding-right-eighteen-percent {
    padding-right: 18% !important;
  }
  .sm-padding-right-nineteen-percent {
    padding-right: 19% !important;
  }
  .sm-padding-right-twenty-percent {
    padding-right: 20% !important;
  }
  .sm-padding-right-twenty-one-percent {
    padding-right: 21% !important;
  }
  .sm-padding-right-twenty-two-percent {
    padding-right: 22% !important;
  }
  .sm-padding-right-twenty-three-percent {
    padding-right: 23% !important;
  }
  .sm-padding-right-twenty-four-percent {
    padding-right: 24% !important;
  }
  .sm-padding-right-twenty-five-percent {
    padding-right: 25% !important;
  }
  .sm-padding-right-5px {
    padding-right: 5px !important;
  }
  .sm-padding-right-10px {
    padding-right: 10px !important;
  }
  .sm-padding-right-15px {
    padding-right: 15px !important;
  }
  .sm-padding-right-20px {
    padding-right: 20px !important;
  }
  .sm-padding-right-25px {
    padding-right: 25px !important;
  }
  .sm-padding-right-30px {
    padding-right: 30px !important;
  }
  .sm-padding-right-35px {
    padding-right: 35px !important;
  }
  .sm-padding-right-40px {
    padding-right: 40px !important;
  }
  .sm-padding-right-45px {
    padding-right: 45px !important;
  }
  .sm-padding-right-50px {
    padding-right: 50px !important;
  }
  .sm-padding-right-55px {
    padding-right: 55px !important;
  }
  .sm-padding-right-60px {
    padding-right: 60px !important;
  }
  .sm-padding-right-65px {
    padding-right: 65px !important;
  }
  .sm-padding-right-70px {
    padding-right: 70px !important;
  }
  .sm-padding-right-75px {
    padding-right: 75px !important;
  }
  .sm-padding-right-80px {
    padding-right: 80px !important;
  }
  .sm-padding-right-85px {
    padding-right: 85px !important;
  }
  .sm-padding-right-90px {
    padding-right: 90px !important;
  }
  .sm-padding-right-95px {
    padding-right: 95px !important;
  }
  .sm-padding-right-100px {
    padding-right: 100px !important;
  }
  .sm-padding-right-150px {
    padding-right: 150px !important;
  }
  .sm-padding-left-one-percent {
    padding-left: 1% !important;
  }
  .sm-padding-left-two-percent {
    padding-left: 2% !important;
  }
  .sm-padding-left-three-percent {
    padding-left: 3% !important;
  }
  .sm-padding-left-four-percent {
    padding-left: 4% !important;
  }
  .sm-padding-left-five-percent {
    padding-left: 5% !important;
  }
  .sm-padding-left-six-percent {
    padding-left: 6% !important;
  }
  .sm-padding-left-seven-percent {
    padding-left: 7% !important;
  }
  .sm-padding-left-eight-percent {
    padding-left: 8% !important;
  }
  .sm-padding-left-nine-percent {
    padding-left: 9% !important;
  }
  .sm-padding-left-ten-percent {
    padding-left: 10% !important;
  }
  .sm-padding-left-eleven-percent {
    padding-left: 11% !important;
  }
  .sm-padding-left-twelve-percent {
    padding-left: 12% !important;
  }
  .sm-padding-left-thirteen-percent {
    padding-left: 13% !important;
  }
  .sm-padding-left-fourteen-percent {
    padding-left: 14% !important;
  }
  .sm-padding-left-fifteen-percent {
    padding-left: 15% !important;
  }
  .sm-padding-left-sixteen-percent {
    padding-left: 16% !important;
  }
  .sm-padding-left-seventeen-percent {
    padding-left: 17% !important;
  }
  .sm-padding-left-eighteen-percent {
    padding-left: 18% !important;
  }
  .sm-padding-left-nineteen-percent {
    padding-left: 19% !important;
  }
  .sm-padding-left-twenty-percent {
    padding-left: 20% !important;
  }
  .sm-padding-left-twenty-one-percent {
    padding-left: 21% !important;
  }
  .sm-padding-left-twenty-two-percent {
    padding-left: 22% !important;
  }
  .sm-padding-left-twenty-three-percent {
    padding-left: 23% !important;
  }
  .sm-padding-left-twenty-four-percent {
    padding-left: 24% !important;
  }
  .sm-padding-left-twenty-five-percent {
    padding-left: 25% !important;
  }
  .sm-padding-left-5px {
    padding-left: 5px !important;
  }
  .sm-padding-left-10px {
    padding-left: 10px !important;
  }
  .sm-padding-left-15px {
    padding-left: 15px !important;
  }
  .sm-padding-left-20px {
    padding-left: 20px !important;
  }
  .sm-padding-left-25px {
    padding-left: 25px !important;
  }
  .sm-padding-left-30px {
    padding-left: 30px !important;
  }
  .sm-padding-left-35px {
    padding-left: 35px !important;
  }
  .sm-padding-left-40px {
    padding-left: 40px !important;
  }
  .sm-padding-left-45px {
    padding-left: 45px !important;
  }
  .sm-padding-left-50px {
    padding-left: 50px !important;
  }
  .sm-padding-left-55px {
    padding-left: 55px !important;
  }
  .sm-padding-left-60px {
    padding-left: 60px !important;
  }
  .sm-padding-left-65px {
    padding-left: 65px !important;
  }
  .sm-padding-left-70px {
    padding-left: 70px !important;
  }
  .sm-padding-left-75px {
    padding-left: 75px !important;
  }
  .sm-padding-left-80px {
    padding-left: 80px !important;
  }
  .sm-padding-left-85px {
    padding-left: 85px !important;
  }
  .sm-padding-left-90px {
    padding-left: 90px !important;
  }
  .sm-padding-left-95px {
    padding-left: 95px !important;
  }
  .sm-padding-left-100px {
    padding-left: 100px !important;
  }
  .sm-padding-left-150px {
    padding-left: 150px !important;
  }
  .sm-padding-top-bottom-one-percent {
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
  .sm-padding-top-bottom-two-percent {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }
  .sm-padding-top-bottom-three-percent {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
  }
  .sm-padding-top-bottom-four-percent {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
  }
  .sm-padding-top-bottom-five-percent {
    padding-top: 5% !important;
    padding-bottom: 5% !important;
  }
  .sm-padding-top-bottom-six-percent {
    padding-top: 6% !important;
    padding-bottom: 6% !important;
  }
  .sm-padding-top-bottom-seven-percent {
    padding-top: 7% !important;
    padding-bottom: 7% !important;
  }
  .sm-padding-top-bottom-eight-percent {
    padding-top: 8% !important;
    padding-bottom: 8% !important;
  }
  .sm-padding-top-bottom-nine-percent {
    padding-top: 9% !important;
    padding-bottom: 9% !important;
  }
  .sm-padding-top-bottom-ten-percent {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .sm-padding-top-bottom-eleven-percent {
    padding-top: 11% !important;
    padding-bottom: 11% !important;
  }
  .sm-padding-top-bottom-twelve-percent {
    padding-top: 12% !important;
    padding-bottom: 12% !important;
  }
  .sm-padding-top-bottom-thirteen-percent {
    padding-top: 13% !important;
    padding-bottom: 13% !important;
  }
  .sm-padding-top-bottom-fourteen-percent {
    padding-top: 14% !important;
    padding-bottom: 14% !important;
  }
  .sm-padding-top-bottom-fifteen-percent {
    padding-top: 15% !important;
    padding-bottom: 15% !important;
  }
  .sm-padding-top-bottom-sixteen-percent {
    padding-top: 16% !important;
    padding-bottom: 16% !important;
  }
  .sm-padding-top-bottom-seventeen-percent {
    padding-top: 17% !important;
    padding-bottom: 17% !important;
  }
  .sm-padding-top-bottom-eighteen-percent {
    padding-top: 18% !important;
    padding-bottom: 18% !important;
  }
  .sm-padding-top-bottom-nineteen-percent {
    padding-top: 19% !important;
    padding-bottom: 19% !important;
  }
  .sm-padding-top-bottom-twenty-percent {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .sm-padding-top-bottom-twenty-one-percent {
    padding-top: 21% !important;
    padding-bottom: 21% !important;
  }
  .sm-padding-top-bottom-twenty-two-percent {
    padding-top: 22% !important;
    padding-bottom: 22% !important;
  }
  .sm-padding-top-bottom-twenty-three-percent {
    padding-top: 23% !important;
    padding-bottom: 23% !important;
  }
  .sm-padding-top-bottom-twenty-four-percent {
    padding-top: 24% !important;
    padding-bottom: 24% !important;
  }
  .sm-padding-top-bottom-twenty-five-percent {
    padding-top: 25% !important;
    padding-bottom: 25% !important;
  }
  .sm-padding-top-bottom-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .sm-padding-top-bottom-10px {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sm-padding-top-bottom-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .sm-padding-top-bottom-20px {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sm-padding-top-bottom-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .sm-padding-top-bottom-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .sm-padding-top-bottom-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .sm-padding-top-bottom-40px {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sm-padding-top-bottom-45px {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .sm-padding-top-bottom-50px {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sm-padding-top-bottom-55px {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .sm-padding-top-bottom-60px {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sm-padding-top-bottom-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .sm-padding-top-bottom-70px {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .sm-padding-top-bottom-75px {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .sm-padding-top-bottom-80px {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .sm-padding-top-bottom-85px {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .sm-padding-top-bottom-90px {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .sm-padding-top-bottom-95px {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .sm-padding-top-bottom-100px {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .sm-padding-top-bottom-120px {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .sm-padding-top-bottom-150px {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .sm-padding-top-bottom-200px {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .sm-padding-top-bottom-250px {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .sm-padding-top-bottom-300px {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .sm-padding-left-right-one-percent {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
  .sm-padding-left-right-two-percent {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .sm-padding-left-right-three-percent {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
  .sm-padding-left-right-four-percent {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
  .sm-padding-left-right-five-percent {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .sm-padding-left-right-six-percent {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .sm-padding-left-right-seven-percent {
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
  .sm-padding-left-right-eight-percent {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  .sm-padding-left-right-nine-percent {
    padding-left: 9% !important;
    padding-right: 9% !important;
  }
  .sm-padding-left-right-ten-percent {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .sm-padding-left-right-eleven-percent {
    padding-left: 11% !important;
    padding-right: 11% !important;
  }
  .sm-padding-left-right-twelve-percent {
    padding-left: 12% !important;
    padding-right: 12% !important;
  }
  .sm-padding-left-right-thirteen-percent {
    padding-left: 13% !important;
    padding-right: 13% !important;
  }
  .sm-padding-left-right-fourteen-percent {
    padding-left: 14% !important;
    padding-right: 14% !important;
  }
  .sm-padding-left-right-fifteen-percent {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .sm-padding-left-right-sixteen-percent {
    padding-left: 16% !important;
    padding-right: 16% !important;
  }
  .sm-padding-left-right-seventeen-percent {
    padding-left: 17% !important;
    padding-right: 17% !important;
  }
  .sm-padding-left-right-eighteen-percent {
    padding-left: 18% !important;
    padding-right: 18% !important;
  }
  .sm-padding-left-right-nineteen-percent {
    padding-left: 19% !important;
    padding-right: 19% !important;
  }
  .sm-padding-left-right-twenty-percent {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .sm-padding-left-right-twenty-one-percent {
    padding-left: 21% !important;
    padding-right: 21% !important;
  }
  .sm-padding-left-right-twenty-two-percent {
    padding-left: 22% !important;
    padding-right: 22% !important;
  }
  .sm-padding-left-right-twenty-three-percent {
    padding-left: 23% !important;
    padding-right: 23% !important;
  }
  .sm-padding-left-right-twenty-four-percent {
    padding-left: 24% !important;
    padding-right: 24% !important;
  }
  .sm-padding-left-right-twenty-five-percent {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }
  .sm-padding-left-right-5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .sm-padding-left-right-10px {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sm-padding-left-right-15px {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .sm-padding-left-right-20px {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sm-padding-left-right-25px {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .sm-padding-left-right-30px {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sm-padding-left-right-35px {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .sm-padding-left-right-40px {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sm-padding-left-right-45px {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .sm-padding-left-right-50px {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .sm-padding-left-right-55px {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .sm-padding-left-right-60px {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sm-padding-left-right-65px {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .sm-padding-left-right-70px {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .sm-padding-left-right-75px {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .sm-padding-left-right-80px {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .sm-padding-left-right-85px {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .sm-padding-left-right-90px {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .sm-padding-left-right-95px {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .sm-padding-left-right-100px {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .sm-padding-left-right-120px {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .sm-padding-left-right-150px {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .sm-width-1px {
    width: 1px !important;
  }
  .sm-width-2px {
    width: 2px !important;
  }
  .sm-width-3px {
    width: 3px !important;
  }
  .sm-width-4px {
    width: 4px !important;
  }
  .sm-width-5px {
    width: 5px !important;
  }
  .sm-width-6px {
    width: 6px !important;
  }
  .sm-width-7px {
    width: 7px !important;
  }
  .sm-width-8px {
    width: 8px !important;
  }
  .sm-width-9px {
    width: 9px !important;
  }
  .sm-width-10px {
    width: 10px !important;
  }
  .sm-width-15px {
    width: 15px !important;
  }
  .sm-width-20px {
    width: 20px !important;
  }
  .sm-width-25px {
    width: 25px !important;
  }
  .sm-width-30px {
    width: 30px !important;
  }
  .sm-width-35px {
    width: 35px !important;
  }
  .sm-width-40px {
    width: 40px !important;
  }
  .sm-width-45px {
    width: 45px !important;
  }
  .sm-width-50px {
    width: 50px !important;
  }
  .sm-width-55px {
    width: 55px !important;
  }
  .sm-width-60px {
    width: 60px !important;
  }
  .sm-width-65px {
    width: 65px !important;
  }
  .sm-width-70px {
    width: 70px !important;
  }
  .sm-width-75px {
    width: 75px !important;
  }
  .sm-width-80px {
    width: 80px !important;
  }
  .sm-width-85px {
    width: 85px !important;
  }
  .sm-width-90px {
    width: 90px !important;
  }
  .sm-width-100px {
    width: 100px !important;
  }
  .sm-width-110px {
    width: 110px !important;
  }
  .sm-width-120px {
    width: 120px !important;
  }
  .sm-width-130px {
    width: 130px !important;
  }
  .sm-width-140px {
    width: 140px !important;
  }
  .sm-width-150px {
    width: 150px !important;
  }
  .sm-width-160px {
    width: 160px !important;
  }
  .sm-width-170px {
    width: 170px !important;
  }
  .sm-width-180px {
    width: 180px !important;
  }
  .sm-width-190px {
    width: 190px !important;
  }
  .sm-width-200px {
    width: 200px !important;
  }
  .sm-width-250px {
    width: 250px !important;
  }
  .sm-width-300px {
    width: 300px !important;
  }
  .sm-width-350px {
    width: 350px !important;
  }
  .sm-width-400px {
    width: 400px !important;
  }
  .sm-width-450px {
    width: 450px !important;
  }
  .sm-width-500px {
    width: 500px !important;
  }
  .sm-width-550px {
    width: 550px !important;
  }
  .sm-width-600px {
    width: 600px !important;
  }
  .sm-width-650px {
    width: 650px !important;
  }
  .sm-width-700px {
    width: 700px !important;
  }
  .sm-width-750px {
    width: 750px !important;
  }
  .sm-width-800px {
    width: 800px !important;
  }
  .sm-width-850px {
    width: 850px !important;
  }
  .sm-width-900px {
    width: 900px !important;
  }
  .sm-width-950px {
    width: 950px !important;
  }
  .sm-width-1000px {
    width: 1000px !important;
  }
  .sm-width-5 {
    width: 5% !important;
  }
  .sm-width-10 {
    width: 10% !important;
  }
  .sm-width-12 {
    width: 12% !important;
  }
  .sm-width-15 {
    width: 15% !important;
  }
  .sm-width-20 {
    width: 20% !important;
  }
  .sm-width-25 {
    width: 25% !important;
  }
  .sm-width-30 {
    width: 30% !important;
  }
  .sm-width-35 {
    width: 35% !important;
  }
  .sm-width-40 {
    width: 40% !important;
  }
  .sm-width-45 {
    width: 45% !important;
  }
  .sm-width-50 {
    width: 50% !important;
  }
  .sm-width-55 {
    width: 55% !important;
  }
  .sm-width-60 {
    width: 60% !important;
  }
  .sm-width-65 {
    width: 65% !important;
  }
  .sm-width-70 {
    width: 70% !important;
  }
  .sm-width-75 {
    width: 75% !important;
  }
  .sm-width-80 {
    width: 80% !important;
  }
  .sm-width-85 {
    width: 85% !important;
  }
  .sm-width-90 {
    width: 90% !important;
  }
  .sm-width-95 {
    width: 95% !important;
  }
  .sm-width-100 {
    width: 100% !important;
  }
  .sm-width-auto {
    width: auto !important;
  }
  .sm-height-1px {
    height: 1px !important;
  }
  .sm-height-2px {
    height: 2px !important;
  }
  .sm-height-3px {
    height: 3px !important;
  }
  .sm-height-4px {
    height: 4px !important;
  }
  .sm-height-5px {
    height: 5px !important;
  }
  .sm-height-6px {
    height: 6px !important;
  }
  .sm-height-7px {
    height: 7px !important;
  }
  .sm-height-8px {
    height: 8px !important;
  }
  .sm-height-9px {
    height: 9px !important;
  }
  .sm-height-5px {
    height: 5px !important;
  }
  .sm-height-10px {
    height: 10px !important;
  }
  .sm-height-15px {
    height: 15px !important;
  }
  .sm-height-20px {
    height: 20px !important;
  }
  .sm-height-25px {
    height: 25px !important;
  }
  .sm-height-30px {
    height: 30px !important;
  }
  .sm-height-35px {
    height: 30px !important;
  }
  .sm-height-40px {
    height: 40px !important;
  }
  .sm-height-45px {
    height: 45px !important;
  }
  .sm-height-50px {
    height: 50px !important;
  }
  .sm-height-55px {
    height: 55px !important;
  }
  .sm-height-60px {
    height: 60px !important;
  }
  .sm-height-65px {
    height: 60px !important;
  }
  .sm-height-70px {
    height: 70px !important;
  }
  .sm-height-75px {
    height: 70px !important;
  }
  .sm-height-80px {
    height: 80px !important;
  }
  .sm-height-85px {
    height: 80px !important;
  }
  .sm-height-90px {
    height: 90px !important;
  }
  .sm-height-95px {
    height: 90px !important;
  }
  .sm-height-100px {
    height: 100px !important;
  }
  .sm-height-110px {
    height: 110px !important;
  }
  .sm-height-120px {
    height: 120px !important;
  }
  .sm-height-130px {
    height: 130px !important;
  }
  .sm-height-140px {
    height: 130px !important;
  }
  .sm-height-150px {
    height: 150px !important;
  }
  .sm-height-160px {
    height: 160px !important;
  }
  .sm-height-170px {
    height: 170px !important;
  }
  .sm-height-180px {
    height: 180px !important;
  }
  .sm-height-190px {
    height: 190px !important;
  }
  .sm-height-200px {
    height: 200px !important;
  }
  .sm-height-250px {
    height: 250px !important;
  }
  .sm-height-300px {
    height: 300px !important;
  }
  .sm-height-350px {
    height: 350px !important;
  }
  .sm-height-400px {
    height: 400px !important;
  }
  .sm-height-450px {
    height: 450px !important;
  }
  .sm-height-500px {
    height: 500px !important;
  }
  .sm-height-550px {
    height: 550px !important;
  }
  .sm-height-600px {
    height: 600px !important;
  }
  .sm-height-650px {
    height: 650px !important;
  }
  .sm-height-700px {
    height: 700px !important;
  }
  .sm-height-10 {
    height: 10% !important;
  }
  .sm-height-20 {
    height: 20% !important;
  }
  .sm-height-30 {
    height: 30% !important;
  }
  .sm-height-40 {
    height: 40% !important;
  }
  .sm-height-50 {
    height: 50% !important;
  }
  .sm-height-55 {
    height: 55% !important;
  }
  .sm-height-60 {
    height: 60% !important;
  }
  .sm-height-70 {
    height: 70% !important;
  }
  .sm-height-80 {
    height: 80% !important;
  }
  .sm-height-90 {
    height: 90% !important;
  }
  .sm-height-100 {
    height: 100% !important;
  }
  .sm-height-full {
    height: 100vh !important;
  }
  .sm-height-auto {
    height: auto !important;
  }
  .sm-max-height-100 {
    max-height: 100% !important;
  }
  .sm-full-screen {
    min-height: 980px !important;
  }
}

@media all and (max-width: 575px) {
  .first-col {
    order: 13;
  }

  .uk-inline-clip-custom > img {
    max-width: 50%;
    margin-left: 25%;
    align-self: center !important;
  }

  .loader-img img {
    left: 28%;
    bottom: 45%;
    width: 200px;
  }

  .text-extra-small {
    font-size: 11px !important;
    line-height: 20px;
  }

  h1 {
    /* font-size: 35px !important; */
    font-size: 35px;
    line-height: 1.1;
  }

  h2 {
    /* font-size: 33px !important; */
    font-size: 33px;
    line-height: 1.2;
  }

  h3 {
    font-size: 29px !important;
    line-height: 1.2;
  }

  h4 {
    font-size: 25px;
    /* font-size: 25px !important; */
    line-height: 1.2;
  }

  h5 {
    font-size: 22px !important;
    line-height: 1.3;
  }

  h6 {
    /* font-size: 18px !important; */
    font-size: 18px;
    line-height: 1.5;
  }

  h1:empty {
    min-height: 35px;
  }

  h2:empty {
    min-height: 33px;
  }

  h3:empty {
    min-height: 29px;
  }

  h4:empty {
    min-height: 25px;
  }

  h5:empty {
    min-height: 22px;
  }

  h6:empty {
    min-height: 18px;
  }

  .xs-page-title h1 {
    font-size: 14px !important;
    /* font-family: Roboto; */
    font-weight: 400;
    margin-top: 15px !important;
    line-height: 22px !important;
  }
  /* .xs-overlay-white-light:after {
    background: url(../images/overlay-2.png) repeat !important;
    opacity: 0.6 !important;
  } */
  .xs-bg-transparent {
    background-color: transparent !important;
  }
  .xs-bg-white {
    background-color: #fff !important;
  }
  .xs-bg-black {
    background-color: #000 !important;
  }
  .xs-bg-gray-extra-light {
    background-color: #f9f9f9 !important;
  }
  .xs-bg-gray-light {
    background-color: #c0bfbf !important;
  }
  .xs-bg-gray-regular {
    background-color: #9d9d9d !important;
  }
  .xs-bg-gray-dark {
    background-color: #444 !important;
  }
  .xs-bg-gray-extra-dark {
    background-color: #232323 !important;
  }
  .xs-bg-blue {
    background-color: #1769ff !important;
  }
  .xs-bg-red {
    background-color: #e31919 !important;
  }
  .xs-line-height-normal {
    line-height: normal !important;
  }
  .xs-no-line-height {
    line-height: 0 !important;
  }
  .xs-line-height-spaced {
    line-height: 1xs-5em !important;
  }
  .xs-line-height-regular {
    line-height: 1xs-1em !important;
  }
  .xs-line-height-medium {
    line-height: 1xs-3em !important;
  }
  .xs-line-height-10 {
    line-height: 10px !important;
  }
  .xs-line-height-15 {
    line-height: 15px !important;
  }
  .xs-line-height-16 {
    line-height: 16px !important;
  }
  .xs-line-height-17 {
    line-height: 17px !important;
  }
  .xs-line-height-18 {
    line-height: 18px !important;
  }
  .xs-line-height-19 {
    line-height: 19px !important;
  }
  .xs-line-height-20 {
    line-height: 20px !important;
  }
  .xs-line-height-25 {
    line-height: 25px !important;
  }
  .xs-line-height-26 {
    line-height: 26px !important;
  }
  .xs-line-height-27 {
    line-height: 27px !important;
  }
  .xs-line-height-28 {
    line-height: 28px !important;
  }
  .xs-line-height-29 {
    line-height: 29px !important;
  }
  .xs-line-height-30 {
    line-height: 30px !important;
  }
  .xs-line-height-35 {
    line-height: 35px !important;
  }
  .xs-line-height-40 {
    line-height: 40px !important;
  }
  .xs-line-height-45 {
    line-height: 45px !important;
  }
  .xs-line-height-48 {
    line-height: 48px !important;
  }
  .xs-line-height-50 {
    line-height: 50px !important;
  }
  .xs-line-height-55 {
    line-height: 55px !important;
  }
  .xs-line-height-60 {
    line-height: 60px !important;
  }
  .xs-line-height-65 {
    line-height: 65px !important;
  }
  .xs-line-height-70 {
    line-height: 70px !important;
  }
  .xs-line-height-75 {
    line-height: 75px !important;
  }
  .xs-line-height-80 {
    line-height: 80px !important;
  }
  .xs-line-height-85 {
    line-height: 85px !important;
  }
  .xs-line-height-90 {
    line-height: 90px !important;
  }
  .xs-line-height-95 {
    line-height: 95px !important;
  }
  .xs-line-height-100 {
    line-height: 100px !important;
  }
  .xs-line-height-110 {
    line-height: 110px !important;
  }
  .xs-line-height-120 {
    line-height: 120px !important;
  }
  .xs-line-height-175 {
    line-height: 175px !important;
  }
  .xs-line-height-180 {
    line-height: 180px !important;
  }
  .xs-line-height-170 {
    line-height: 170px !important;
  }
  .xs-line-height-160 {
    line-height: 160px !important;
  }
  .xs-line-height-150 {
    line-height: 150px !important;
  }
  .xs-line-height-200 {
    line-height: 200px !important;
  }
  .xs-text-underline {
    text-decoration: underline !important;
  }
  .xs-text-line-through {
    text-decoration: line-through !important;
  }
  .xs-text-transform-none {
    text-transform: none !important;
  }
  .xs-text-transform-unset {
    text-transform: unset !important;
  }
  .xs-text-regular {
    font-style: normal !important;
  }
  .xs-text-italic {
    font-style: italic !important;
  }
  .xs-no-letter-spacing {
    letter-spacing: 0 !important;
  }
  .xs-letter-spacing-1 {
    letter-spacing: 1px !important;
  }
  .xs-letter-spacing-2 {
    letter-spacing: 2px !important;
  }
  .xs-letter-spacing-3 {
    letter-spacing: 3px !important;
  }
  .xs-letter-spacing-4 {
    letter-spacing: 4px !important;
  }
  .xs-letter-spacing-5 {
    letter-spacing: 5px !important;
  }
  .xs-letter-spacing-6 {
    letter-spacing: 6px !important;
  }
  .xs-letter-spacing-7 {
    letter-spacing: 7px !important;
  }
  .xs-letter-spacing-8 {
    letter-spacing: 8px !important;
  }
  .xs-letter-spacing-9 {
    letter-spacing: 9px !important;
  }
  .xs-letter-spacing-10 {
    letter-spacing: 10px !important;
  }
  .xs-letter-spacing-11 {
    letter-spacing: 11px !important;
  }
  .xs-letter-spacing-12 {
    letter-spacing: 12px !important;
  }
  .xs-letter-spacing-13 {
    letter-spacing: 13px !important;
  }
  .xs-letter-spacing-14 {
    letter-spacing: 14px !important;
  }
  .xs-letter-spacing-15 {
    letter-spacing: 15px !important;
  }
  .xs-letter-spacing-16 {
    letter-spacing: 16px !important;
  }
  .xs-letter-spacing-17 {
    letter-spacing: 17px !important;
  }
  .xs-letter-spacing-18 {
    letter-spacing: 18px !important;
  }
  .xs-letter-spacing-19 {
    letter-spacing: 19px !important;
  }
  .xs-letter-spacing-20 {
    letter-spacing: 20px !important;
  }
  .xs-letter-spacing-50 {
    letter-spacing: 50px !important;
  }
  .xs-letter-spacing-minus-1 {
    letter-spacing: -1px !important;
  }
  .xs-letter-spacing-minus-2 {
    letter-spacing: -2px !important;
  }
  .xs-letter-spacing-minus-3 {
    letter-spacing: -3px !important;
  }
  .xs-letter-spacing-minus-4 {
    letter-spacing: -4px !important;
  }
  .xs-letter-spacing-minus-5 {
    letter-spacing: -5px !important;
  }
  .xs-letter-spacing-minus-6 {
    letter-spacing: -6px !important;
  }
  .xs-letter-spacing-minus-7 {
    letter-spacing: -7px !important;
  }
  .xs-letter-spacing-minus-8 {
    letter-spacing: -8px !important;
  }
  .xs-letter-spacing-minus-9 {
    letter-spacing: -9px !important;
  }
  .xs-letter-spacing-minus-10 {
    letter-spacing: -10px !important;
  }
  .xs-no-word-spacing {
    word-spacing: 0 !important;
  }
  .xs-word-spacing-1 {
    word-spacing: 1px !important;
  }
  .xs-word-spacing-2 {
    word-spacing: 2px !important;
  }
  .xs-word-spacing-3 {
    word-spacing: 3px !important;
  }
  .xs-word-spacing-4 {
    word-spacing: 4px !important;
  }
  .xs-word-spacing-5 {
    word-spacing: 5px !important;
  }
  .xs-word-spacing-6 {
    word-spacing: 6px !important;
  }
  .xs-word-spacing-7 {
    word-spacing: 7px !important;
  }
  .xs-word-spacing-8 {
    word-spacing: 8px !important;
  }
  .xs-word-spacing-9 {
    word-spacing: 9px !important;
  }
  .xs-word-spacing-10 {
    word-spacing: 10px !important;
  }
  .xs-word-spacing-minus-1 {
    word-spacing: -1px !important;
  }
  .xs-word-spacing-minus-2 {
    word-spacing: -2px !important;
  }
  .xs-word-spacing-minus-3 {
    word-spacing: -3px !important;
  }
  .xs-word-spacing-minus-4 {
    word-spacing: -4px !important;
  }
  .xs-word-spacing-minus-5 {
    word-spacing: -5px !important;
  }
  .xs-word-spacing-minus-6 {
    word-spacing: -6px !important;
  }
  .xs-word-spacing-minus-7 {
    word-spacing: -7px !important;
  }
  .xs-word-spacing-minus-8 {
    word-spacing: -8px !important;
  }
  .xs-word-spacing-minus-9 {
    word-spacing: -9px !important;
  }
  .xs-word-spacing-minus-10 {
    word-spacing: -10px !important;
  }
  .xs-text-extra-small {
    font-size: 11px !important;
    line-height: 14px !important;
  }
  .xs-text-small {
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .xs-text-medium {
    font-size: 16px !important;
    line-height: 23px !important;
  }
  .xs-text-large {
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .xs-text-extra-large {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .xs-title-extra-small {
    font-size: 35px !important;
    line-height: 38px !important;
  }
  .xs-title-small {
    font-size: 55px !important;
    line-height: 48px !important;
  }
  .xs-title-medium {
    font-size: 75px !important;
    line-height: 60px !important;
  }
  .xs-title-large {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .xs-title-extra-large {
    font-size: 130px !important;
    line-height: 138px !important;
  }
  .xs-text-center {
    text-align: center !important;
  }
  .xs-text-left {
    text-align: left !important;
  }
  .xs-text-right {
    text-align: right !important;
  }
  .xs-text-white {
    color: #fff !important;
  }
  .xs-text-black {
    color: #000 !important;
  }
  .xs-text-blue {
    color: #1769ff !important;
  }
  .xs-text-red {
    color: #e31919 !important;
  }
  .xs-text-gray-light {
    color: #c0bfbf !important;
  }
  .xs-text-gray-regular {
    color: #9d9d9d !important;
  }
  .xs-text-gray-dark {
    color: #444 !important;
  }
  .xs-text-gray-extra-dark {
    color: #232323 !important;
  }
  .xs-text-weight-100 {
    font-weight: 100 !important;
  }
  .xs-text-weight-200 {
    font-weight: 200 !important;
  }
  .xs-text-weight-300 {
    font-weight: 300 !important;
  }
  .xs-text-weight-400 {
    font-weight: 400 !important;
  }
  .xs-text-weight-500 {
    font-weight: 500 !important;
  }
  .xs-text-weight-600 {
    font-weight: 600 !important;
  }
  .xs-text-weight-700 {
    font-weight: 700 !important;
  }
  .xs-text-weight-800 {
    font-weight: 800 !important;
  }
  .xs-text-weight-900 {
    font-weight: 900 !important;
  }
  .xs-display-block {
    display: block !important;
  }
  .xs-display-inline-block {
    display: inline-block !important;
  }
  .xs-display-inline {
    display: inline !important;
  }
  .xs-display-none {
    display: none !important;
  }
  .xs-display-inherit {
    display: inherit !important;
  }
  .xs-display-table {
    display: table !important;
  }
  .xs-display-table-cell {
    display: table-cell !important;
  }
  .xs-overflow-hidden {
    overflow: hidden !important;
  }
  .xs-overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xs-overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xs-overflow-visible {
    overflow: visible !important;
  }
  .xs-overflow-auto {
    overflow: auto !important;
  }
  .xs-overflow-x-auto {
    overflow-x: auto !important;
  }
  .xs-overflow-y-auto {
    overflow-y: auto !important;
  }
  .xs-overflow-scroll {
    overflow: scroll !important;
  }
  .xs-vertical-align-middle {
    vertical-align: middle !important;
  }
  .xs-vertical-align-top {
    vertical-align: top !important;
  }
  .xs-vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xs-vertical-middle {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    position: absolute;
    top: 50% !important;
    left: 0;
    right: 0 !important;
  }
  .xs-middle-center {
    left: 50% !important;
    top: 50% !important;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%) !important;
  }
  .xs-center-col {
    float: none;
    margin-left: auto;
    margin-right: auto !important;
  }
  .xs-right-col {
    float: right;
    margin-left: auto;
    margin-right: auto !important;
  }
  .xs-left-col {
    float: left;
    margin-right: auto;
    margin-right: auto !important;
  }
  .xs-position-inherit {
    position: inherit !important;
  }
  .xs-position-relative {
    position: relative !important;
  }
  .xs-position-absolute {
    position: absolute !important;
  }
  .xs-position-fixed {
    position: fixed !important;
  }
  .xs-position-right {
    right: 0 !important;
  }
  .xs-position-left {
    left: 0 !important;
  }
  .xs-position-top {
    top: 0 !important;
  }
  .xs-top-1 {
    top: 1px !important;
  }
  .xs-top-2 {
    top: 2px !important;
  }
  .xs-top-3 {
    top: 2px !important;
  }
  .xs-top-4 {
    top: 2px !important;
  }
  .xs-top-5 {
    top: 2px !important;
  }
  .xs-top-6 {
    top: 2px !important;
  }
  .xs-top-7 {
    top: 2px !important;
  }
  .xs-top-8 {
    top: 2px !important;
  }
  .xs-top-9 {
    top: 2px !important;
  }
  .xs-top-10 {
    top: 2px !important;
  }
  .xs-bottom-1 {
    bottom: 1px !important;
  }
  .xs-bottom-2 {
    bottom: 2px !important;
  }
  .xs-bottom-3 {
    top: 3px !important;
  }
  .xs-bottom-4 {
    top: 4px !important;
  }
  .xs-bottom-5 {
    top: 5px !important;
  }
  .xs-bottom-6 {
    top: 6px !important;
  }
  .xs-bottom-7 {
    top: 7px !important;
  }
  .xs-bottom-8 {
    top: 8px !important;
  }
  .xs-bottom-9 {
    top: 9px !important;
  }
  .xs-bottom-10 {
    top: 10px !important;
  }
  .xs-float-left {
    float: left !important;
  }
  .xs-float-right {
    float: right !important;
  }
  .xs-float-none {
    float: none !important;
  }
  .xs-clear-both {
    clear: both !important;
  }
  .xs-z-index-9999 {
    z-index: 9999 !important;
  }
  .xs-z-index-999 {
    z-index: 999 !important;
  }
  .xs-z-index-99 {
    z-index: 99 !important;
  }
  .xs-z-index-6 {
    z-index: 6 !important;
  }
  .xs-z-index-5 {
    z-index: 5 !important;
  }
  .xs-z-index-4 {
    z-index: 4 !important;
  }
  .xs-z-index-3 {
    z-index: 3 !important;
  }
  .xs-z-index-2 {
    z-index: 2 !important;
  }
  .xs-z-index-1 {
    z-index: 1 !important;
  }
  .xs-z-index-0 {
    z-index: 0 !important;
  }
  .xs-z-index-minus1 {
    z-index: -1 !important;
  }
  .xs-z-index-minus2 {
    z-index: -2 !important;
  }
  .xs-opacity-1 {
    opacity: 0xs-1 !important;
  }
  .xs-opacity-2 {
    opacity: 0xs-2 !important;
  }
  .xs-opacity-3 {
    opacity: 0xs-3 !important;
  }
  .xs-opacity-4 {
    opacity: 0xs-4 !important;
  }
  .xs-opacity-5 {
    opacity: 0xs-5 !important;
  }
  .xs-opacity-6 {
    opacity: 0xs-6 !important;
  }
  .xs-opacity-7 {
    opacity: 0xs-7 !important;
  }
  .xs-opacity-8 {
    opacity: 0xs-8 !important;
  }
  .xs-opacity-9 {
    opacity: 0xs-9 !important;
  }
  .xs-opacity-visible {
    opacity: 1 !important;
  }
  .xs-hidden {
    display: none;
    opacity: 0 !important;
  }
  .xs-all-border {
    border: 1px solid !important;
  }
  .xs-top-border {
    border-top: 1px solid !important;
  }
  .xs-bottom-border {
    border-bottom: 1px solid !important;
  }
  .xs-left-border {
    border-left: 1px solid !important;
  }
  .xs-right-border {
    border-right: 1px solid !important;
  }
  .xs-border-left-right {
    border-left: 1px solid;
    border-right: 1px solid !important;
  }
  .xs-border-right-left {
    border-top: 1px solid;
    border-bottom: 1px solid !important;
  }
  .xs-no-border {
    border: 0 !important;
  }
  .xs-no-border-top {
    border-top: 0 !important;
  }
  .xs-no-border-bottom {
    border-bottom: 0 !important;
  }
  .xs-no-border-left {
    border-left: 0 !important;
  }
  .xs-no-border-right {
    border-right: 0 !important;
  }
  .xs-border-1px {
    border-width: 1px !important;
  }
  .xs-border-2px {
    border-width: 2px !important;
  }
  .xs-border-3px {
    border-width: 3px !important;
  }
  .xs-border-4px {
    border-width: 4px !important;
  }
  .xs-border-5px {
    border-width: 5px !important;
  }
  .xs-border-6px {
    border-width: 6px !important;
  }
  .xs-border-7px {
    border-width: 7px !important;
  }
  .xs-border-8px {
    border-width: 8px !important;
  }
  .xs-border-9px {
    border-width: 9px !important;
  }
  .xs-border-10px {
    border-width: 10px !important;
  }
  .xs-border-11px {
    border-width: 11px !important;
  }
  .xs-border-12px {
    border-width: 12px !important;
  }
  .xs-border-13px {
    border-width: 13px !important;
  }
  .xs-border-14px {
    border-width: 14px !important;
  }
  .xs-border-15px {
    border-width: 15px !important;
  }
  .xs-border-16px {
    border-width: 16px !important;
  }
  .xs-border-17px {
    border-width: 17px !important;
  }
  .xs-border-18px {
    border-width: 18px !important;
  }
  .xs-border-19px {
    border-width: 19px !important;
  }
  .xs-border-20px {
    border-width: 20px !important;
  }
  .xs-border-color-white {
    border-color: #fff !important;
  }
  .xs-border-color-black {
    border-color: #000 !important;
  }
  .xs-border-color-blue {
    border-color: #1769ff !important;
  }
  .xs-border-color-gray-extra-light {
    border-color: #e5e5e5 !important;
  }
  .xs-border-color-gray-light {
    border-color: #c0bfbf !important;
  }
  .xs-border-color-gray-regular {
    border-color: #9d9d9d !important;
  }
  .xs-border-color-gray-dark {
    border-color: #444 !important;
  }
  .xs-border-color-gray-extra-dark {
    border-color: #232323 !important;
  }
  .xs-border-black-light {
    border-color: rgba(0, 0, 0, .xs-1) !important;
  }
  .xs-border-white-light {
    border-color: rgba(255, 255, 255, .xs-1) !important;
  }
  .xs-border-radius-1 {
    border-radius: 1px !important;
  }
  .xs-border-radius-2 {
    border-radius: 2px !important;
  }
  .xs-border-radius-3 {
    border-radius: 3px !important;
  }
  .xs-border-radius-4 {
    border-radius: 4px !important;
  }
  .xs-border-radius-5 {
    border-radius: 5px !important;
  }
  .xs-border-radius-6 {
    border-radius: 6px !important;
  }
  .xs-border-radius-7 {
    border-radius: 7px !important;
  }
  .xs-border-radius-8 {
    border-radius: 8px !important;
  }
  .xs-border-radius-9 {
    border-radius: 9px !important;
  }
  .xs-border-radius-10 {
    border-radius: 11px !important;
  }
  .xs-border-radius-11 {
    border-radius: 11px !important;
  }
  .xs-border-radius-12 {
    border-radius: 12px !important;
  }
  .xs-border-radius-13 {
    border-radius: 13px !important;
  }
  .xs-border-radius-14 {
    border-radius: 14px !important;
  }
  .xs-border-radius-15 {
    border-radius: 15px !important;
  }
  .xs-border-radius-16 {
    border-radius: 16px !important;
  }
  .xs-border-radius-17 {
    border-radius: 17px !important;
  }
  .xs-border-radius-18 {
    border-radius: 18px !important;
  }
  .xs-border-radius-19 {
    border-radius: 19px !important;
  }
  .xs-border-radius-20 {
    border-radius: 20px !important;
  }
  .xs-border-radius-50 {
    border-radius: 50px !important;
  }
  .xs-border-radius-100 {
    border-radius: 100px !important;
  }
  .xs-border-radius-50-percent {
    border-radius: 50% !important;
  }
  .xs-border-radius-100-percent {
    border-radius: 100% !important;
  }
  .xs-border-radius-none {
    border-radius: 0 !important;
  }
  .xs-no-margin {
    margin: 0 !important;
  }
  .xs-no-margin-top {
    margin-top: 0 !important;
  }
  .xs-no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .xs-no-margin-left {
    margin-left: 0 !important;
  }
  .xs-no-margin-right {
    margin-right: 0 !important;
  }
  .xs-no-margin-left-right {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .xs-no-margin-top-bottom {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .xs-margin-auto {
    margin: 0 auto !important;
  }
  .xs-margin-left-right-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xs-margin-left-auto {
    margin-left: auto !important;
  }
  .xs-margin-right-auto {
    margin-right: auto !important;
  }
  .xs-margin-one-percent {
    margin: 1% !important;
  }
  .xs-margin-two-percent {
    margin: 2% !important;
  }
  .xs-margin-three-percent {
    margin: 3% !important;
  }
  .xs-margin-four-percent {
    margin: 4% !important;
  }
  .xs-margin-five-percent {
    margin: 5% !important;
  }
  .xs-margin-six-percent {
    margin: 6% !important;
  }
  .xs-margin-seven-percent {
    margin: 7% !important;
  }
  .xs-margin-eight-percent {
    margin: 8% !important;
  }
  .xs-margin-nine-percent {
    margin: 9% !important;
  }
  .xs-margin-ten-percent {
    margin: 10% !important;
  }
  .xs-margin-eleven-percent {
    margin: 11% !important;
  }
  .xs-margin-twelve-percent {
    margin: 12% !important;
  }
  .xs-margin-thirteen-percent {
    margin: 13% !important;
  }
  .xs-margin-fourteen-percent {
    margin: 14% !important;
  }
  .xs-margin-fifteen-percent {
    margin: 15% !important;
  }
  .xs-margin-sixteen-percent {
    margin: 16% !important;
  }
  .xs-margin-seventeen-percent {
    margin: 17% !important;
  }
  .xs-margin-eighteen-percent {
    margin: 18% !important;
  }
  .xs-margin-nineteen-percent {
    margin: 19% !important;
  }
  .xs-margin-twenty-percent {
    margin: 20% !important;
  }
  .xs-margin-twenty-one-percent {
    margin: 21% !important;
  }
  .xs-margin-twenty-two-percent {
    margin: 22% !important;
  }
  .xs-margin-twenty-three-percent {
    margin: 23% !important;
  }
  .xs-margin-twenty-four-percent {
    margin: 24% !important;
  }
  .xs-margin-twenty-five-percent {
    margin: 25% !important;
  }
  .xs-margin-twenty-six-percent {
    margin: 26% !important;
  }
  .xs-margin-twenty-seven-percent {
    margin: 27% !important;
  }
  .xs-margin-twenty-eight-percent {
    margin: 28% !important;
  }
  .xs-margin-twenty-nine-percent {
    margin: 29% !important;
  }
  .xs-margin-thirty-percent {
    margin: 30% !important;
  }
  .xs-margin-thirty-one-percent {
    margin: 31% !important;
  }
  .xs-margin-thirty-two-percent {
    margin: 32% !important;
  }
  .xs-margin-thirty-three-percent {
    margin: 33% !important;
  }
  .xs-margin-thirty-four-percent {
    margin: 34% !important;
  }
  .xs-margin-thirty-five-percent {
    margin: 35% !important;
  }
  .xs-margin-thirty-six-percent {
    margin: 36% !important;
  }
  .xs-margin-thirty-seven-percent {
    margin: 37% !important;
  }
  .xs-margin-thirty-eight-percent {
    margin: 38% !important;
  }
  .xs-margin-thirty-nine-percent {
    margin: 39% !important;
  }
  .xs-margin-forty-percent {
    margin: 40% !important;
  }
  .xs-margin-forty-one-percent {
    margin: 41% !important;
  }
  .xs-margin-forty-two-percent {
    margin: 42% !important;
  }
  .xs-margin-forty-three-percent {
    margin: 43% !important;
  }
  .xs-margin-forty-four-percent {
    margin: 44% !important;
  }
  .xs-margin-forty-five-percent {
    margin: 45% !important;
  }
  .xs-margin-forty-six-percent {
    margin: 46% !important;
  }
  .xs-margin-forty-seven-percent {
    margin: 47% !important;
  }
  .xs-margin-forty-eight-percent {
    margin: 48% !important;
  }
  .xs-margin-forty-nine-percent {
    margin: 49% !important;
  }
  .xs-margin-fifty-percent {
    margin: 50% !important;
  }
  .xs-margin-5px {
    margin: 5px !important;
  }
  .xs-margin-10px {
    margin: 10px !important;
  }
  .xs-margin-15px {
    margin: 15px !important;
  }
  .xs-margin-20px {
    margin: 20px !important;
  }
  .xs-margin-25px {
    margin: 25px !important;
  }
  .xs-margin-30px {
    margin: 30px !important;
  }
  .xs-margin-35px {
    margin: 35px !important;
  }
  .xs-margin-40px {
    margin: 40px !important;
  }
  .xs-margin-45px {
    margin: 45px !important;
  }
  .xs-margin-50px {
    margin: 50px !important;
  }
  .xs-margin-55px {
    margin: 55px !important;
  }
  .xs-margin-60px {
    margin: 60px !important;
  }
  .xs-margin-65px {
    margin: 65px !important;
  }
  .xs-margin-70px {
    margin: 70px !important;
  }
  .xs-margin-75px {
    margin: 75px !important;
  }
  .xs-margin-80px {
    margin: 80px !important;
  }
  .xs-margin-85px {
    margin: 85px !important;
  }
  .xs-margin-90px {
    margin: 90px !important;
  }
  .xs-margin-95px {
    margin: 95px !important;
  }
  .xs-margin-100px {
    margin: 100px !important;
  }
  .xs-margin-120px {
    margin: 120px !important;
  }
  .xs-margin-150px {
    margin: 150px !important;
  }
  .xs-margin-top-one-percent {
    margin-top: 1% !important;
  }
  .xs-margin-top-two-percent {
    margin-top: 2% !important;
  }
  .xs-margin-top-three-percent {
    margin-top: 3% !important;
  }
  .xs-margin-top-four-percent {
    margin-top: 4% !important;
  }
  .xs-margin-top-five-percent {
    margin-top: 5% !important;
  }
  .xs-margin-top-six-percent {
    margin-top: 6% !important;
  }
  .xs-margin-top-seven-percent {
    margin-top: 7% !important;
  }
  .xs-margin-top-eight-percent {
    margin-top: 8% !important;
  }
  .xs-margin-top-nine-percent {
    margin-top: 9% !important;
  }
  .xs-margin-top-ten-percent {
    margin-top: 10% !important;
  }
  .xs-margin-top-eleven-percent {
    margin-top: 11% !important;
  }
  .xs-margin-top-twelve-percent {
    margin-top: 12% !important;
  }
  .xs-margin-top-thirteen-percent {
    margin-top: 13% !important;
  }
  .xs-margin-top-fourteen-percent {
    margin-top: 14% !important;
  }
  .xs-margin-top-fifteen-percent {
    margin-top: 15% !important;
  }
  .xs-margin-top-sixteen-percent {
    margin-top: 16% !important;
  }
  .xs-margin-top-seventeen-percent {
    margin-top: 17% !important;
  }
  .xs-margin-top-eighteen-percent {
    margin-top: 18% !important;
  }
  .xs-margin-top-nineteen-percent {
    margin-top: 19% !important;
  }
  .xs-margin-top-twenty-percent {
    margin-top: 20% !important;
  }
  .xs-margin-top-twenty-one-percent {
    margin-top: 21% !important;
  }
  .xs-margin-top-twenty-two-percent {
    margin-top: 22% !important;
  }
  .xs-margin-top-twenty-three-percent {
    margin-top: 23% !important;
  }
  .xs-margin-top-twenty-four-percent {
    margin-top: 24% !important;
  }
  .xs-margin-top-twenty-five-percent {
    margin-top: 25% !important;
  }
  .xs-margin-top-1px {
    margin-top: 1px !important;
  }
  .xs-margin-top-5px {
    margin-top: 5px !important;
  }
  .xs-margin-top-10px {
    margin-top: 10px !important;
  }
  .xs-margin-top-15px {
    margin-top: 15px !important;
  }
  .xs-margin-top-20px {
    margin-top: 20px !important;
  }
  .xs-margin-top-25px {
    margin-top: 25px !important;
  }
  .xs-margin-top-30px {
    margin-top: 30px !important;
  }
  .xs-margin-top-35px {
    margin-top: 35px !important;
  }
  .xs-margin-top-40px {
    margin-top: 40px !important;
  }
  .xs-margin-top-45px {
    margin-top: 45px !important;
  }
  .xs-margin-top-50px {
    margin-top: 50px !important;
  }
  .xs-margin-top-55px {
    margin-top: 55px !important;
  }
  .xs-margin-top-60px {
    margin-top: 60px !important;
  }
  .xs-margin-top-65px {
    margin-top: 65px !important;
  }
  .xs-margin-top-70px {
    margin-top: 70px !important;
  }
  .xs-margin-top-75px {
    margin-top: 75px !important;
  }
  .xs-margin-top-80px {
    margin-top: 80px !important;
  }
  .xs-margin-top-85px {
    margin-top: 85px !important;
  }
  .xs-margin-top-90px {
    margin-top: 90px !important;
  }
  .xs-margin-top-95px {
    margin-top: 95px !important;
  }
  .xs-margin-top-100px {
    margin-top: 100px !important;
  }
  .xs-margin-top-110px {
    margin-top: 110px !important;
  }
  .xs-margin-top-120px {
    margin-top: 120px !important;
  }
  .xs-margin-top-130px {
    margin-top: 130px !important;
  }
  .xs-margin-top-140px {
    margin-top: 140px !important;
  }
  .xs-margin-top-150px {
    margin-top: 150px !important;
  }
  .xs-margin-bottom-5px {
    margin-bottom: 5px !important;
  }
  .xs-margin-bottom-10px {
    margin-bottom: 10px !important;
  }
  .xs-margin-bottom-15px {
    margin-bottom: 15px !important;
  }
  .xs-margin-bottom-20px {
    margin-bottom: 20px !important;
  }
  .xs-margin-bottom-25px {
    margin-bottom: 25px !important;
  }
  .xs-margin-bottom-30px {
    margin-bottom: 30px !important;
  }
  .xs-margin-bottom-35px {
    margin-bottom: 35px !important;
  }
  .xs-margin-bottom-40px {
    margin-bottom: 40px !important;
  }
  .xs-margin-bottom-45px {
    margin-bottom: 45px !important;
  }
  .xs-margin-bottom-50px {
    margin-bottom: 50px !important;
  }
  .xs-margin-bottom-55px {
    margin-bottom: 55px !important;
  }
  .xs-margin-bottom-60px {
    margin-bottom: 60px !important;
  }
  .xs-margin-bottom-65px {
    margin-bottom: 65px !important;
  }
  .xs-margin-bottom-70px {
    margin-bottom: 70px !important;
  }
  .xs-margin-bottom-75px {
    margin-bottom: 75px !important;
  }
  .xs-margin-bottom-80px {
    margin-bottom: 80px !important;
  }
  .xs-margin-bottom-85px {
    margin-bottom: 85px !important;
  }
  .xs-margin-bottom-90px {
    margin-bottom: 90px !important;
  }
  .xs-margin-bottom-95px {
    margin-bottom: 95px !important;
  }
  .xs-margin-bottom-100px {
    margin-bottom: 100px !important;
  }
  .xs-margin-bottom-120px {
    margin-bottom: 120px !important;
  }
  .xs-margin-bottom-150px {
    margin-bottom: 150px !important;
  }
  .xs-margin-bottom-one-percent {
    margin-bottom: 1% !important;
  }
  .xs-margin-bottom-two-percent {
    margin-bottom: 2% !important;
  }
  .xs-margin-bottom-three-percent {
    margin-bottom: 3% !important;
  }
  .xs-margin-bottom-four-percent {
    margin-bottom: 4% !important;
  }
  .xs-margin-bottom-five-percent {
    margin-bottom: 5% !important;
  }
  .xs-margin-bottom-six-percent {
    margin-bottom: 6% !important;
  }
  .xs-margin-bottom-seven-percent {
    margin-bottom: 7% !important;
  }
  .xs-margin-bottom-eight-percent {
    margin-bottom: 8% !important;
  }
  .xs-margin-bottom-nine-percent {
    margin-bottom: 9% !important;
  }
  .xs-margin-bottom-ten-percent {
    margin-bottom: 10% !important;
  }
  .xs-margin-bottom-eleven-percent {
    margin-bottom: 11% !important;
  }
  .xs-margin-bottom-twelve-percent {
    margin-bottom: 12% !important;
  }
  .xs-margin-bottom-thirteen-percent {
    margin-bottom: 13% !important;
  }
  .xs-margin-bottom-fourteen-percent {
    margin-bottom: 14% !important;
  }
  .xs-margin-bottom-fifteen-percent {
    margin-bottom: 15% !important;
  }
  .xs-margin-bottom-sixteen-percent {
    margin-bottom: 16% !important;
  }
  .xs-margin-bottom-seventeen-percent {
    margin-bottom: 17% !important;
  }
  .xs-margin-bottom-eighteen-percent {
    margin-bottom: 18% !important;
  }
  .xs-margin-bottom-nineteen-percent {
    margin-bottom: 19% !important;
  }
  .xs-margin-bottom-twenty-percent {
    margin-bottom: 20% !important;
  }
  .xs-margin-bottom-twenty-one-percent {
    margin-bottom: 21% !important;
  }
  .xs-margin-bottom-twenty-two-percent {
    margin-bottom: 22% !important;
  }
  .xs-margin-bottom-twenty-three-percent {
    margin-bottom: 23% !important;
  }
  .xs-margin-bottom-twenty-four-percent {
    margin-bottom: 24% !important;
  }
  .xs-margin-bottom-twenty-five-percent {
    margin-bottom: 25% !important;
  }
  .xs-margin-right-one-percent {
    margin-right: 1% !important;
  }
  .xs-margin-right-two-percent {
    margin-right: 2% !important;
  }
  .xs-margin-right-three-percent {
    margin-right: 3% !important;
  }
  .xs-margin-right-four-percent {
    margin-right: 4% !important;
  }
  .xs-margin-right-five-percent {
    margin-right: 5% !important;
  }
  .xs-margin-right-six-percent {
    margin-right: 6% !important;
  }
  .xs-margin-right-seven-percent {
    margin-right: 7% !important;
  }
  .xs-margin-right-eight-percent {
    margin-right: 8% !important;
  }
  .xs-margin-right-nine-percent {
    margin-right: 9% !important;
  }
  .xs-margin-right-ten-percent {
    margin-right: 10% !important;
  }
  .xs-margin-right-eleven-percent {
    margin-right: 11% !important;
  }
  .xs-margin-right-twelve-percent {
    margin-right: 12% !important;
  }
  .xs-margin-right-thirteen-percent {
    margin-right: 13% !important;
  }
  .xs-margin-right-fourteen-percent {
    margin-right: 14% !important;
  }
  .xs-margin-right-fifteen-percent {
    margin-right: 15% !important;
  }
  .xs-margin-right-sixteen-percent {
    margin-right: 16% !important;
  }
  .xs-margin-right-seventeen-percent {
    margin-right: 17% !important;
  }
  .xs-margin-right-eighteen-percent {
    margin-right: 18% !important;
  }
  .xs-margin-right-nineteen-percent {
    margin-right: 19% !important;
  }
  .xs-margin-right-twenty-percent {
    margin-right: 20% !important;
  }
  .xs-margin-right-twenty-one-percent {
    margin-right: 21% !important;
  }
  .xs-margin-right-twenty-two-percent {
    margin-right: 22% !important;
  }
  .xs-margin-right-twenty-three-percent {
    margin-right: 23% !important;
  }
  .xs-margin-right-twenty-four-percent {
    margin-right: 24% !important;
  }
  .xs-margin-right-twenty-five-percent {
    margin-right: 25% !important;
  }
  .xs-margin-right-1px {
    margin-right: 2px !important;
  }
  .xs-margin-right-2px {
    margin-right: 2px !important;
  }
  .xs-margin-right-5px {
    margin-right: 5px !important;
  }
  .xs-margin-right-10px {
    margin-right: 10px !important;
  }
  .xs-margin-right-15px {
    margin-right: 15px !important;
  }
  .xs-margin-right-20px {
    margin-right: 20px !important;
  }
  .xs-margin-right-25px {
    margin-right: 25px !important;
  }
  .xs-margin-right-30px {
    margin-right: 30px !important;
  }
  .xs-margin-right-35px {
    margin-right: 35px !important;
  }
  .xs-margin-right-40px {
    margin-right: 40px !important;
  }
  .xs-margin-right-45px {
    margin-right: 45px !important;
  }
  .xs-margin-right-50px {
    margin-right: 50px !important;
  }
  .xs-margin-right-55px {
    margin-right: 55px !important;
  }
  .xs-margin-right-60px {
    margin-right: 60px !important;
  }
  .xs-margin-right-65px {
    margin-right: 65px !important;
  }
  .xs-margin-right-70px {
    margin-right: 70px !important;
  }
  .xs-margin-right-75px {
    margin-right: 75px !important;
  }
  .xs-margin-right-80px {
    margin-right: 80px !important;
  }
  .xs-margin-right-85px {
    margin-right: 85px !important;
  }
  .xs-margin-right-90px {
    margin-right: 90px !important;
  }
  .xs-margin-right-95px {
    margin-right: 95px !important;
  }
  .xs-margin-right-100px {
    margin-right: 100px !important;
  }
  .xs-margin-left-one-percent {
    margin-left: 1% !important;
  }
  .xs-margin-left-two-percent {
    margin-left: 2% !important;
  }
  .xs-margin-left-three-percent {
    margin-left: 3% !important;
  }
  .xs-margin-left-four-percent {
    margin-left: 4% !important;
  }
  .xs-margin-left-five-percent {
    margin-left: 5% !important;
  }
  .xs-margin-left-six-percent {
    margin-left: 6% !important;
  }
  .xs-margin-left-seven-percent {
    margin-left: 7% !important;
  }
  .xs-margin-left-eight-percent {
    margin-left: 8% !important;
  }
  .xs-margin-left-nine-percent {
    margin-left: 9% !important;
  }
  .xs-margin-left-ten-percent {
    margin-left: 10% !important;
  }
  .xs-margin-left-eleven-percent {
    margin-left: 11% !important;
  }
  .xs-margin-left-twelve-percent {
    margin-left: 12% !important;
  }
  .xs-margin-left-thirteen-percent {
    margin-left: 13% !important;
  }
  .xs-margin-left-fourteen-percent {
    margin-left: 14% !important;
  }
  .xs-margin-left-fifteen-percent {
    margin-left: 15% !important;
  }
  .xs-margin-left-sixteen-percent {
    margin-left: 16% !important;
  }
  .xs-margin-left-seventeen-percent {
    margin-left: 17% !important;
  }
  .xs-margin-left-eighteen-percent {
    margin-left: 18% !important;
  }
  .xs-margin-left-nineteen-percent {
    margin-left: 19% !important;
  }
  .xs-margin-left-twenty-percent {
    margin-left: 20% !important;
  }
  .xs-margin-left-twenty-one-percent {
    margin-left: 21% !important;
  }
  .xs-margin-left-twenty-two-percent {
    margin-left: 22% !important;
  }
  .xs-margin-left-twenty-three-percent {
    margin-left: 23% !important;
  }
  .xs-margin-left-twenty-four-percent {
    margin-left: 24% !important;
  }
  .xs-margin-left-twenty-five-percent {
    margin-left: 25% !important;
  }
  .xs-margin-left-5px {
    margin-left: 5px !important;
  }
  .xs-margin-left-10px {
    margin-left: 10px !important;
  }
  .xs-margin-left-15px {
    margin-left: 15px !important;
  }
  .xs-margin-left-20px {
    margin-left: 20px !important;
  }
  .xs-margin-left-25px {
    margin-left: 25px !important;
  }
  .xs-margin-left-30px {
    margin-left: 30px !important;
  }
  .xs-margin-left-35px {
    margin-left: 35px !important;
  }
  .xs-margin-left-40px {
    margin-left: 40px !important;
  }
  .xs-margin-left-45px {
    margin-left: 45px !important;
  }
  .xs-margin-left-50px {
    margin-left: 50px !important;
  }
  .xs-margin-left-55px {
    margin-left: 55px !important;
  }
  .xs-margin-left-60px {
    margin-left: 60px !important;
  }
  .xs-margin-left-65px {
    margin-left: 65px !important;
  }
  .xs-margin-left-70px {
    margin-left: 70px !important;
  }
  .xs-margin-left-75px {
    margin-left: 75px !important;
  }
  .xs-margin-left-80px {
    margin-left: 80px !important;
  }
  .xs-margin-left-85px {
    margin-left: 85px !important;
  }
  .xs-margin-left-90px {
    margin-left: 90px !important;
  }
  .xs-margin-left-95px {
    margin-left: 95px !important;
  }
  .xs-margin-left-100px {
    margin-left: 100px !important;
  }
  .xs-margin-left-150px {
    margin-left: 150px !important;
  }
  .xs-margin-top-bottom-one-percent {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .xs-margin-top-bottom-two-percent {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
  .xs-margin-top-bottom-three-percent {
    margin-top: 3% !important;
    margin-bottom: 3% !important;
  }
  .xs-margin-top-bottom-four-percent {
    margin-top: 4% !important;
    margin-bottom: 4% !important;
  }
  .xs-margin-top-bottom-five-percent {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
  }
  .xs-margin-top-bottom-six-percent {
    margin-top: 6% !important;
    margin-bottom: 6% !important;
  }
  .xs-margin-top-bottom-seven-percent {
    margin-top: 7% !important;
    margin-bottom: 7% !important;
  }
  .xs-margin-top-bottom-eight-percent {
    margin-top: 8% !important;
    margin-bottom: 8% !important;
  }
  .xs-margin-top-bottom-nine-percent {
    margin-top: 9% !important;
    margin-bottom: 9% !important;
  }
  .xs-margin-top-bottom-ten-percent {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .xs-margin-top-bottom-eleven-percent {
    margin-top: 11% !important;
    margin-bottom: 11% !important;
  }
  .xs-margin-top-bottom-twelve-percent {
    margin-top: 12% !important;
    margin-bottom: 12% !important;
  }
  .xs-margin-top-bottom-thirteen-percent {
    margin-top: 13% !important;
    margin-bottom: 13% !important;
  }
  .xs-margin-top-bottom-fourteen-percent {
    margin-top: 14% !important;
    margin-bottom: 14% !important;
  }
  .xs-margin-top-bottom-fifteen-percent {
    margin-top: 15% !important;
    margin-bottom: 15% !important;
  }
  .xs-margin-top-bottom-sixteen-percent {
    margin-top: 16% !important;
    margin-bottom: 16% !important;
  }
  .xs-margin-top-bottom-seventeen-percent {
    margin-top: 17% !important;
    margin-bottom: 17% !important;
  }
  .xs-margin-top-bottom-eighteen-percent {
    margin-top: 18% !important;
    margin-bottom: 18% !important;
  }
  .xs-margin-top-bottom-nineteen-percent {
    margin-top: 19% !important;
    margin-bottom: 19% !important;
  }
  .xs-margin-top-bottom-twenty-percent {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .xs-margin-top-bottom-twenty-one-percent {
    margin-top: 21% !important;
    margin-bottom: 21% !important;
  }
  .xs-margin-top-bottom-twenty-two-percent {
    margin-top: 22% !important;
    margin-bottom: 22% !important;
  }
  .xs-margin-top-bottom-twenty-three-percent {
    margin-top: 23% !important;
    margin-bottom: 23% !important;
  }
  .xs-margin-top-bottom-twenty-four-percent {
    margin-top: 24% !important;
    margin-bottom: 24% !important;
  }
  .xs-margin-top-bottom-twenty-five-percent {
    margin-top: 25% !important;
    margin-bottom: 25% !important;
  }
  .xs-margin-top-bottom-5px {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .xs-margin-top-bottom-10px {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .xs-margin-top-bottom-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .xs-margin-top-bottom-20px {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .xs-margin-top-bottom-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .xs-margin-top-bottom-30px {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .xs-margin-top-bottom-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .xs-margin-top-bottom-40px {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .xs-margin-top-bottom-45px {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .xs-margin-top-bottom-50px {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .xs-margin-top-bottom-55px {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .xs-margin-top-bottom-60px {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .xs-margin-top-bottom-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .xs-margin-top-bottom-70px {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .xs-margin-top-bottom-75px {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .xs-margin-top-bottom-80px {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .xs-margin-top-bottom-85px {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .xs-margin-top-bottom-90px {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .xs-margin-top-bottom-95px {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .xs-margin-top-bottom-100px {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .xs-margin-top-bottom-150px {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .xs-no-padding {
    padding: 0 !important;
  }
  .xs-no-padding-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .xs-no-padding-top-bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .xs-no-padding-top {
    padding-top: 0 !important;
  }
  .xs-no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .xs-no-padding-left {
    padding-left: 0 !important;
  }
  .xs-no-padding-right {
    padding-right: 0 !important;
  }
  .xs-padding-one-percent {
    padding: 1% !important;
  }
  .xs-padding-two-percent {
    padding: 2% !important;
  }
  .xs-padding-three-percent {
    padding: 3% !important;
  }
  .xs-padding-four-percent {
    padding: 4% !important;
  }
  .xs-padding-five-percent {
    padding: 5% !important;
  }
  .xs-padding-six-percent {
    padding: 6% !important;
  }
  .xs-padding-seven-percent {
    padding: 7% !important;
  }
  .xs-padding-eight-percent {
    padding: 8% !important;
  }
  .xs-padding-nine-percent {
    padding: 9% !important;
  }
  .xs-padding-ten-percent {
    padding: 10% !important;
  }
  .xs-padding-eleven-percent {
    padding: 11% !important;
  }
  .xs-padding-twelve-percent {
    padding: 12% !important;
  }
  .xs-padding-thirteen-percent {
    padding: 13% !important;
  }
  .xs-padding-fourteen-percent {
    padding: 14% !important;
  }
  .xs-padding-fifteen-percent {
    padding: 15% !important;
  }
  .xs-padding-sixteen-percent {
    padding: 16% !important;
  }
  .xs-padding-seventeen-percent {
    padding: 17% !important;
  }
  .xs-padding-eighteen-percent {
    padding: 18% !important;
  }
  .xs-padding-nineteen-percent {
    padding: 19% !important;
  }
  .xs-padding-twenty-percent {
    padding: 20% !important;
  }
  .xs-padding-twenty-one-percent {
    padding: 21% !important;
  }
  .xs-padding-twenty-two-percent {
    padding: 22% !important;
  }
  .xs-padding-twenty-three-percent {
    padding: 23% !important;
  }
  .xs-padding-twenty-four-percent {
    padding: 24% !important;
  }
  .xs-padding-twenty-five-percent {
    padding: 25% !important;
  }
  .xs-padding-5px {
    padding: 5px !important;
  }
  .xs-padding-10px {
    padding: 10px !important;
  }
  .xs-padding-15px {
    padding: 15px !important;
  }
  .xs-padding-20px {
    padding: 20px !important;
  }
  .xs-padding-25px {
    padding: 25px !important;
  }
  .xs-padding-30px {
    padding: 30px !important;
  }
  .xs-padding-35px {
    padding: 35px !important;
  }
  .xs-padding-40px {
    padding: 40px !important;
  }
  .xs-padding-45px {
    padding: 45px !important;
  }
  .xs-padding-50px {
    padding: 50px !important;
  }
  .xs-padding-55px {
    padding: 55px !important;
  }
  .xs-padding-60px {
    padding: 60px !important;
  }
  .xs-padding-65px {
    padding: 65px !important;
  }
  .xs-padding-70px {
    padding: 70px !important;
  }
  .xs-padding-75px {
    padding: 75px !important;
  }
  .xs-padding-80px {
    padding: 80px !important;
  }
  .xs-padding-85px {
    padding: 85px !important;
  }
  .xs-padding-90px {
    padding: 90px !important;
  }
  .xs-padding-95px {
    padding: 95px !important;
  }
  .xs-padding-100px {
    padding: 100px !important;
  }
  .xs-padding-150px {
    padding: 150px !important;
  }
  .xs-padding-top-one-percent {
    padding-top: 1% !important;
  }
  .xs-padding-top-two-percent {
    padding-top: 2% !important;
  }
  .xs-padding-top-three-percent {
    padding-top: 3% !important;
  }
  .xs-padding-top-four-percent {
    padding-top: 4% !important;
  }
  .xs-padding-top-five-percent {
    padding-top: 5% !important;
  }
  .xs-padding-top-six-percent {
    padding-top: 6% !important;
  }
  .xs-padding-top-seven-percent {
    padding-top: 7% !important;
  }
  .xs-padding-top-eight-percent {
    padding-top: 8% !important;
  }
  .xs-padding-top-nine-percent {
    padding-top: 9% !important;
  }
  .xs-padding-top-ten-percent {
    padding-top: 10% !important;
  }
  .xs-padding-top-eleven-percent {
    padding-top: 11% !important;
  }
  .xs-padding-top-twelve-percent {
    padding-top: 12% !important;
  }
  .xs-padding-top-thirteen-percent {
    padding-top: 13% !important;
  }
  .xs-padding-top-fourteen-percent {
    padding-top: 14% !important;
  }
  .xs-padding-top-fifteen-percent {
    padding-top: 15% !important;
  }
  .xs-padding-top-sixteen-percent {
    padding-top: 16% !important;
  }
  .xs-padding-top-seventeen-percent {
    padding-top: 17% !important;
  }
  .xs-padding-top-eighteen-percent {
    padding-top: 18% !important;
  }
  .xs-padding-top-nineteen-percent {
    padding-top: 19% !important;
  }
  .xs-padding-top-twenty-percent {
    padding-top: 20% !important;
  }
  .xs-padding-top-twenty-one-percent {
    padding-top: 21% !important;
  }
  .xs-padding-top-twenty-two-percent {
    padding-top: 22% !important;
  }
  .xs-padding-top-twenty-three-percent {
    padding-top: 23% !important;
  }
  .xs-padding-top-twenty-four-percent {
    padding-top: 24% !important;
  }
  .xs-padding-top-twenty-five-percent {
    padding-top: 25% !important;
  }
  .xs-padding-top-1px {
    padding-top: 1px !important;
  }
  .xs-padding-top-2px {
    padding-top: 2px !important;
  }
  .xs-padding-top-5px {
    padding-top: 5px !important;
  }
  .xs-padding-top-10px {
    padding-top: 10px !important;
  }
  .xs-padding-top-15px {
    padding-top: 15px !important;
  }
  .xs-padding-top-20px {
    padding-top: 20px !important;
  }
  .xs-padding-top-25px {
    padding-top: 25px !important;
  }
  .xs-padding-top-30px {
    padding-top: 30px !important;
  }
  .xs-padding-top-35px {
    padding-top: 35px !important;
  }
  .xs-padding-top-40px {
    padding-top: 40px !important;
  }
  .xs-padding-top-45px {
    padding-top: 45px !important;
  }
  .xs-padding-top-50px {
    padding-top: 50px !important;
  }
  .xs-padding-top-55px {
    padding-top: 55px !important;
  }
  .xs-padding-top-60px {
    padding-top: 60px !important;
  }
  .xs-padding-top-65px {
    padding-top: 65px !important;
  }
  .xs-padding-top-70px {
    padding-top: 70px !important;
  }
  .xs-padding-top-75px {
    padding-top: 75px !important;
  }
  .xs-padding-top-80px {
    padding-top: 80px !important;
  }
  .xs-padding-top-85px {
    padding-top: 85px !important;
  }
  .xs-padding-top-90px {
    padding-top: 90px !important;
  }
  .xs-padding-top-95px {
    padding-top: 95px !important;
  }
  .xs-padding-top-100px {
    padding-top: 100px !important;
  }
  .xs-padding-top-120px {
    padding-top: 120px !important;
  }
  .xs-padding-top-150px {
    padding-top: 150px !important;
  }
  .xs-padding-bottom-one-percent {
    padding-bottom: 1% !important;
  }
  .xs-padding-bottom-two-percent {
    padding-bottom: 2% !important;
  }
  .xs-padding-bottom-three-percent {
    padding-bottom: 3% !important;
  }
  .xs-padding-bottom-four-percent {
    padding-bottom: 4% !important;
  }
  .xs-padding-bottom-five-percent {
    padding-bottom: 5% !important;
  }
  .xs-padding-bottom-six-percent {
    padding-bottom: 6% !important;
  }
  .xs-padding-bottom-seven-percent {
    padding-bottom: 7% !important;
  }
  .xs-padding-bottom-eight-percent {
    padding-bottom: 8% !important;
  }
  .xs-padding-bottom-nine-percent {
    padding-bottom: 9% !important;
  }
  .xs-padding-bottom-ten-percent {
    padding-bottom: 10% !important;
  }
  .xs-padding-bottom-eleven-percent {
    padding-bottom: 11% !important;
  }
  .xs-padding-bottom-twelve-percent {
    padding-bottom: 12% !important;
  }
  .xs-padding-bottom-thirteen-percent {
    padding-bottom: 13% !important;
  }
  .xs-padding-bottom-fourteen-percent {
    padding-bottom: 14% !important;
  }
  .xs-padding-bottom-fifteen-percent {
    padding-bottom: 15% !important;
  }
  .xs-padding-bottom-sixteen-percent {
    padding-bottom: 16% !important;
  }
  .xs-padding-bottom-seventeen-percent {
    padding-bottom: 17% !important;
  }
  .xs-padding-bottom-eighteen-percent {
    padding-bottom: 18% !important;
  }
  .xs-padding-bottom-nineteen-percent {
    padding-bottom: 19% !important;
  }
  .xs-padding-bottom-twenty-percent {
    padding-bottom: 20% !important;
  }
  .xs-padding-bottom-twenty-one-percent {
    padding-bottom: 21% !important;
  }
  .xs-padding-bottom-twenty-two-percent {
    padding-bottom: 22% !important;
  }
  .xs-padding-bottom-twenty-three-percent {
    padding-bottom: 23% !important;
  }
  .xs-padding-bottom-twenty-four-percent {
    padding-bottom: 24% !important;
  }
  .xs-padding-bottom-twenty-five-percent {
    padding-bottom: 25% !important;
  }
  .xs-padding-bottom-5px {
    padding-bottom: 5px !important;
  }
  .xs-padding-bottom-10px {
    padding-bottom: 10px !important;
  }
  .xs-padding-bottom-15px {
    padding-bottom: 15px !important;
  }
  .xs-padding-bottom-20px {
    padding-bottom: 20px !important;
  }
  .xs-padding-bottom-25px {
    padding-bottom: 25px !important;
  }
  .xs-padding-bottom-30px {
    padding-bottom: 30px !important;
  }
  .xs-padding-bottom-35px {
    padding-bottom: 35px !important;
  }
  .xs-padding-bottom-40px {
    padding-bottom: 40px !important;
  }
  .xs-padding-bottom-45px {
    padding-bottom: 45px !important;
  }
  .xs-padding-bottom-50px {
    padding-bottom: 50px !important;
  }
  .xs-padding-bottom-55px {
    padding-bottom: 55px !important;
  }
  .xs-padding-bottom-60px {
    padding-bottom: 60px !important;
  }
  .xs-padding-bottom-65px {
    padding-bottom: 65px !important;
  }
  .xs-padding-bottom-70px {
    padding-bottom: 70px !important;
  }
  .xs-padding-bottom-75px {
    padding-bottom: 75px !important;
  }
  .xs-padding-bottom-80px {
    padding-bottom: 80px !important;
  }
  .xs-padding-bottom-85px {
    padding-bottom: 85px !important;
  }
  .xs-padding-bottom-90px {
    padding-bottom: 90px !important;
  }
  .xs-padding-bottom-95px {
    padding-bottom: 95px !important;
  }
  .xs-padding-bottom-100px {
    padding-bottom: 100px !important;
  }
  .xs-padding-bottom-120px {
    padding-bottom: 120px !important;
  }
  .xs-padding-bottom-150px {
    padding-bottom: 150px !important;
  }
  .xs-padding-bottom-200px {
    padding-bottom: 200px !important;
  }
  .xs-padding-bottom-250px {
    padding-bottom: 250px !important;
  }
  .xs-padding-right-one-percent {
    padding-right: 1% !important;
  }
  .xs-padding-right-two-percent {
    padding-right: 2% !important;
  }
  .xs-padding-right-three-percent {
    padding-right: 3% !important;
  }
  .xs-padding-right-four-percent {
    padding-right: 4% !important;
  }
  .xs-padding-right-five-percent {
    padding-right: 5% !important;
  }
  .xs-padding-right-six-percent {
    padding-right: 6% !important;
  }
  .xs-padding-right-seven-percent {
    padding-right: 7% !important;
  }
  .xs-padding-right-eight-percent {
    padding-right: 8% !important;
  }
  .xs-padding-right-nine-percent {
    padding-right: 9% !important;
  }
  .xs-padding-right-ten-percent {
    padding-right: 10% !important;
  }
  .xs-padding-right-eleven-percent {
    padding-right: 11% !important;
  }
  .xs-padding-right-twelve-percent {
    padding-right: 12% !important;
  }
  .xs-padding-right-thirteen-percent {
    padding-right: 13% !important;
  }
  .xs-padding-right-fourteen-percent {
    padding-right: 14% !important;
  }
  .xs-padding-right-fifteen-percent {
    padding-right: 15% !important;
  }
  .xs-padding-right-sixteen-percent {
    padding-right: 16% !important;
  }
  .xs-padding-right-seventeen-percent {
    padding-right: 17% !important;
  }
  .xs-padding-right-eighteen-percent {
    padding-right: 18% !important;
  }
  .xs-padding-right-nineteen-percent {
    padding-right: 19% !important;
  }
  .xs-padding-right-twenty-percent {
    padding-right: 20% !important;
  }
  .xs-padding-right-twenty-one-percent {
    padding-right: 21% !important;
  }
  .xs-padding-right-twenty-two-percent {
    padding-right: 22% !important;
  }
  .xs-padding-right-twenty-three-percent {
    padding-right: 23% !important;
  }
  .xs-padding-right-twenty-four-percent {
    padding-right: 24% !important;
  }
  .xs-padding-right-twenty-five-percent {
    padding-right: 25% !important;
  }
  .xs-padding-right-5px {
    padding-right: 5px !important;
  }
  .xs-padding-right-10px {
    padding-right: 10px !important;
  }
  .xs-padding-right-15px {
    padding-right: 15px !important;
  }
  .xs-padding-right-20px {
    padding-right: 20px !important;
  }
  .xs-padding-right-25px {
    padding-right: 25px !important;
  }
  .xs-padding-right-30px {
    padding-right: 30px !important;
  }
  .xs-padding-right-35px {
    padding-right: 35px !important;
  }
  .xs-padding-right-40px {
    padding-right: 40px !important;
  }
  .xs-padding-right-45px {
    padding-right: 45px !important;
  }
  .xs-padding-right-50px {
    padding-right: 50px !important;
  }
  .xs-padding-right-55px {
    padding-right: 55px !important;
  }
  .xs-padding-right-60px {
    padding-right: 60px !important;
  }
  .xs-padding-right-65px {
    padding-right: 65px !important;
  }
  .xs-padding-right-70px {
    padding-right: 70px !important;
  }
  .xs-padding-right-75px {
    padding-right: 75px !important;
  }
  .xs-padding-right-80px {
    padding-right: 80px !important;
  }
  .xs-padding-right-85px {
    padding-right: 85px !important;
  }
  .xs-padding-right-90px {
    padding-right: 90px !important;
  }
  .xs-padding-right-95px {
    padding-right: 95px !important;
  }
  .xs-padding-right-100px {
    padding-right: 100px !important;
  }
  .xs-padding-right-150px {
    padding-right: 150px !important;
  }
  .xs-padding-left-one-percent {
    padding-left: 1% !important;
  }
  .xs-padding-left-two-percent {
    padding-left: 2% !important;
  }
  .xs-padding-left-three-percent {
    padding-left: 3% !important;
  }
  .xs-padding-left-four-percent {
    padding-left: 4% !important;
  }
  .xs-padding-left-five-percent {
    padding-left: 5% !important;
  }
  .xs-padding-left-six-percent {
    padding-left: 6% !important;
  }
  .xs-padding-left-seven-percent {
    padding-left: 7% !important;
  }
  .xs-padding-left-eight-percent {
    padding-left: 8% !important;
  }
  .xs-padding-left-nine-percent {
    padding-left: 9% !important;
  }
  .xs-padding-left-ten-percent {
    padding-left: 10% !important;
  }
  .xs-padding-left-eleven-percent {
    padding-left: 11% !important;
  }
  .xs-padding-left-twelve-percent {
    padding-left: 12% !important;
  }
  .xs-padding-left-thirteen-percent {
    padding-left: 13% !important;
  }
  .xs-padding-left-fourteen-percent {
    padding-left: 14% !important;
  }
  .xs-padding-left-fifteen-percent {
    padding-left: 15% !important;
  }
  .xs-padding-left-sixteen-percent {
    padding-left: 16% !important;
  }
  .xs-padding-left-seventeen-percent {
    padding-left: 17% !important;
  }
  .xs-padding-left-eighteen-percent {
    padding-left: 18% !important;
  }
  .xs-padding-left-nineteen-percent {
    padding-left: 19% !important;
  }
  .xs-padding-left-twenty-percent {
    padding-left: 20% !important;
  }
  .xs-padding-left-twenty-one-percent {
    padding-left: 21% !important;
  }
  .xs-padding-left-twenty-two-percent {
    padding-left: 22% !important;
  }
  .xs-padding-left-twenty-three-percent {
    padding-left: 23% !important;
  }
  .xs-padding-left-twenty-four-percent {
    padding-left: 24% !important;
  }
  .xs-padding-left-twenty-five-percent {
    padding-left: 25% !important;
  }
  .xs-padding-left-5px {
    padding-left: 5px !important;
  }
  .xs-padding-left-10px {
    padding-left: 10px !important;
  }
  .xs-padding-left-15px {
    padding-left: 15px !important;
  }
  .xs-padding-left-20px {
    padding-left: 20px !important;
  }
  .xs-padding-left-25px {
    padding-left: 25px !important;
  }
  .xs-padding-left-30px {
    padding-left: 30px !important;
  }
  .xs-padding-left-35px {
    padding-left: 35px !important;
  }
  .xs-padding-left-40px {
    padding-left: 40px !important;
  }
  .xs-padding-left-45px {
    padding-left: 45px !important;
  }
  .xs-padding-left-50px {
    padding-left: 50px !important;
  }
  .xs-padding-left-55px {
    padding-left: 55px !important;
  }
  .xs-padding-left-60px {
    padding-left: 60px !important;
  }
  .xs-padding-left-65px {
    padding-left: 65px !important;
  }
  .xs-padding-left-70px {
    padding-left: 70px !important;
  }
  .xs-padding-left-75px {
    padding-left: 75px !important;
  }
  .xs-padding-left-80px {
    padding-left: 80px !important;
  }
  .xs-padding-left-85px {
    padding-left: 85px !important;
  }
  .xs-padding-left-90px {
    padding-left: 90px !important;
  }
  .xs-padding-left-95px {
    padding-left: 95px !important;
  }
  .xs-padding-left-100px {
    padding-left: 100px !important;
  }
  .xs-padding-left-150px {
    padding-left: 150px !important;
  }
  .xs-padding-top-bottom-one-percent {
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
  .xs-padding-top-bottom-two-percent {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }
  .xs-padding-top-bottom-three-percent {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
  }
  .xs-padding-top-bottom-four-percent {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
  }
  .xs-padding-top-bottom-five-percent {
    padding-top: 5% !important;
    padding-bottom: 5% !important;
  }
  .xs-padding-top-bottom-six-percent {
    padding-top: 6% !important;
    padding-bottom: 6% !important;
  }
  .xs-padding-top-bottom-seven-percent {
    padding-top: 7% !important;
    padding-bottom: 7% !important;
  }
  .xs-padding-top-bottom-eight-percent {
    padding-top: 8% !important;
    padding-bottom: 8% !important;
  }
  .xs-padding-top-bottom-nine-percent {
    padding-top: 9% !important;
    padding-bottom: 9% !important;
  }
  .xs-padding-top-bottom-ten-percent {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .xs-padding-top-bottom-eleven-percent {
    padding-top: 11% !important;
    padding-bottom: 11% !important;
  }
  .xs-padding-top-bottom-twelve-percent {
    padding-top: 12% !important;
    padding-bottom: 12% !important;
  }
  .xs-padding-top-bottom-thirteen-percent {
    padding-top: 13% !important;
    padding-bottom: 13% !important;
  }
  .xs-padding-top-bottom-fourteen-percent {
    padding-top: 14% !important;
    padding-bottom: 14% !important;
  }
  .xs-padding-top-bottom-fifteen-percent {
    padding-top: 15% !important;
    padding-bottom: 15% !important;
  }
  .xs-padding-top-bottom-sixteen-percent {
    padding-top: 16% !important;
    padding-bottom: 16% !important;
  }
  .xs-padding-top-bottom-seventeen-percent {
    padding-top: 17% !important;
    padding-bottom: 17% !important;
  }
  .xs-padding-top-bottom-eighteen-percent {
    padding-top: 18% !important;
    padding-bottom: 18% !important;
  }
  .xs-padding-top-bottom-nineteen-percent {
    padding-top: 19% !important;
    padding-bottom: 19% !important;
  }
  .xs-padding-top-bottom-twenty-percent {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .xs-padding-top-bottom-twenty-one-percent {
    padding-top: 21% !important;
    padding-bottom: 21% !important;
  }
  .xs-padding-top-bottom-twenty-two-percent {
    padding-top: 22% !important;
    padding-bottom: 22% !important;
  }
  .xs-padding-top-bottom-twenty-three-percent {
    padding-top: 23% !important;
    padding-bottom: 23% !important;
  }
  .xs-padding-top-bottom-twenty-four-percent {
    padding-top: 24% !important;
    padding-bottom: 24% !important;
  }
  .xs-padding-top-bottom-twenty-five-percent {
    padding-top: 25% !important;
    padding-bottom: 25% !important;
  }
  .xs-padding-top-bottom-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xs-padding-top-bottom-10px {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xs-padding-top-bottom-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xs-padding-top-bottom-20px {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xs-padding-top-bottom-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xs-padding-top-bottom-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xs-padding-top-bottom-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xs-padding-top-bottom-40px {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xs-padding-top-bottom-45px {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xs-padding-top-bottom-50px {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xs-padding-top-bottom-55px {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .xs-padding-top-bottom-60px {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xs-padding-top-bottom-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .xs-padding-top-bottom-70px {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .xs-padding-top-bottom-75px {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .xs-padding-top-bottom-80px {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xs-padding-top-bottom-85px {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .xs-padding-top-bottom-90px {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xs-padding-top-bottom-95px {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .xs-padding-top-bottom-100px {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .xs-padding-top-bottom-120px {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .xs-padding-top-bottom-150px {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .xs-padding-top-bottom-200px {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .xs-padding-top-bottom-250px {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .xs-padding-top-bottom-300px {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .xs-padding-left-right-one-percent {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
  .xs-padding-left-right-two-percent {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .xs-padding-left-right-three-percent {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
  .xs-padding-left-right-four-percent {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
  .xs-padding-left-right-five-percent {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .xs-padding-left-right-six-percent {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .xs-padding-left-right-seven-percent {
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
  .xs-padding-left-right-eight-percent {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  .xs-padding-left-right-nine-percent {
    padding-left: 9% !important;
    padding-right: 9% !important;
  }
  .xs-padding-left-right-ten-percent {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .xs-padding-left-right-eleven-percent {
    padding-left: 11% !important;
    padding-right: 11% !important;
  }
  .xs-padding-left-right-twelve-percent {
    padding-left: 12% !important;
    padding-right: 12% !important;
  }
  .xs-padding-left-right-thirteen-percent {
    padding-left: 13% !important;
    padding-right: 13% !important;
  }
  .xs-padding-left-right-fourteen-percent {
    padding-left: 14% !important;
    padding-right: 14% !important;
  }
  .xs-padding-left-right-fifteen-percent {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .xs-padding-left-right-sixteen-percent {
    padding-left: 16% !important;
    padding-right: 16% !important;
  }
  .xs-padding-left-right-seventeen-percent {
    padding-left: 17% !important;
    padding-right: 17% !important;
  }
  .xs-padding-left-right-eighteen-percent {
    padding-left: 18% !important;
    padding-right: 18% !important;
  }
  .xs-padding-left-right-nineteen-percent {
    padding-left: 19% !important;
    padding-right: 19% !important;
  }
  .xs-padding-left-right-twenty-percent {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .xs-padding-left-right-twenty-one-percent {
    padding-left: 21% !important;
    padding-right: 21% !important;
  }
  .xs-padding-left-right-twenty-two-percent {
    padding-left: 22% !important;
    padding-right: 22% !important;
  }
  .xs-padding-left-right-twenty-three-percent {
    padding-left: 23% !important;
    padding-right: 23% !important;
  }
  .xs-padding-left-right-twenty-four-percent {
    padding-left: 24% !important;
    padding-right: 24% !important;
  }
  .xs-padding-left-right-twenty-five-percent {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }
  .xs-padding-left-right-5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xs-padding-left-right-10px {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xs-padding-left-right-15px {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xs-padding-left-right-20px {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xs-padding-left-right-25px {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xs-padding-left-right-30px {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xs-padding-left-right-35px {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xs-padding-left-right-40px {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xs-padding-left-right-45px {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xs-padding-left-right-50px {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xs-padding-left-right-55px {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .xs-padding-left-right-60px {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xs-padding-left-right-65px {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .xs-padding-left-right-70px {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .xs-padding-left-right-75px {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .xs-padding-left-right-80px {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xs-padding-left-right-85px {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .xs-padding-left-right-90px {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xs-padding-left-right-95px {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .xs-padding-left-right-100px {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .xs-padding-left-right-120px {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .xs-padding-left-right-150px {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .xs-width-1px {
    width: 1px !important;
  }
  .xs-width-2px {
    width: 2px !important;
  }
  .xs-width-3px {
    width: 3px !important;
  }
  .xs-width-4px {
    width: 4px !important;
  }
  .xs-width-5px {
    width: 5px !important;
  }
  .xs-width-6px {
    width: 6px !important;
  }
  .xs-width-7px {
    width: 7px !important;
  }
  .xs-width-8px {
    width: 8px !important;
  }
  .xs-width-9px {
    width: 9px !important;
  }
  .xs-width-10px {
    width: 10px !important;
  }
  .xs-width-15px {
    width: 15px !important;
  }
  .xs-width-20px {
    width: 20px !important;
  }
  .xs-width-25px {
    width: 25px !important;
  }
  .xs-width-30px {
    width: 30px !important;
  }
  .xs-width-35px {
    width: 35px !important;
  }
  .xs-width-40px {
    width: 40px !important;
  }
  .xs-width-45px {
    width: 45px !important;
  }
  .xs-width-50px {
    width: 50px !important;
  }
  .xs-width-55px {
    width: 55px !important;
  }
  .xs-width-60px {
    width: 60px !important;
  }
  .xs-width-65px {
    width: 65px !important;
  }
  .xs-width-70px {
    width: 70px !important;
  }
  .xs-width-75px {
    width: 75px !important;
  }
  .xs-width-80px {
    width: 80px !important;
  }
  .xs-width-85px {
    width: 85px !important;
  }
  .xs-width-90px {
    width: 90px !important;
  }
  .xs-width-100px {
    width: 100px !important;
  }
  .xs-width-110px {
    width: 110px !important;
  }
  .xs-width-120px {
    width: 120px !important;
  }
  .xs-width-130px {
    width: 130px !important;
  }
  .xs-width-140px {
    width: 140px !important;
  }
  .xs-width-150px {
    width: 150px !important;
  }
  .xs-width-160px {
    width: 160px !important;
  }
  .xs-width-170px {
    width: 170px !important;
  }
  .xs-width-180px {
    width: 180px !important;
  }
  .xs-width-190px {
    width: 190px !important;
  }
  .xs-width-200px {
    width: 200px !important;
  }
  .xs-width-250px {
    width: 250px !important;
  }
  .xs-width-300px {
    width: 300px !important;
  }
  .xs-width-350px {
    width: 350px !important;
  }
  .xs-width-400px {
    width: 400px !important;
  }
  .xs-width-450px {
    width: 450px !important;
  }
  .xs-width-500px {
    width: 500px !important;
  }
  .xs-width-550px {
    width: 550px !important;
  }
  .xs-width-600px {
    width: 600px !important;
  }
  .xs-width-650px {
    width: 650px !important;
  }
  .xs-width-700px {
    width: 700px !important;
  }
  .xs-width-750px {
    width: 750px !important;
  }
  .xs-width-800px {
    width: 800px !important;
  }
  .xs-width-850px {
    width: 850px !important;
  }
  .xs-width-900px {
    width: 900px !important;
  }
  .xs-width-950px {
    width: 950px !important;
  }
  .xs-width-1000px {
    width: 1000px !important;
  }
  .xs-width-5 {
    width: 5% !important;
  }
  .xs-width-10 {
    width: 10% !important;
  }
  .xs-width-12 {
    width: 12% !important;
  }
  .xs-width-15 {
    width: 15% !important;
  }
  .xs-width-20 {
    width: 20% !important;
  }
  .xs-width-25 {
    width: 25% !important;
  }
  .xs-width-30 {
    width: 30% !important;
  }
  .xs-width-35 {
    width: 35% !important;
  }
  .xs-width-40 {
    width: 40% !important;
  }
  .xs-width-45 {
    width: 45% !important;
  }
  .xs-width-50 {
    width: 50% !important;
  }
  .xs-width-55 {
    width: 55% !important;
  }
  .xs-width-60 {
    width: 60% !important;
  }
  .xs-width-65 {
    width: 65% !important;
  }
  .xs-width-70 {
    width: 70% !important;
  }
  .xs-width-75 {
    width: 75% !important;
  }
  .xs-width-80 {
    width: 80% !important;
  }
  .xs-width-85 {
    width: 85% !important;
  }
  .xs-width-90 {
    width: 90% !important;
  }
  .xs-width-95 {
    width: 95% !important;
  }
  .xs-width-100 {
    width: 100% !important;
  }
  .xs-width-auto {
    width: auto !important;
  }
  .xs-height-1px {
    height: 1px !important;
  }
  .xs-height-2px {
    height: 2px !important;
  }
  .xs-height-3px {
    height: 3px !important;
  }
  .xs-height-4px {
    height: 4px !important;
  }
  .xs-height-5px {
    height: 5px !important;
  }
  .xs-height-6px {
    height: 6px !important;
  }
  .xs-height-7px {
    height: 7px !important;
  }
  .xs-height-8px {
    height: 8px !important;
  }
  .xs-height-9px {
    height: 9px !important;
  }
  .xs-height-5px {
    height: 5px !important;
  }
  .xs-height-10px {
    height: 10px !important;
  }
  .xs-height-15px {
    height: 15px !important;
  }
  .xs-height-20px {
    height: 20px !important;
  }
  .xs-height-25px {
    height: 25px !important;
  }
  .xs-height-30px {
    height: 30px !important;
  }
  .xs-height-35px {
    height: 30px !important;
  }
  .xs-height-40px {
    height: 40px !important;
  }
  .xs-height-45px {
    height: 45px !important;
  }
  .xs-height-50px {
    height: 50px !important;
  }
  .xs-height-55px {
    height: 55px !important;
  }
  .xs-height-60px {
    height: 60px !important;
  }
  .xs-height-65px {
    height: 60px !important;
  }
  .xs-height-70px {
    height: 70px !important;
  }
  .xs-height-75px {
    height: 70px !important;
  }
  .xs-height-80px {
    height: 80px !important;
  }
  .xs-height-85px {
    height: 80px !important;
  }
  .xs-height-90px {
    height: 90px !important;
  }
  .xs-height-95px {
    height: 90px !important;
  }
  .xs-height-100px {
    height: 100px !important;
  }
  .xs-height-110px {
    height: 110px !important;
  }
  .xs-height-120px {
    height: 120px !important;
  }
  .xs-height-130px {
    height: 130px !important;
  }
  .xs-height-140px {
    height: 130px !important;
  }
  .xs-height-150px {
    height: 150px !important;
  }
  .xs-height-160px {
    height: 160px !important;
  }
  .xs-height-170px {
    height: 170px !important;
  }
  .xs-height-180px {
    height: 180px !important;
  }
  .xs-height-190px {
    height: 190px !important;
  }
  .xs-height-200px {
    height: 200px !important;
  }
  .xs-height-250px {
    height: 250px !important;
  }
  .xs-height-300px {
    height: 300px !important;
  }
  .xs-height-350px {
    height: 350px !important;
  }
  .xs-height-400px {
    height: 400px !important;
  }
  .xs-height-450px {
    height: 450px !important;
  }
  .xs-height-500px {
    height: 500px !important;
  }
  .xs-height-550px {
    height: 550px !important;
  }
  .xs-height-600px {
    height: 600px !important;
  }
  .xs-height-650px {
    height: 650px !important;
  }
  .xs-height-700px {
    height: 700px !important;
  }
  .xs-height-10 {
    height: 10% !important;
  }
  .xs-height-20 {
    height: 20% !important;
  }
  .xs-height-30 {
    height: 30% !important;
  }
  .xs-height-40 {
    height: 40% !important;
  }
  .xs-height-50 {
    height: 50% !important;
  }
  .xs-height-55 {
    height: 55% !important;
  }
  .xs-height-60 {
    height: 60% !important;
  }
  .xs-height-70 {
    height: 70% !important;
  }
  .xs-height-80 {
    height: 80% !important;
  }
  .xs-height-90 {
    height: 90% !important;
  }
  .xs-height-100 {
    height: 100% !important;
  }
  .xs-height-full {
    height: 100vh !important;
  }
  .xs-height-auto {
    height: auto !important;
  }
  .xs-max-height-100 {
    max-height: 100% !important;
  }
  .xs-full-screen {
    min-height: 980px !important;
  }
}

@media all and (max-width: 400px) {
  .first-col {
    order: 13;
  }

  .uk-inline-clip-custom > img {
    max-width: 50%;
    margin-left: 25%;
    align-self: center !important;
  }

  .loader-img img {
    left: 23%;
    bottom: 45%;
  }
}
