@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

/*
// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
// Small devices (landscape phones, 576px and up) sm
// Medium devices (tablets, 768px and up) md
// Large devices (desktops, 992px and up) lg
// Extra large devices (large desktops, 1200px and up) xl
*/

a:focus,
button:focus,
input:focus,
textarea:focus,
audio:focus {
  outline: none;
  box-shadow: none;
}

button:focus,
button:active,
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}

$color-blue: #91d3e1;
$color-blue-dark: #6fafbc;

// em {
//   color: $color-blue;
// }

.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: $color-blue;
}

.uk-radio:focus,
.uk-checkbox:focus {
  border-color: $color-blue-dark;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  border-color: $color-blue-dark;
}

.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: $color-blue-dark;
}

.image-main {
  width: 100%;
  object-fit: cover;
}

.uk-card-success {
  background: #e0faea;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

audio,
[data-slate-void],
.default blockquote {
  margin: 15px 0;
}

.uk-offcanvas-bar .uk-input::placeholder,
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #acacac;
  opacity: 1; /* Firefox */
}

.uk-offcanvas-bar .uk-input::placeholder,
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #acacac;
}

.uk-offcanvas-bar .uk-input::placeholder,
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #acacac;
}

p[data-slate-node='element'] {
  blockquote:not(:first-child):not(:last-child) {
    padding: 0 25px;
  }
  blockquote:first-child {
    padding: 50px 25px 0;
  }
  blockquote:last-child {
    padding: 0 25px 50px;
  }
}

.default {
  .first-letter::first-letter {
    font-size: 85px;
    text-transform: uppercase;
    margin: 0 10px 0 0;
    float: left;
    text-align: center;
    padding: 20px 0;
  }
  .first-letter::after {
    content: '';
    clear: both;
  }
}

.m-messenger__conversation-new-messages-amount-custom-navbar {
  font-size: 0.8rem;
  padding: 0 5px;
  border-radius: 8px;
  background-color: #dc3545;
  color: #fff;
  height: 10px;
  line-height: 16px;
  font-weight: normal;
  position: absolute;
  top: 10px;
  right: 10px;
}

.m-messenger__conversation-new-messages-amount-custom {
  font-size: 0.8rem;
  padding: 0 5px;
  border-radius: 8px;
  background-color: #dc3545;
  color: #fff;
  height: 10px;
  /* line-height: 16px; */
  font-weight: normal;
  position: absolute;
  top: 25px;
  left: 35px;
}

.m-messenger {
  .sender-link {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
  }
  .m-messenger__container {
    .m-messenger__discussions {
      .m-messenger__discussions-scrollpane {
        position: relative;
      }
    }
    .m-messenger__messages {
      .m-messenger__messages-header {
        position: sticky;
        top: 50px;
        z-index: 1;
        background: #fff;

        .m-messenger__messages-to {
          a {
            color: $color-blue;
            border-bottom: 1px dashed rgba(145, 211, 225, 0.5);
          }
        }
      }

      .m-messenger__messages-body {
        .m-messenger__messages-message {
          [data-rmiz-wrap] {
            max-height: 100%;
          }
          .quote-message {
            margin-top: 8px;
            background: #fff !important;
            padding-left: 5px;
            border-radius: 0 20px 20px 0;
            border-left: 2px solid #455161;

            .quote-message-sender {
              color: $color-blue;
              font-size: 0.8rem;
              font-weight: bold;
              line-height: 1rem;
            }

            .quote-message-message {
              color: #939daa;
              font-size: 0.75rem;
            }
          }
        }

        .is-interlocutor {
          .m-messenger__messages-message-text,
          .m-messenger__messages-message-text:before {
            background: $color-blue;
          }
        }
      }

      .m-messenger__messages-footer {
        position: sticky;
        bottom: 0;
        background: #fff;
        z-index: 1000;
        margin-bottom: 0;

        textarea {
          margin-bottom: 0;
          font-size: 1rem;
        }

        .btn-info {
          background-color: $color-blue;
        }

        .messages-quote {
          padding-left: 0.5rem !important;
          margin-bottom: 0.5rem !important;
          margin-top: 0.5rem !important;
          border-left: 2px solid $color-blue !important;

          .clear-quote {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 0.8rem;
            color: #495057;
          }
        }
      }
    }
  }

  .m-messenger__conversation-message {
    overflow-x: hidden;
    height: 22px;
  }

  .m-messenger__contacts-search {
    height: fit-content;
    padding: 5px 15px;

    .m-messenger__contacts-filter {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .btn {
      border: 1px solid #b3b3b3;
    }
  }

  .js-scrollable {
    overflow-y: scroll;
  }
}

.service-managing {
  .uk-card {
    margin: 10px 0;
    height: 150px;
  }
}

.rhap_container {
  margin: 15px 0;
}

@include media-breakpoint-up(md) {
  .m-messenger {
    max-height: calc(100vh - 121px);
    .m-messenger__messages,
    .m-messenger__discussions {
      max-height: calc(100vh - 121px);
    }

    .m-messenger__messages-header {
      top: 81px;
    }

    .m-messenger__discussions-scrollpane {
      // height: calc(100vh - 182px);
      // max-height: calc(100vh - 182px);
    }
  }
}

@include media-breakpoint-down(md) {
  .uk-navbar-nav > li > a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    min-height: 50px;
  }

  .uk-modal-body {
    padding: 15px;
  }

  .sm-no-margin-bottom {
    margin-bottom: 0 !important;
  }

  .m-messenger {
    max-height: calc(100vh - 90px);

    .m-messenger__messages {
      max-height: calc(100vh - 90px);
    }

    .m-messenger__discussions {
      flex-grow: 1;
    }

    .m-messenger__discussions-scrollpane-contacts {
      height: calc(100vh - 215px);
      max-height: calc(100vh - 215px);
    }

    .m-messenger__discussions-header {
      height: 70px;
      min-height: 70px;
      margin: 0;
      padding: 0;

      .nav-link {
        padding: 0 1rem;
      }
    }

    .m-messenger__conversation {
      padding: 10px 15px;
      font-size: 14px;
    }

    .m-messenger__messages {
      border: 0;
    }
    .m-messenger__messages-header {
      padding: 5px 0;
      height: 45px;
      min-height: 40px;
      justify-content: flex-start;
      .m-messenger__messages-to {
        margin-left: 15px;
        a {
          color: $color-blue;
        }
      }
    }
    .m-messenger__messages-body {
      height: unset;
      max-height: 100%;
      .m-messenger__messages-message {
        padding: 0;
      }
      .m-messenger__messages-message:first-child {
        margin-top: 15px;
        background-color: red;
      }
      .m-messenger__messages-message-text {
        font-size: 13px;
        line-height: 25px;
      }
    }

    .m-messenger__messages-footer {
      max-height: 200px;

      padding: 5px 0;
      .p-2 {
        padding-left: 0 !important;
      }
      textarea {
        font-size: 0.875rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  footer {
    // span::before {
    //   content: '\A';
    //   white-space: pre;
    // }
    & > div.padding-top-bottom-10px {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .text-small {
      font-size: 11px;
      line-height: 15px;
    }
    .text-small-custom {
      font-size: 15px;
    }
  }
  .m-messenger {
    // height: calc(100vh - 111px);
    // max-height: calc(100vh - 111px);

    // .m-messenger__discussions-scrollpane {
    //   height: calc(100vh - 182px);
    //   max-height: calc(100vh - 182px);
    // }
    //
    // .m-messenger__discussions-scrollpane-contacts {
    //   height: calc(100vh - 235px);
    //   max-height: calc(100vh - 235px);
    // }
    .m-messenger__container {
      .m-messenger__messages {
        .m-messenger__messages-body {
          .m-messenger__messages-message-text {
            font-size: 13px;
            line-height: 20px;
          }
        }
        .m-messenger__messages-footer {
          textarea {
            font-size: 0.875rem;
            line-height: 1.2;
          }
        }
      }
    }
  }
}

.basic-single {
  & > .select-with-search-prefix__menu {
    z-index: 10 !important;
  }
  .select-with-search-prefix__menu-list {
    position: relative;
    z-index: 10 !important;

    .select-with-search-prefix__option {
      position: relative;
      z-index: 10 !important;
    }
  }
}

@media (min-width: 1200px) {
  .uk-card-body {
    padding: 20px 20px;
  }
}

.navbar-custom-user {
  border-bottom: 1px solid #f3f3f3;
  box-shadow: none;
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;

  .nav-logo {
    padding: 0 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #232222;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-decoration: none;
  }

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    padding: 0 15px;
    transition: 0.1s ease-in-out;
    transition-property: color, background-color;
    // font-family: Roboto;
    color: #717171;
    font-size: 14px;
    font-weight: 400;
  }

  .dropdown-menu-custom {
    box-sizing: border-box;
    min-width: 200px;
    padding: 25px;
    background-color: #fff;
    color: #666;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    border: none;

    ul {
      list-style: none;

      .dropdown-item {
        font-size: 13px;
        font-weight: 400;
        color: #212121;
        padding: 5px 0;
        display: block;
        text-decoration: none;
        outline: none;
      }
    }
  }
}

.dropdown-menu-auth {
  min-width: 270px;
  width: max-content;
  box-sizing: border-box;
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
  border: none;
}

.sticky-placeholder {
  height: 81px;
  margin: 0;
}

@media (max-width: 768px) {
  .navbar-custom-user.navbar-expand-md {
    .nav-link {
      min-height: 45px;
    }
  }
}

@media (min-width: 768px) {
  .navbar-custom-user.navbar-expand-md {
    .navbar-nav {
      min-height: 80px;

      .nav-link {
        padding-right: 15px;
        padding-left: 15px;
      }

      .nav-link.custom-nav-class {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: 5px;
        margin-left: 5px;
        background-color: $color-blue;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .sticky-placeholder {
    height: 51px;
    margin: 0;
  }
  .navbar-toggler {
    min-height: 50px;
  }

  .offcanvas-collapse {
    position: fixed;
    top: 51px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    // padding-right: 1rem;
    // padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #fff;
    transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;

    .m-messenger__conversation-new-messages-amount-custom {
      font-size: 0.8rem;
      padding: 0 5px;
      border-radius: 8px;
      background-color: #dc3545;
      color: #fff;
      height: 10px;
      /* line-height: 16px; */
      font-weight: normal;
      position: absolute;
      top: 0px;
      left: 25px;
    }
  }
  .offcanvas-collapse.open {
    visibility: visible;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);

    .dropdown-menu-custom {
      padding: 5px 0 5px 15px !important;
      box-shadow: none;
    }

    .dropdown-toggle.nav-link {
      padding: 0 !important;
    }

    .dropdown.nav-item {
      margin-bottom: 20px;
    }

    .navbar-nav {
      margin-top: 25px;
      margin-left: 8px !important;
      padding: 0;
      list-style: none;

      .nav-link {
        font-family: Play, sans-serif;
        margin-bottom: 10px;
        font-size: 0.875rem !important;
        text-align: left !important;
        min-height: unset;
        padding: 0;
        padding-bottom: 20px;
        display: block !important;
      }
    }
  }
}

.nav-item {
  position: relative;
}
