/* html,
body {
  height: 100%;
} */
.uk-offcanvas-bar .uk-button-secondary,
.uk-offcanvas-bar .uk-button-default {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.uk-offcanvas-bar .uk-button-secondary,
.uk-offcanvas-bar .uk-button-default {
  background-color: #fff;
  color: #666;
}
.uk-offcanvas-bar .uk-input,
.uk-offcanvas-bar .uk-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #222;
}
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px;
}
.uk-offcanvas-container-animation .react-notification-root {
  left: 0;
}
.uk-navbar-nav > li > a {
  text-transform: none;
  font-weight: 400;
}
.uk-logo {
  font-size: 1rem;
  font-weight: bold;
  color: #232222;
}
a.btn-black:not([href]) {
  color: #fff;
  text-decoration: none;
}

.preview-h1 {
  font-size: 75px;
  line-height: 1.1;
}

/* h2 {
  font-size: 1.8em;
  margin: 0.67em 0;
} */

.uk-margin-small-custom {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  overflow: visible;
  text-align: inherit;
  border: 0;
  border-top: 1px solid #e5e5e5;
}
[data-oembed-url] {
  text-align: center;
  width: 100%;
}

.uk-tab > .uk-active > a {
  color: #6f6f6f;
  border-color: #626262;
}

.default .embed-responsive {
  margin: 30px 0;
}

.default blockquote {
  border-left: 1px solid;
  padding: 50px 25px;
  line-height: 1;
  margin: 45px 0;
  font-style: normal;
  color: #232323;
  border-width: 2px;
  border-color: #000;
}

.default blockquote p {
  margin-bottom: 25px;
  line-height: 1.5em;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 25px;
  margin-bottom: 15px !important;
}

.custom-overflow {
  overflow: hidden;
}

.custom-overflow .uk-dropdown {
  position: absolute;
}

.custom-day {
  font-size: 1.5rem;
}

h3.vertical-timeline-element-title {
  font-size: 48px;
  line-height: 1.2;
}

h4.vertical-timeline-element-subtitle {
  font-size: 30px;
  line-height: 1.2;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #000, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.vertical-timeline-element-text {
  color: #fff;
}
.vertical-timeline-element-text a {
  color: #4d4d4d;
}
.vertical-timeline-element-text a:hover {
  color: #000;
}
.vertical-timeline-element-content {
  border-top: 3px solid #000;
}
.vertical-timeline {
  min-height: calc(100vh - 121px);
}
.image-bg-account {
  background: url('../constants/images/bg-account.jpeg');
}
.select-datePicker {
  width: fit-content;
}
.uk-offcanvas-bar .custom li > a.mobile {
  color: #999;
}
.uk-offcanvas-bar .custom li.uk-active > a.mobile {
  color: #000;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  background-color: #15616d;
  border-color: #15616d;
}

.btn-blue {
  background: #91d3e1 !important;
  border-color: #91d3e1 !important;
  color: #fff;
}

.btn-blue:hover,
.btn-blue:focus {
  background: transparent !important;
  color: #278ba0 !important;
  border-color: #278ba0 !important;
}
