@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'colors';

$line-height-extra-lg: 2 !default;
$border-radius-extra-lg: 0.2rem !default;
$font-size-extra-lg: $font-size-base * 1.1 !default;
$input-btn-padding-y-extra-lg: 0.5rem !default;
$input-btn-padding-x-extra-lg: 1rem !default;
$input-btn-font-size-extra-lg: $font-size-extra-lg !default;
$input-btn-line-height-extra-lg: $line-height-extra-lg !default;
$input-padding-y-extra-lg: $input-btn-padding-y-extra-lg !default;
$input-padding-x-extra-lg: $input-btn-padding-x-extra-lg !default;
$input-font-size-extra-lg: $input-btn-font-size-extra-lg !default;
$input-line-height-extra-lg: $input-btn-line-height-extra-lg !default;
$input-border-radius-extra-lg: $border-radius-extra-lg !default;
$input-height-extra-lg: add(
  $input-line-height-extra-lg * 1em,
  add($input-padding-y-extra-lg * 2, $input-height-border, false)
) !default;

.vertical-timeline {
  &::before {
    background: #000 !important;
  }
}

.help-text {
  color: $primary-color-dark;
  font-size: 1.1rem;
  a {
    color: $primary-color-dark;
  }
}

.btn-kirkova {
  @include button-variant(
    $accent-color,
    $accent-color-border,
    darken($accent-color, 7.5%),
    darken($accent-color-border, 10%),
    darken($accent-color, 10%),
    darken($accent-color-border, 12.5%)
  );
  @include border-radius($input-border-radius-extra-lg);
  // @include font-size($input-font-size-extra-lg);
}

p:empty {
  min-height: 1px;
}

.btn-kirkova-white {
  @include button-variant(
    $primary-color-text,
    $secondary-text-color,
    darken($primary-color-text, 7.5%),
    darken($secondary-text-color, 10%),
    darken($primary-color-text, 10%),
    darken($secondary-text-color, 12.5%)
  );
  @include border-radius($input-border-radius-extra-lg);
  // @include font-size($input-font-size-extra-lg);
}

.form-control-extra-large {
  height: $input-height-extra-lg;
  padding: $input-padding-y-extra-lg $input-padding-x-extra-lg;
  @include font-size($input-font-size-extra-lg);
  line-height: $input-line-height-extra-lg;
  @include border-radius($input-border-radius-extra-lg);
}

.navbar-custom-user {
  // background: $primary-color;
  .nav-logo {
    font-size: 1.3rem;
    color: $primary-text-color;
  }
  .nav-link {
    font-size: 1rem;
    color: $primary-text-color;
  }
  .btn-black {
    background: $primary-text-color !important;
    border-color: $primary-text-color !important;
    color: $primary-color-text;
    &:hover {
      color: $primary-color-text !important;
    }
  }
  .btn-small {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 5px;
  }
}

.video-youtube {
  top: 20px;
}

.navbar-transparent {
  .navbar-custom-user {
    background: rgba(255, 255, 255, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0);

    .nav-logo {
      font-size: 1.3rem;
      color: $primary-color-text;
    }
    .nav-link {
      font-size: 1rem;
      color: $primary-color-text;
    }
    .btn-black {
      background: $primary-color-text !important;
      border-color: $primary-color-text !important;
      color: $secondary-text-color;
      &:hover {
        color: $secondary-text-color !important;
      }
    }
  }
  .sticky-placeholder {
    height: unset;
  }
  [data-icon='bars'] {
    color: $primary-color-text;
  }
}

.accent-color {
  color: $accent-color;
}

.absolute-container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;

  video {
    object-fit: cover;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
  }

  .image-main-bg {
    object-position: 0;
    object-fit: cover;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
  }

  .video-overlay {
    &:before {
      content: '';
      object-fit: cover;
      position: absolute;
      width: 100vw;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .image-overlay-second {
    &:before {
      content: '';
      object-fit: cover;
      position: absolute;
      width: 100vw;
      height: 100%;
      z-index: 1;
    }
  }

  .image-overlay-module {
    &:before {
      content: '';
      object-fit: cover;
      position: absolute;
      width: 100vw;
      height: 100%;
      // background: rgba(81, 45, 168, 0.4);
      background: rgba(43, 26, 86, 0.8);
      z-index: 1;
    }
  }
  .image-overlay-module-second {
    &:before {
      content: '';
      object-fit: cover;
      position: absolute;
      width: 100vw;
      height: 100%;
      background: rgba(209, 196, 233, 0.5);
      z-index: 1;
    }
  }

  .image-overlay-third {
    &:before {
      content: '';
      object-fit: cover;
      position: absolute;
      width: 100vw;
      height: 100%;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      );
      z-index: 1;
    }
  }

  .accent-color {
    color: $accent-color;
  }

  h1 {
    // font-size: 2.8rem;
    font-weight: bold;
    color: $primary-color-text;
  }
  h2,
  h4 {
    // font-size: 1.8rem;
    color: $primary-color-text;
  }
  h6 {
    // font-size: 1.8rem;
    font-weight: bold;
    color: $primary-color-text;
  }

  .input-viewport-header {
    width: 70%;
    --PhoneInputCountryFlag-height: 25px;
    --PhoneInputCountrySelectArrow-color: #fff;

    .PhoneInputCountrySelectArrow {
      font-size: 1rem;
    }

    // .PhoneInputCountryIcon,
    // .PhoneInputCountrySelectArrow {
    //   color: #fff;
    //
    // }
  }
  .button-viewport-header {
    width: 30%;
  }
  .form-viewport-header {
    width: 70%;
  }
  .book-viewport-header {
    padding-top: 30px;
    img {
      object-fit: cover;
      max-width: unset;
      height: 100%;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.viewport-header {
  position: relative;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  z-index: 1;

  .book-circle {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.color-primary-light {
  color: $primary-color-light !important;
}

.color-kirkova-primary {
  color: $primary-color !important;
}

.bg-kirkova-primary {
  background-color: $primary-color !important;
}

.about-autor {
  background: $primary-color-light;
  color: $primary-text-color;

  .about-autor-title {
    font-size: 2.8rem;
  }

  .autor {
    font-weight: bold;
    font-size: 1.8rem;
  }
  .autor-description {
    font-size: 1.3rem;
    line-height: 1.6;
  }

  .violet-color {
    color: $primary-color-dark;
  }
}

.arrow {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
}
.arrow span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid $accent-color;
  border-right: 2px solid $accent-color;
  transform: rotate(45deg);
  animation: animate 1s infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    // transform: rotate(45deg) translate(0, 0);
  }
}

.payment-modal-header {
  border: none;
  padding: 0;

  .close {
    margin-right: 0;
    margin-top: -5px;
  }
}
.modal-body {
  .title-modal-body {
    font-size: 2rem;
    font-stretch: bold;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.module-first-eighteen {
  background-color: $modules-bg-color;
  color: $white;
  .link-block {
    a {
      color: $accent-color;
    }
  }
  img {
    width: 60%;
  }
}

.vertical-timeline-element-content {
  .timeline-img-container {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      max-width: unset;
      object-fit: cover;
    }
  }
}

@include media-breakpoint-up(md) {
  .absolute-container {
    .viewport-header {
      & > * {
        margin: 30px 0;
      }
    }
    .image-bg-position-6,
    .image-bg-position-5,
    .image-bg-position-3,
    .image-bg-position-2 {
      object-position: 0;
    }
  }
  .h1-book-viewport-header {
    font-size: 6rem;
    text-shadow: 2px 2px #000;
  }
  .book-footer-text {
    background-color: $primary-color-light;
    text-align: center;
    color: $black;
    font-weight: bold;
    font-size: 1.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
  }
  .h1-new-reality-viewport-header {
    font-size: 6rem;
    span {
      font-size: 4rem;
      color: $accent-color;
    }
  }
  .h4-new-reality-viewport-header {
    font-size: 2rem;
  }
}

@include media-breakpoint-down(sm) {
  .new-reality-seven {
    .absolute-container {
      min-height: unset;
      height: auto;
    }
  }
  .absolute-container {
    .image-bg-position-2 {
      object-position: 80%;
    }
    .image-bg-position-3 {
      object-position: -250px;
    }
    .image-bg-position-4 {
      object-position: -250px;
    }
    .image-bg-position-5 {
      object-fit: contain;
      height: unset;
      position: relative;
    }
    .book-circle {
      width: 40%;
    }
  }
  .book-viewport-header {
    img {
      width: 85%;
    }
  }
  .image-overlay-second {
    &:before {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.2)
      );
    }
  }
  .h1-book-viewport-header {
    font-size: 3rem;
    text-shadow: 2px 2px #000;
  }
}

@include media-breakpoint-up(xs) {
  .viewport-container {
    margin-top: 50px;
  }
  .viewport-header {
    min-height: calc(100vh - 50px);
  }
  .absolute-container {
    min-height: calc(100vh - 50px);
    .h1-viewport-header {
      font-size: 1.3rem;
    }
    .h2-viewport-header {
      font-size: 1.2rem;
    }
    .h4-viewport-header {
      font-size: 1.1rem;
    }
    .h6-viewport-header {
      font-size: 1rem;
    }
    .input-viewport-header {
      width: 100%;
      padding: 0.5rem 1rem;
      height: calc(2em + 1rem + 2px);
    }
    .button-viewport-header {
      width: 100%;
    }
    .form-viewport-header {
      width: 100%;
    }
    .h1-book-viewport-header {
      font-size: 2.5rem;
      text-shadow: 2px 2px #000;
    }
    .h2-book-viewport-header {
      font-weight: bold;
      text-shadow: 2px 2px #000;
    }

    .h4-book-viewport-header {
      font-size: 1rem;
      font-weight: bold;
      text-shadow: 2px 2px #000;
    }
    .h1-new-reality-viewport-header {
      font-size: 2.8rem;
      span {
        font-size: 1.8rem;
        color: $accent-color;
      }
    }
    .h4-new-reality-viewport-header {
      font-size: 1.1rem;
    }
    .text-title-first {
      font-weight: bold;
      font-size: 1.1rem;
      color: $white;
      line-height: 1.2;
    }
    .text-list-new-reality-third-screen {
      font-weight: bold;
      font-size: 0.9rem;
      color: $white;
    }
    .text-footer-new-reality-third-screen {
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }
  .book-viewport-header {
    img {
      width: 85%;
    }
  }
  .h2-new-reality {
    color: $white;
    font-size: 2rem;
    font-weight: bold;
  }
  .divider-white {
    border-top: 4px solid $white;
    width: 100%;
    margin: 0px 0px 25px;
  }
  .h4-new-reality {
    color: $white;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .header-fouth-screen {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.1;
  }
  .title-fouth-screen {
    font-weight: bold;
    font-size: 1.3rem;
  }
  .text-fouth-screen {
    font-size: 1rem;
  }
  .start-use {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
  }
  .new-reality-six {
    color: $primary-text-color;
    .mb-custom {
      margin-bottom: 4rem !important;
    }
    .header {
      font-weight: bold;
      font-size: 2rem;
      line-height: 1.3;
    }
    .number-round {
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-weight: 100;
      box-sizing: content-box;
      padding: 0.8em 1em;
      border-radius: 50%;
      color: $white;
      background-color: $primary-color;
    }
    .text {
      font-size: 1.2rem;
    }
  }
  .new-reality-seven {
    background-color: #f3f1f2;
    .header {
      font-weight: bold;
      font-size: 1.6rem;
    }
    .text {
      color: $primary-text-color;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .new-reality-eight {
    color: $primary-text-color;
    background-color: #d4bcbc;
    .header {
      text-align: center;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.5;
    }
    .description {
      padding-bottom: 1rem;

      .description-title {
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .description-text {
        font-size: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
  .new-reality-nine {
    color: $primary-text-color;
    background-color: $divider-color;
    //
    // .header-block {
    //   height: 200px;
    // }

    .header {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 5rem;
    }

    .description {
      margin-top: 20px;
      font-size: 1.1rem;
      line-height: 1.1;
      text-align: center;
    }
  }
  .new-reality-ten {
    color: $primary-text-color;

    .footer-text {
      font-size: 1.1rem;
    }

    .link-to-module {
      font-size: 1.1rem;
      color: $accent-color;
    }

    .header {
      font-weight: bold;
      font-size: 2rem;
    }
    .vertical-timeline {
      padding: 0;

      &::before {
        background-color: $secondary-text-color;
        width: 1px;
        color: $white;
      }
    }
    .vertical-timeline--two-columns {
      &::before {
        margin-left: 0;
      }
      .icon-module {
        background-color: $primary-color;
        width: 20px !important;
        height: 20px !important;
        box-shadow: none;
        margin-left: 8px;
      }
    }

    .vertical-timeline-element-content {
      border: none;
      box-shadow: none;
      margin-left: 30px;
      font-size: 1.1rem;

      h2 {
        color: $accent-color;
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
      }
      .vertical-timeline-element-title {
        margin: 0;
        padding: 0;
        font-size: 1.3rem !important;
        font-weight: bold;
      }
      .vertical-timeline-element-date {
        display: none;
      }
    }
  }
  .new-reality-eleven {
    .header {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .message-block {
      font-size: 1.3rem;
    }
    .policy-block {
      font-size: 1rem;
      a {
        color: $accent-color;
      }
    }
  }
  .new-reality-twelve {
    color: $primary-text-color;
    .header {
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
  .new-reality-thirteen {
    background-color: $secondary-text-color;
    .header {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .autor {
      font-weight: bold;
      font-size: 1.3rem;
    }
    .about-text {
      line-height: 1.3rem;
      font-size: 0.9rem;
      display: table;
    }
    .subscriptions {
      font-weight: bold;
      font-size: 1rem;
      a {
        color: $accent-color;
      }
    }
  }

  .new-reality-fourteen {
    color: $primary-text-color;
    .header {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .text-description {
      font-size: 1rem;
      line-height: 1.4rem;
      text-align: justify;
    }
    .avatar {
      width: 150px;
      height: 150px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50% !important;
      }
    }
    .autor {
      font-size: 1rem;
      a {
        color: $primary-text-color;
      }
    }
    .alice-carousel__dots-item {
      background-color: $secondary-text-color;

      &:hover,
      &.__active {
        background-color: $primary-color;
      }
    }
  }
  .new-reality-fifteen {
    color: $white;
    a {
      color: $accent-color;
    }
    .header {
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
      span {
        font-size: 2rem;
      }
    }
    .question {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }
    .description {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
    .price {
      font-weight: bold;
      font-size: 3rem;
    }
  }
  .new-reality-sixteen {
    color: $primary-text-color;
    .header {
      font-size: 1.2rem;
    }
    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }
    .description {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    .help-text {
      font-size: 1.2rem;
    }
  }
  .new-reality-seventeen {
    background-color: $primary-color-light;
    color: $primary-text-color;
    .btn-link {
      color: $primary-text-color;
    }
    .button-block {
      min-width: 80px;
    }
    .header {
      font-size: 2rem;
      font-weight: bold;
    }
    .question {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    .answer {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
    .item {
      width: 100%;
      border-top: 1px solid #bca8df;
      &:last-child {
        border-bottom: 1px solid #bca8df;
      }
    }
  }
  .module-first-one {
    .header {
      font-size: 2.8rem;
      span {
        font-size: 1.8rem;
      }
    }
    .description {
      font-size: 1.1rem;
    }
  }
  .module-first-three {
    color: $white;
    .description {
      text-align: center;
      font-size: 1rem;
      line-height: 1.6rem;
    }
    .image-bg-position-6 {
      object-position: -100px;
    }
  }
  .module-first-eighteen {
    .header {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .title {
      font-weight: bold;
      font-size: 1.2rem;
    }
    .description {
      font-size: 0.9rem;
    }
    .link-block {
      font-size: 1rem;
    }
  }
  .module-second-one {
    .header {
      color: $primary-text-color;
      font-size: 2.8rem;
      span {
        font-size: 1.8rem;
      }
    }
    .description {
      color: $primary-text-color;
      font-size: 1.1rem;
    }
  }
  .video-youtube-container {
    height: 90vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-youtube {
      top: 20px;
      .header {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'lg')) and (orientation: landscape) {
  .viewport-container {
    margin-top: 70px;
  }
}

@include media-breakpoint-down(sm) {
  .offcanvas-collapse.open {
    .navbar-nav {
      .nav-link {
        color: $primary-text-color;
        margin-bottom: 0;
        padding-bottom: 15px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-overlay-second {
    &:before {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0)
      );
    }
  }
  .autor-image {
    img {
      width: 60%;
    }
  }
  .absolute-container {
    .viewport-header {
      & > * {
        margin: 30px 0;
      }
      .h1-viewport-header {
        font-size: 2.6rem;
      }
      .h2-viewport-header,
      .h6-viewport-header {
        font-size: 1.6rem;
      }
      .input-viewport-header {
        width: 70%;
      }
      .button-viewport-header {
        width: 30%;
      }
      .form-viewport-header {
        width: 70%;
      }
      .h4-viewport-header {
        font-size: 1.2rem;
        width: 50%;
      }
      .book-viewport-header {
        margin: 0;
      }
      .image-bg-position-2 {
        object-position: 0;
      }
      .h1-book-viewport-header {
        font-size: 6rem;
        text-shadow: 2px 2px #000;
      }

      .h2-book-viewport-header {
        font-weight: bold;
        text-shadow: 2px 2px #000;
      }

      .h4-book-viewport-header {
        font-size: 2rem;
        font-weight: bold;
        text-shadow: 2px 2px #000;
      }
      .h1-new-reality-viewport-header {
        font-size: 6rem;
        span {
          font-size: 4rem;
          color: $accent-color;
        }
      }
      .h4-new-reality-viewport-header {
        font-size: 2rem;
      }
      .text-title-first {
        font-weight: bold;
        font-size: 1.8rem;
        color: $white;
        line-height: 1.6;
      }
      .text-list-new-reality-third-screen {
        font-weight: bold;
        font-size: 1.5rem;
        color: $white;
      }
      .text-footer-new-reality-third-screen {
        font-weight: bold;
        font-size: 1.9rem;
        line-height: 1.6;
      }
    }
  }
  .h2-new-reality {
    color: $white;
    font-size: 2rem;
    font-weight: bold;
  }
  .divider-white {
    border-top: 4px solid $white;
    width: 100%;
    margin: 0px 0px 25px;
  }
  .h4-new-reality {
    color: $white;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .header-fouth-screen {
    font-weight: bold;
    font-size: 2.8rem;
  }
  .title-fouth-screen {
    font-weight: bold;
    font-size: 1.5rem;
  }
  .text-fouth-screen {
    font-size: 1.2rem;
  }
  .start-use {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .new-reality-six {
    color: $primary-text-color;
    .mb-custom {
      margin-bottom: 4rem !important;
    }
    .header {
      font-weight: bold;
      font-size: 2.8rem;
    }
    .number-round {
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-weight: 100;
      box-sizing: content-box;
      padding: 0.8em 1em;
      border-radius: 50%;
      color: $white;
      background-color: $primary-color;
    }
    .text {
      font-size: 1.3rem;
    }
  }
  .new-reality-seven {
    background-color: #f3f1f2;
    .header {
      font-weight: bold;
      font-size: 2.8rem;
    }
    .text {
      color: $primary-text-color;
      font-size: 1.8rem;
    }
    .image-bg-position-5 {
      object-position: 0;
    }
    .image-container {
      position: relative;
      width: 100vw;
      .image-main-bg {
        object-position: 0;
        object-fit: cover;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  .new-reality-eight {
    color: $primary-text-color;
    background-color: #d4bcbc;
    .header {
      text-align: center;
      font-weight: bold;
      font-size: 2.6rem;
    }
    .description {
      padding-top: 3rem;
      padding-bottom: 10rem;

      .description-title {
        font-size: 1.7rem;
      }

      .description-text {
        font-size: 1.3rem;
      }
    }
  }
  .new-reality-nine {
    color: $primary-text-color;
    background-color: $divider-color;

    .header-block {
      height: 150px;
    }

    .header {
      font-weight: bold;
      font-size: 2rem;
    }

    .description {
      font-size: 1.8rem;
      line-height: 1.2;
      text-align: left;
    }
  }
  .new-reality-ten {
    color: $primary-text-color;

    .footer-text {
      font-size: 1.2rem;
    }

    .link-to-module {
      font-size: 1.2rem;
      color: $accent-color;
    }

    .header {
      font-weight: bold;
      font-size: 3rem;
    }

    .vertical-timeline {
      padding: 0;

      &::before {
        background-color: $secondary-text-color;
        width: 1px;
        color: $white;
      }
    }
    .vertical-timeline--two-columns {
      &::before {
        margin-left: 0;
      }
      .icon-module {
        background-color: $primary-color;
        width: 20px !important;
        height: 20px !important;
        box-shadow: none;
        margin-left: -12px;
      }
    }

    .vertical-timeline-element-content {
      border: none;
      box-shadow: none;

      h2 {
        color: $accent-color;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .vertical-timeline-element-title {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        font-weight: bold;
      }
      .vertical-timeline-element-date {
        display: none;
      }
    }
  }
  .new-reality-eleven {
    .header {
      font-weight: bold;
      font-size: 3rem;
    }
    .message-block {
      font-size: 1.3rem;
    }
    .policy-block {
      font-size: 1rem;
      a {
        color: $accent-color;
      }
    }
  }
  .new-reality-twelve {
    color: $primary-text-color;
    .header {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  .new-reality-thirteen {
    background-color: $secondary-text-color;
    .header {
      font-weight: bold;
      font-size: 2rem;
    }
    .autor {
      font-weight: bold;
      font-size: 1.8rem;
    }
    .about-text {
      line-height: 1.6rem;
      font-size: 1.2rem;
      display: table;
    }
    .subscriptions {
      font-weight: bold;
      font-size: 1.2rem;
      a {
        color: $accent-color;
      }
    }
  }
  .new-reality-fourteen {
    color: $primary-text-color;
    .header {
      font-weight: bold;
      font-size: 3rem;
    }
    .text-description {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-align: justify;
    }
    .avatar {
      width: 150px;
      height: 150px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50% !important;
      }
    }
    .autor {
      font-size: 1.2rem;
      a {
        color: $primary-text-color;
      }
    }
    .alice-carousel__dots-item {
      background-color: $secondary-text-color;

      &:hover,
      &.__active {
        background-color: $primary-color;
      }
    }
  }
  .new-reality-fifteen {
    color: $white;
    a {
      color: $accent-color;
    }
    .header {
      font-weight: bold;
      font-size: 2.1rem;
      text-align: center;
      line-height: 2.8rem;
      span {
        font-size: 4rem;
      }
    }
    .question {
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
    .description {
      font-size: 1.3rem;
      line-height: 2.5rem;
    }
    .price {
      font-weight: bold;
      font-size: 5rem;
    }
  }
  .new-reality-sixteen {
    color: $primary-text-color;
    .header {
      font-size: 1.1rem;
    }
    .title {
      font-size: 2rem;
      font-weight: bold;
    }
    .description {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
    img {
      width: 250px;
    }
    .help-text {
      font-size: 1.1rem;
    }
  }
  .new-reality-seventeen {
    background-color: $primary-color-light;
    color: $primary-text-color;
    .btn-link {
      color: $primary-text-color;
    }
    .button-block {
      width: 90px;
    }
    .header {
      font-size: 3rem;
      font-weight: bold;
    }
    .question {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    .answer {
      font-size: 1.3rem;
      line-height: 2.2rem;
    }
    .item {
      width: 80%;
      border-top: 1px solid #bca8df;
      &:last-child {
        border-bottom: 1px solid #bca8df;
      }
    }
  }
  .module-first-one {
    .header {
      font-size: 6rem;
      span {
        font-size: 4rem;
      }
    }
    .description {
      font-size: 1.7rem;
    }
  }
  .module-first-three {
    color: $white;
    .description {
      text-align: center;
      font-size: 1.7rem;
      line-height: 2.4rem;
    }
    .image-bg-position-6 {
      object-position: 0;
    }
  }
  .custom-bottom {
    margin: 1em 0 !important;
  }
  .module-first-eighteen {
    .header {
      font-weight: bold;
      font-size: 2.8rem;
    }
    .title {
      font-weight: bold;
      font-size: 1.4rem;
    }
    .description {
      font-size: 1rem;
    }
    .link-block {
      font-size: 1.1rem;
    }
  }
  .module-second-one {
    &.absolute-container {
      min-height: 100vh;
    }
    .viewport-container {
      margin-top: 0;
    }
    .header {
      color: $primary-text-color;
      font-size: 6rem;
      span {
        font-size: 4rem;
      }
    }
    .description {
      color: $primary-text-color;
      font-size: 1.7rem;
      width: 50%;
    }
  }
  .video-youtube-container {
    height: unset;

    img {
      width: unset;
      height: unset;
      object-fit: unset;
    }

    .video-youtube {
      top: 50px;
      .header {
        font-size: 3.2rem;
      }
      .embed-responsive {
        width: 80%;
        text-align: center;
        margin-right: auto !important;
        margin-left: auto !important;
      }
    }
  }
}

.PhoneInputCountryIcon {
  color: #fff;
}

.notification-item {
  .notification-message {
    white-space: pre-line;
  }
}

.alice-carousel__prev-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 111111;
}
